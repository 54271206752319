import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './apploaderGrid.css';
import { APPPATH } from '../../../../modules/helper.module';

const GridLoader = ({Theme}) => {
  // console.log(Theme)
  return (
    <>
      <Row className={`justify-content-center text-center h100vh load-bg ${Theme === 'light' ? 'lightmode' : ''}`}>
        <Col lg={4} sm={6} xs={12} md={6} xl={4} className="align-self-center">
          <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GridLoader;
