import appGlobalState from '../appconfig';
import * as moment from 'moment';

const apppreferencesReducer = (state = { ...appGlobalState.app_preferences }, action) => {
  if (action.type === 'SET_DESKTOPAPP_PREFERENCES') {
    // console.log(action.payload)
    let dTopPref = action.payload.split(';');
    let dTopBanner = false;
    let dTopNotif = true;
    if (dTopPref.length) {
      dTopPref.forEach((dpref) => {
        let dTopPrefKey = dpref.split(':')[0].trim();
        let dTopPrefValue = dpref.split(':')[1].trim();
        switch (dTopPrefKey) {
          case 'Installed':
            if (dTopPrefValue === 'False') {
              dTopBanner = true;
            }
            break;
          case 'Date':
            let today = moment();
            let LUD_bits = dTopPrefValue.split('/');
            let lastUsedDate = moment(`${LUD_bits[1]}/${LUD_bits[0]}/${LUD_bits[2]}`, 'DD-MM-YYYY');
            //Update back to 2 days when the notifier app will be able to update the installed date
            if (today.diff(lastUsedDate, 'days') < 200) {
              dTopNotif = false;
            }
            break;
          default:
            break;
        }
      });
    }
    return {
      ...state,
      webNotifications: dTopNotif,
      Install_Desktop_App: dTopBanner,
    };
  }

  if (action.type === 'SET_USER_PREFERENCES') {
    let preferences = state.preferences;
    let newPreferences = preferences;
    // let Stories = state.Stories;
    // let StoriesIndex = state.StoriesIndex;
    // let EventReadStatus = state.ReadEventStatusData;
    if (action.payload) {
      // console.log(action.payload)
      let prefStr = action.payload.split(';');
      // console.log(prefStr)
      let prefKeysEIF = Object.keys(newPreferences.event_importance_filters);
      let prefKeysEF = Object.keys(newPreferences.event_filters);
      let prefKeysUI = Object.keys(newPreferences.user_interface);
      let prefKeysSF = Object.keys(newPreferences.search_preferences);
      let prefKeysCDSF = Object.keys(newPreferences.coindetailsearch_preferences);
      if (prefStr.length) {
        prefStr.forEach((objStr) => {
          switch (`${objStr.split(':')[0]}`) {
            case 'Low_Importance':
              if (prefKeysEIF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_importance_filters.White_Colored_Events.Show_White = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Medium_Importance':
              if (prefKeysEIF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_importance_filters.Gray_Colored_Events.Show_Gray = objStr.split(':')[1] === 'true';
              }
              break;
            case 'High_Importance':
              if (prefKeysEIF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_importance_filters.Yellow_Colored_Events.Show_Yellow = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Higher_Importance':
              if (prefKeysEIF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_importance_filters.Red_Colored_Events.Show_Red = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Show_Curated':
              // console.log('show_curated')
              if (prefKeysEIF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_importance_filters.Non_Active_Curated_Events.Show_NACE =
                  objStr.split(':')[1] === 'true';
              }
              break;
            case 'Low_Speech':
              newPreferences.event_importance_filters.Gray_Colored_Events.Speech_Notifications_Gray =
                objStr.split(':')[1] === 'true';
              break;
            case 'Medium_Speech':
              newPreferences.event_importance_filters.Yellow_Colored_Events.Speech_Notifications_Yellow =
                objStr.split(':')[1] === 'true';
              break;
            case 'High_Speech':
              newPreferences.event_importance_filters.Red_Colored_Events.Speech_Notifications_Red =
                objStr.split(':')[1] === 'true';
              break;
            case 'Local_News':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Securities_Line':
              if (prefKeysEF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_filters[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Tab_Mode':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Early_Events':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Locations_Related_Countries':
              if (prefKeysEF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.event_filters[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Analyzed_Events':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Early_In_Analyzed_Events':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Trending_Mentions':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'RecentUpdateView':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'HasColumnOrderSettings':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Theme':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = action.forcetheme !== false ? action.forcetheme : objStr.split(':')[1];
              }
              break;
            case 'MenuFilter':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1];
              }
              break;
            case 'Event_Collapsed_View':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Search_Collapsed_View':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'CD_Collapsed_View':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Mainstream_News':
              if (prefKeysUI.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.user_interface[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Search_with_gray':
              if (prefKeysSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.search_preferences[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Search_with_black':
              if (prefKeysSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.search_preferences[`${objStr.split(':')[0]}`] = action.noblack ? false : objStr.split(':')[1] === 'true';
              }
              break;
            case 'Search_with_yellow':
              if (prefKeysSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.search_preferences[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Search_with_orange':
              if (prefKeysSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.search_preferences[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'Search_with_red':
              if (prefKeysSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.search_preferences[`${objStr.split(':')[0]}`] = objStr.split(':')[1] === 'true';
              }
              break;
            case 'CDSearch_with_gray':
              if (prefKeysCDSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.coindetailsearch_preferences[`${objStr.split(':')[0]}`] =
                  objStr.split(':')[1] === 'true';
              }
              break;
            case 'CDSearch_with_black':
              if (prefKeysCDSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.coindetailsearch_preferences[`${objStr.split(':')[0]}`] = action.noblack ? false : objStr.split(':')[1] === 'true';
              }
              break;
            case 'CDSearch_with_yellow':
              if (prefKeysCDSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.coindetailsearch_preferences[`${objStr.split(':')[0]}`] =
                  objStr.split(':')[1] === 'true';
              }
              break;
            case 'CDSearch_with_orange':
              if (prefKeysCDSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.coindetailsearch_preferences[`${objStr.split(':')[0]}`] =
                  objStr.split(':')[1] === 'true';
              }
              break;
            case 'CDSearch_with_red':
              if (prefKeysCDSF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
                newPreferences.coindetailsearch_preferences[`${objStr.split(':')[0]}`] =
                  objStr.split(':')[1] === 'true';
              }
              break;
            case 'UserEmail':
              newPreferences.localisation_preferences[`Email`] = objStr.split(':')[1];

              break;
            default:
              break;
          }
          // if(prefKeysEIF.indexOf([`${objStr.split(':')[0]}`]) && objStr) {
          //   newPreferences.event_importance_filters[`${objStr.split(':')[0]}`] = (objStr.split(':')[1] === 'true')
          // }
        });
      }
      // console.log(newPreferences)
      return {
        ...state,
        UserPreferenceUpdated: action.changeUpdateCount ? parseInt(state.UserPreferenceUpdated) + 1 : 0,
        preferences: newPreferences,
        UserPreferenceUpdatedCategory: action.selectedCategory,
        preferenceRequestStatus: {
          ...state.preferenceRequestStatus,
          User_Preference: false,
        },
      };
    }
  }

  if (action.type === 'SET_NOTIFICATION_PREFERENCES') {
    let preferences = state.preferences;

    let newPreferences = preferences;
    if (action.payload) {
      // console.log(action.payload)
      let prefStr = action.payload.split(';');
      // console.log(prefStr)
      let AllKeys = Object.keys(newPreferences);
      if (prefStr.length) {
        prefStr.forEach((objStr) => {
          let notPrefStr = `${objStr.split(':')[1]}`;
          // console.log(objStr.split(':')[0])
          // console.log(notPrefStr)
          let firstSplitArr = notPrefStr.split(',');
          // console.log(firstSplitArr)
          firstSplitArr.forEach((el) => {
            let elVal = el.split('=');
            // console.log(elVal[0])
            if (elVal[1] === 'None') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.email_preferences.None = true;
                newPreferences.email_preferences.Lite = false;
                newPreferences.email_preferences.Standard = false;
                newPreferences.email_preferences.Professional = false;
              }
            } else if (elVal[1] === 'Lite') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.email_preferences.None = false;
                newPreferences.email_preferences.Lite = true;
                newPreferences.email_preferences.Standard = false;
                newPreferences.email_preferences.Professional = false;
              }
            } else if (elVal[1] === 'Standard') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.email_preferences.None = false;
                newPreferences.email_preferences.Lite = false;
                newPreferences.email_preferences.Standard = true;
                newPreferences.email_preferences.Professional = false;
              }
            } else if (elVal[1] === 'Professional') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.email_preferences.None = false;
                newPreferences.email_preferences.Lite = false;
                newPreferences.email_preferences.Standard = false;
                newPreferences.email_preferences.Professional = true;
              }
            } else if (elVal[0] === 'Daily') {
              newPreferences.email_preferences.Daily_Notifications = elVal[1] === 'true';
            } else if (elVal[0] === 'Weekly') {
              newPreferences.email_preferences.Weekly_Notifications = elVal[1] === 'true';
            } else if (elVal[0] === 'Low') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Gray_Colored_Events.Desktop_Notifications_Gray =
                  elVal[1] === 'On';
              } else if (objStr.split(':')[0] === 'PhoneNotifications') {
                newPreferences.event_importance_filters.Gray_Colored_Events.SMS_Notifications_Gray = elVal[1] === 'On';
              }
            } else if (elVal[0] === 'Medium') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Yellow_Colored_Events.Desktop_Notifications_Yellow =
                  elVal[1] === 'On';
              } else if (objStr.split(':')[0] === 'PhoneNotifications') {
                newPreferences.event_importance_filters.Yellow_Colored_Events.SMS_Notifications_Yellow =
                  elVal[1] === 'On';
              }
            } else if (elVal[0] === 'High') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Red_Colored_Events.Desktop_Notifications_Red =
                  elVal[1] === 'On';
              } else if (objStr.split(':')[0] === 'PhoneNotifications') {
                newPreferences.event_importance_filters.Red_Colored_Events.SMS_Notifications_Red = elVal[1] === 'On';
              }
            } else if (elVal[0] === 'High_BreakingOnly') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Red_Colored_Events.Desktop_Notify_Breaking_Only_Red =
                  elVal[1] === 'On';
              }
            } else if (elVal[0] === 'Medium_BreakingOnly') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Yellow_Colored_Events.Desktop_Notify_Breaking_Only_Yellow =
                  elVal[1] === 'On';
              }
            } else if (elVal[0] === 'Low_BreakingOnly') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Gray_Colored_Events.Desktop_Notify_Breaking_Only_Gray =
                  elVal[1] === 'On';
              }
            } else if (elVal[0] === 'Curated') {
              if (objStr.split(':')[0] === 'DesktopNotifications') {
                newPreferences.event_importance_filters.Non_Active_Curated_Events.Desktop_Notifications_NACE =
                  elVal[1] === 'On';
              }
            } else if (elVal[0] === 'EN_High') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.event_importance_filters.Red_Colored_Events.Email_Notifications_Red =
                  elVal[1] === 'true';
              }
            } else if (elVal[0] === 'EN_Medium') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.event_importance_filters.Yellow_Colored_Events.Email_Notifications_Yellow =
                  elVal[1] === 'true';
              }
            } else if (elVal[0] === 'EN_Low') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.event_importance_filters.Gray_Colored_Events.Email_Notifications_Gray =
                  elVal[1] === 'true';
              }
            } else if (elVal[0] === 'EN_High_BreakingOnly') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.event_importance_filters.Red_Colored_Events.Email_Notify_Breaking_Only_Red =
                  elVal[1] === 'true';
              }
            } else if (elVal[0] === 'EN_Medium_BreakingOnly') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.event_importance_filters.Yellow_Colored_Events.Email_Notify_Breaking_Only_Yellow =
                  elVal[1] === 'true';
              }
            } else if (elVal[0] === 'EN_Low_BreakingOnly') {
              if (objStr.split(':')[0] === 'EmailNotifications') {
                newPreferences.event_importance_filters.Gray_Colored_Events.Email_Notify_Breaking_Only_Gray =
                  elVal[1] === 'true';
              }
            }
          });
        });
      }
      // console.log(newPreferences)
      // return state;
      let actPref = {};
      AllKeys.forEach((key) => {
        actPref[`${key}`] = newPreferences[`${key}`];
      });
      return {
        ...state,
        preferences: actPref,
      };
    }
  }

  if (action.type === 'SET_LOCALISATION_PREFERENCES') {
    if (action.payload) {
      // localStorage.setItem('userPreferenceOffSet', action.payload.UTCOffset);
      return {
        ...state,
        preferences: {
          ...state.preferences,
          localisation_preferences: {
            ...state.preferences.localisation_preferences,
            Timezone: action.payload,
          },
        },
      };
    }
  }

  if (action.type === 'SET_PHONE_PREFERENCES') {
    if (action.payload) {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          localisation_preferences: {
            ...state.preferences.localisation_preferences,
            Phone: action.payload,
          },
        },
      };
    }
  }
  if (action.type === 'SETUSERTHEME') {
    if (action.payload) {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          user_interface: {
            ...state.preferences.user_interface,
            Theme: action.payload,
          },
        },
      };
    }
  }

  return state;
};

export default apppreferencesReducer;
