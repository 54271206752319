import appGlobalState from '../appconfig';

const filtersReducer = (state = {...appGlobalState.filters}, action) => {

    if(action.type === 'STORE_EVENTTYPES') {
        if(action.payload) {
          return {
            ...state,
            eventTypes: action.payload
          }
        }
    } 

    if(action.type === 'STORE_LOCATIONS') {
        if(action.payload) {
          return {
            ...state,
            Locations: action.payload
          }
        }
    }

    if(action.type === 'GET_EVENT_FILTERS') {
        if(action.payload) {
          return {
            ...state,
            eventFilter: action.payload
          }
        }
    }

    if(action.type === 'GET_LOCATIONS_FILTERS') {
        if(action.payload.length) {
          return {
            ...state,
            locationFilter: action.payload
          }
        }
    }

    if(action.type === 'GET_SETTING_SECURITIES') {
        if(action.payload.length) {
          return {
            ...state,
            securitiesRawCollection: action.payload
          }
        }
    }

    if(action.type === 'GET_SECURITY_FILTERS') {
        if(action.payload) {
          return {
            ...state,
            securityFilter: action.payload
          }
        }
    }

    // if(action.type === 'SET_SEC_FILT_REQ') {
    //     if(action.payload) {
    //       return {
    //         ...state,
    //         securityFilter_Req: action.payload
    //       }
    //     }
    // }

    if(action.type === 'GET_SECURITIES') {
        if(action.payload.length) {
            return {
                ...state,
                securities: action.payload,
                SecuritiesTickerIdList: action.TickerIDList
            }
        }
    }

    if(action.type === 'GET_SECURITIES_FILTERS') {
        if(action.payload) {
          return {
            ...state,
            securitiesFilter: action.payload,
            // securityFilter_Req: false
          }
        }
    }

    if(action.type === 'GET_SECURITIES_RESULTS') {
        if(action.payload) {
          return {
            ...state,
            securitiesResults: action.payload
          }
        }
    }

    if(action.type === 'SET_MENTIONTYPES') {
        if(action.payload) {
          return {
            ...state,
            mentionTypes: action.payload
          }
        }
    }

    if(action.type === 'SET_MENTIONTYPE_FILTERS') {
      if(action.payload) {
        return {
          ...state,
          mentiontypesFilter: action.payload
        }
      }
    }

    if(action.type === 'GET_SETTING_LOCATION_CONTINENTS') {
      if(action.payload.length) {
        return {
          ...state,
          locationContinents: action.payload
        }
      }
    }

    if(action.type === 'GET_LOCATIONS_FILTERED_REGIONS') {
      if(action.payload) {
        let LFR = action.payload
        let locations = Object.keys(LFR)
        if(locations.length) {
          locations.forEach(cntry => {
            if(LFR[cntry].length) {
              for(let row of LFR[cntry]) {
                if(state.locationFilter.indexOf(row.name) > -1) {
                  row.selected = true;
                }
              }
            }
          })
        }
        return {
          ...state,
          continentFilteredLocations : {
            ...state.continentFilteredLocations,
            ...LFR
          }
        }
      }
    }

    if(action.type === 'SET_SETTING_LOCATION_BY_CONTINENTS') {
      if(action.payload) {
        let cfl = action.payload;
        let Cont = Object.keys(cfl);
        if(Cont.length) {
          Cont.forEach(cnt => {
            if(cfl[cnt].length) {
              for(let row of cfl[cnt]) {
                if(state.locationFilter.indexOf(row.name) > -1) {
                  row.selected = true;
                }
              }
            }
          })
          return {
            ...state,
            continentFilteredLocations : {
              ...state.continentFilteredLocations,
              ...cfl
            }
          }
        }
      }
    }

    if(action.type === 'UPDATE_EVENT_FILTERS') {
      if(action.payload) {
        let nFS = action.payload.newFilterString.split(';')
        let evntColl = state.eventTypes
        evntColl.forEach(evt => {
          evt.selected = nFS.indexOf(evt.Name) > -1 ? true : false
        })
        return {
          ...state,
          eventType: evntColl,
          eventFilter : nFS
        }
      }
    }

    if(action.type === 'UPDATE_LOCATION_FILTERS') {
      if(action.payload) {
        let nFS = action.payload.newFilterString.split(';')
        
        let contColl = state.locationContinents;
        let locColl = state.continentFilteredLocations
        contColl.forEach(cont => {
          cont.selected = nFS.indexOf(cont.name) > -1 ? true : false
          // if(locColl[cont.name] && locColl[cont.name].length) {
            
          // }
        })
        Object.keys(locColl).map(loc => {
          locColl[loc].forEach(reg => {
            reg.selected = nFS.indexOf(reg.name) > -1 ? true : false
          })
          return null
        })
        let newcontColl = contColl.map(row => {
          return row;
        })
        let newlocColl = {};
        let newlocCollKeys = Object.keys(locColl);
        newlocCollKeys.forEach(key => {
          newlocColl[key] = locColl[key];
        })
        let newnFS = nFS;
        return {
          ...state,
          locationContinents : newcontColl,
          continentFilteredLocations : newlocColl,
          locationFilter : newnFS
          
        }
      }
    }

    if(action.type === 'UPDATE_SECURITIES_FILTERS') {
      if(action.payload) {
        let nFS = action.payload.newFilterString.split(';')
        let secColl = state.securities;
        secColl.forEach(sec => {
          sec.selected = nFS.indexOf(sec.Name) > -1 ? true : false
        })
        return {
          ...state,
          securitiesFilter : JSON.parse(JSON.stringify(nFS))
        }
      }
    }

    if(action.type === 'UPDATE_SECURITY_FILTERS') {
      if(action.payload) {
        let nFS = action.payload.newFilterString.split(';')
        let secColl = state.securitiesRawCollection
        secColl.forEach(sec => {
          sec.selected = nFS.indexOf(sec.Name) > -1 ? true : false
        })
        return {
          ...state,
          securitiesRawCollection : secColl,
          securityFilter : nFS
        }
      }
    }

    if(action.type === 'GET_SETTING_LOCATION_BY_CONTINENTS') {
      if(action.payload.length) {
        for(let row of action.payload) {
          if(state.locationFilter.indexOf(row.name) > -1) {
            row.selected = true;
          }
        }
        return {
          ...state,
          continentFilteredLocations : {
            ...state.appSettings.continentFilteredLocations,
            [`${action.onContinent}`]: action.payload
          }
        }
      }
    }

    

    return state;
}

export default filtersReducer;