import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import * as moment from 'moment';
import StoryHeadBar from './story_headbar';
import StoryDetails from './story_details';
import SecurityDetails from './security_details';
import StoryMaps from './story_map';
import { connect } from 'react-redux';
import * as feedActionCreators from '../../actions/feeds.action';
import { activateGlobalTooltipRemover, resetTipHandles } from '../../actions/settings.action';
import { CustomMouseEvent } from '../../appEvents';
import { APPPATH, NotifyFeedsHandler } from '../../modules/helper.module';
import StoryToggleBar from './story_togglebar';
import CorelTag from './coreltag';
// import { Locations } from '../../static_data/timezone_locations';

class SingleStory extends Component {
  constructor(props) {
    super();
    this.state = {
      detailssection: 'SecurityDetails',
      collapseToggle: props.feedOpenClose,
      collapseView: false,
      feedDetailsRequest: false,
      feedDetailsRequests: props.feedDetailsRequests,
      story: {},
      MouseEnterLeaveTracker: false,
      preferencesSecuritiesLine: false,
      mousemoveoverstory: false,
      preferencesLocationRelatedCountries: false,
      NaceUpdatedOld: 0,
      NaceUpdated: 0,
      withCuratedUpdated: 0,
      withBlackUpdated: 0,
      withCuratedUpdatedOld: 0,
      withBlackUpdatedOld: 0,
      hideThisStory: false,
      UserTimeZone: props.TimeZoneOffset,
      Timezone: props.Timezone,
      timeOffset: 0,
    };
    this.timeInterval = '';
    this.timeIntervalToClose = '';
    this.stopTimeout = '';
    this.OverlayRef = React.createRef();
    this.SecTag = '';
    this.extraSecTag = '';
  }
  componentWillMount() {
    let { offsetMins } = this.makeTimeZoneOffset(this.props.TimeZoneOffset, this.props.Timezone);
    
    this.setState({
      ...this.state,
      story: this.props.story,
      preferencesSecuritiesLine: this.props.preferencesSecuritiesLine,
      preferencesLocationRelatedCountries: this.props.preferencesLocationRelatedCountries,
      NaceUpdated: this.props.NaceUpdated,
      NaceUpdatedOld:
        this.props.NaceUpdated === this.state.NaceUpdatedOld ? this.props.NaceUpdated : this.state.NaceUpdatedOld,
      withBlackUpdated: this.props.withBlackUpdated,
      withBlackUpdatedOld:
        this.props.withBlackUpdated === this.state.withBlackUpdatedOld
          ? this.props.withBlackUpdated
          : this.state.withBlackUpdatedOld,
      withCuratedUpdated: this.props.withCuratedUpdated,
      withCuratedUpdatedOld:
        this.props.withCuratedUpdated === this.state.withCuratedUpdatedOld
          ? this.props.withCuratedUpdated
          : this.state.withCuratedUpdatedOld,
      timeOffset: parseInt(`${offsetMins}`),
    });
  }
  componentDidMount() {
    const { story, tdecktype } = this.props;
    if (story !== null) {
      document.getElementById(`${story.FeedId}`).addEventListener('mousemove', (e) => {
        this.handleMouseMove(e);
      });
      document.getElementById(`${story.FeedId}`).addEventListener('mousestop', (e) => {
        this.handleMouseStop(e);
      });
      if (tdecktype === 'event') {
        
        if(story.CorrelationResults !== null) {
          const TM = {
            SecurityName: story.CorrelationResults[0].Security,
            SecurityTicker: story.CorrelationResults[0].Symbol,
          };
          // window.localStorage.setItem('SavedCoinDetail', JSON.stringify(TM));
          // window.localStorage.setItem('CoinDetailColumnShowState', true);
          // this.props.pinCoinColumn(TM)
        }
        
        if (isMobile) {
          let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
          if (scrollDiv) {
            setTimeout(() => {
              scrollDiv.scroll({
                left: story.CorrelationResults === null ? scrollDiv.scrollWidth : ((scrollDiv.scrollWidth / 3) *1) -10,
                behavior: 'smooth',
              });
            }, 1500);
          }
        }
      }
    }
  }
  handleMouseMove = (e) => {
    // this.props.MouseMoveOverStory(true);
    // clearTimeout(this.stopTimeout);
    // this.stopTimeout = setTimeout(function () {
    //   let event = CustomMouseEvent.mousestop;
    //   e.target.dispatchEvent(event);
    // }, 2000);
  };
  handleMouseStop = (e) => {
    this.props.MouseMoveOverStory(false);
  };
  componentWillUnmount() {
    const { story } = this.props;
    if (story !== null) {
      document.getElementById(`${story.FeedId}`).removeEventListener('mousemove', (e) => {
        this.handleMouseMove(e);
      });
      document.getElementById(`${story.FeedId}`).removeEventListener('mousestop', (e) => {
        this.handleMouseStop(e);
      });
    }
  }
  componentWillReceiveProps(nextProps, prevState) {
    let { offsetMins } = this.makeTimeZoneOffset(nextProps.TimeZoneOffset, nextProps.Timezone);
    // console.log(offsetMins, nextProps.TimeZoneOffset)
    let eachStory = nextProps.story;
    if (eachStory !== null) {
      let detailreq = this.state.feedDetailsRequest
      if (this.state.story && this.state.story.EventReadStatus) {
        eachStory.EventReadStatus = this.state.story.EventReadStatus;
      }
      if(nextProps.feedDetailsRequests > this.state.feedDetailsRequests) {
        detailreq = false;
      }
      this.setState({
        ...this.state,
        story: eachStory,
        preferencesSecuritiesLine: nextProps.preferencesSecuritiesLine,
        preferencesLocationRelatedCountries: nextProps.preferencesLocationRelatedCountries,
        NaceUpdated: nextProps.NaceUpdated,
        NaceUpdatedOld:
          nextProps.NaceUpdated === this.state.NaceUpdatedOld ? nextProps.NaceUpdated : this.state.NaceUpdatedOld,
        withBlackUpdated: nextProps.withBlackUpdated,
        withBlackUpdatedOld:
          nextProps.withBlackUpdated === this.state.withBlackUpdatedOld
            ? nextProps.withBlackUpdated
            : this.state.withBlackUpdatedOld,
        withCuratedUpdated: nextProps.withCuratedUpdated,
        withCuratedUpdatedOld:
          nextProps.withCuratedUpdated === this.state.withCuratedUpdatedOld
            ? nextProps.withCuratedUpdated
            : this.state.withCuratedUpdatedOld,
        timeOffset: parseInt(`${offsetMins}`),
        feedDetailsRequests: nextProps.feedDetailsRequests,
        feedDetailsRequest: detailreq
      });
    } else {
      if (this.state.story !== null) {
        this.setState({
          ...this.state,
          hideThisStory: true,
        });
      }
    }
  }

  makeTimeZoneOffset = (tzOffset, TZName) => {
    let offsetMins = parseInt(tzOffset.split(':')[0]) * 60 + parseInt(tzOffset.split(':')[1]);

    return { offsetMins };
  };

  toggleDetails = (newView) => {
    this.setState({
      ...this.state,
      detailssection: newView,
      collapseToggle: true,
    });
  };

  handleCollapse = () => {
    this.storyClicked().then(() => {
      let detailrequestState = false;
      if (this.props.story) {
        if (
          !this.props.feedDetails[`${this.props.story.FeedId}`] ||
          !this.props.feedDetails[`${this.props.story.FeedId}`].length
        ) {
          this.props.getStoryDetails(this.props.story.FeedId, 11);
          detailrequestState = true;
        }

        this.setState(
          {
            ...this.state,
            collapseToggle: !this.state.collapseToggle,
            feedDetailsRequest: detailrequestState,
          },
          () => {
            //capture open state with feed id
            // console.log(this.state.collapseToggle)
            this.props.storyopenlogger(this.props.story.FeedId, this.state.collapseToggle);
          }
        );
      }
    });
  };
  viewCollapse = (newViewState) => {
    this.setState({
      ...this.state,
      collapseView: newViewState,
    });
  };

  storyClicked = async (e) => {
    if (!this.state.story.EventReadStatus) {
      const { tdecktype, SavedId } = this.props;
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.story.FeedId;
      window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
      window.Mixpanel.MixpanelProps.ColumnType = tdecktype;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Read Event`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('EventId');
        window.Mixpanel.actions.resetProp('Headline');
        window.Mixpanel.actions.resetProp('ColumnType');
      });
      let data = {
        RootId: this.props.story.FeedId,
        ReadStatus: true,
        UserName: this.props.userData.username,
      };
      this.setState({
        ...this.state,
        story: {
          ...this.state.story,
          EventReadStatus: !this.state.EventReadStatus,
        },
      });
      let Saveid = SavedId && SavedId !== '' && SavedId;
      await this.props.updateEventReadStatus(data, tdecktype, Saveid);
      await this.props.activateGlobalTooltipRemover();
      // await this.props.resetTipHandles()
    } else {
      await this.props.resetTipHandles();
    }
  };

  storyOptions = (e) => {
    // console.log(this.state.story)
    this.props.openStoryOptionsMenu(this.state.story);
  };

  storyShare = (e) => {
    // console.log(this.props.id);
    // console.log(document.getElementById(`${this.props.id}`));
    this.props.openStoryShareMenu(this.state.story, this.props.id);
  };

  handleMouseEnter = (e) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.EventId = this.state.story.FeedId;
    window.Mixpanel.MixpanelProps.Headline = this.state.story.HeadlineText;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Mouse Over Event`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('EventId');
      window.Mixpanel.actions.resetProp('Headline');
    });
  };

  setTimeAgoFromNow = (story) => {
    const { RootChangeLogList, FeedTime, LastUpdateTime, LastFeedsUpdateTime } = story;
    let alertTime;
    let alertType;
    let EventTime;
    if (RootChangeLogList.length) {
      alertTime = RootChangeLogList[RootChangeLogList.length - 1].RootChangeLogTime;
      switch (RootChangeLogList[RootChangeLogList.length - 1].UpdateTypeCategory.toLowerCase()) {
        case 'activate':
          alertType = 'Event Raised';
          break;
        case 'detected':
          alertType = 'Signal Raised';
          break;
        case 'pre trade activate':
          alertType = 'Pre trade event confirmed';
          break;
        case 'details level':
          alertType = 'Integrity Updated';
          break;
        case 'event type':
          alertType = 'Event Type Updated';
          break;
        case 'attributes':
          alertType = 'Topics Updated';
          break;
        case 'location':
          alertType = 'Location Updated';
          break;
        case 'magnitude':
          alertType = 'Magnitude Updated';
          break;
        default:
          alertType = 'Major Update';
          break;
      }
    } else {
      if (alertType === 'Updated') {
        alertType = 'Major Update';
      } else {
        alertType = 'First Feed';
      }
    }
    if (alertType === 'First Feed') {
      EventTime = moment(FeedTime);
    } else {
      if (alertTime) {
        EventTime = moment(alertTime);
      } else {
        EventTime = moment(LastFeedsUpdateTime);
      }
    }
    // console.log(EventTime.fromNow(true))
    let TimeChar = EventTime.fromNow(true).split(' ')[0][0] === 'a' ? 1 : EventTime.fromNow(true).split(' ')[0];
    let FromChar = `${EventTime.fromNow(true).split(' ')[1][0]}${EventTime.fromNow(true).split(' ')[1][1]}`;
    // if(FromChar === 'we') { FromChar = 'wk' };
    // console.log('Detected Time',TimeChar.toString(), FromChar.toString())
    if (FromChar === 'we') {
      FromChar = 'w';
    }
    if (FromChar === 'da') {
      FromChar = 'd';
    }
    if (FromChar === 'ho') {
      FromChar = 'h';
    }
    if (FromChar === 'ye') {
      FromChar = 'y';
    }
    if (FromChar === 'mi') {
      FromChar = 'm';
    }
    let EventTimeAgo = `${TimeChar}${FromChar}`;
    // console.log(EventTimeAgo)
    if (EventTimeAgo === '1fe') {
      EventTimeAgo = '1m';
    }
    // console.log(EventTimeAgo)
    return EventTimeAgo;
  };

  EventUpdateDetected = (story) => {

    let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings();
    let DetectedTime = moment.utc(story.FeedTime).utcOffset(offsetMins);
    
    // console.log(newChangesArr, DetectedTime)

    let TimeChar = DetectedTime.fromNow(true).split(' ')[0][0] === 'a' ? 1 : DetectedTime.fromNow(true).split(' ')[0];
    let FromChar = `${DetectedTime.fromNow(true).split(' ')[1][0]}${DetectedTime.fromNow(true).split(' ')[1][1]}`;
    // if(FromChar === 'we') { FromChar = 'wk' };
    // console.log('Updated Time',DetectedTime, TimeChar.toString(), FromChar.toString())
    if (FromChar === 'we') {
      FromChar = 'w';
    }
    if (FromChar === 'da') {
      FromChar = 'd';
    }
    if (FromChar === 'ho') {
      FromChar = 'h';
    }
    if (FromChar === 'ye') {
      FromChar = 'y';
    }
    if (FromChar === 'mi') {
      FromChar = 'm';
    }
    let DetectedTimeAgo = `${TimeChar}${FromChar}`;
    // console.log(EventTimeAgo)
    if (DetectedTimeAgo === '1fe') {
      DetectedTimeAgo = '1m';
    }

    return DetectedTimeAgo;
  }

  ExtractSentiment_Source = (story, type = 'Sentiment') => {
    let LocationString = story.Location
    if(LocationString !== null) {
      let items = LocationString.split(';')
      let SentimentTotal = null;
      let Sources = [];
      if(items.length) {
        items.forEach((el, i) => {
          // console.log(el)
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if(key === 'SentimentTotal') {
            SentimentTotal = value
          } else if(key === 'Source') {
            Sources = value.split(',')
          }
        })
      }
      if(type === 'Sentiment') {
        if(typeof SentimentTotal === 'undefined' || isNaN(SentimentTotal)) {
          SentimentTotal = null
        }
        if(SentimentTotal !== null) {
          return parseFloat(SentimentTotal);
        } else {
          return SentimentTotal;
        }
        
      } else if(type === 'Source') {
        return Sources;
      }
      // console.log(Sources, SentimentTotal)
      // return { Source : Sources, Sentiment: SentimentTotal }
    }
    

  }
  toggleSectagtip = (status, tipText = false) => {
    if(!isMobile) {
      let ContainerRect = this.SecTag.getBoundingClientRect();
      let Pos = {
        left: ContainerRect.left - 12,
        top: ContainerRect.bottom + 10,
      };
      if(this.SecTagttHandle) {
        clearTimeout(this.SecTagttHandle);
      }
      const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.SecTagttHandle = setTimeout(() => {
          this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.togglePopup(status);
      }
    }
    
  }
  securityTag = (security, securitySymbol, Sentiment, SentimentIndicator) => {
    
    // let tiptext =  ''
    // if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
    //   if(SentimentIndicator === 'neg_sentiment.svg') {
    //     tiptext = 'Negative AI sentiment for event'
    //   } else if(SentimentIndicator === 'pos_sentiment.svg') {
    //     tiptext = 'Positive AI sentiment for event'
    //   }
    // } else {
    //   tiptext = 'Asset related to this event'
    // }

    // let MagnitudeRate = this.state.story.MagnitudeRate;
    // let iframeclickable = false;
    // if(MagnitudeRate >= 1) {
    //   if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
    //     iframeclickable = true
    //   }
    // }

    // ${this.props.render === 'iframe' ? 'iframe' : ''} ${sentimentbackground}
    let iconSymbol =
      security !== '' ? (
        <span
          className={`tickhover margr2  padlr5tb2 bg-symbol bold ${this.props.Theme === 'light' ? 'lightmode' : ''} ${this.props.render !== 'iframe' ? 'text-blue-primary01' : ''} `}
          
          // onMouseEnter={(e) => {
          //   this.toggleSectagtip(true, tiptext)
          // }}
          // onMouseLeave={(e) => {
          //   this.toggleSectagtip(false)
          // }}
          onClick={(e) => {
            if(this.props.render !== 'iframe') {
              e.stopPropagation();
              window.showCoinColumnFromText(security, securitySymbol, this.props.listkey);
            }
            // else if(this.props.render === 'iframe') {
            //   if(iframeclickable) {
            //     let Direction = '';
            //     if(Sentiment <= 3.5) { 
            //       Direction = 'Sell';
            //     } else if(Sentiment >= 6.5) {
            //       Direction = 'Buy'
            //     }
            //     e.stopPropagation();
            //     window.showCoinColumnFromText(security, securitySymbol, false, false,  Direction);
            //   }
            // }
            
          }}
        >
          
            <img 
              src={`${APPPATH.CoinIconUrl}/${
                security !== null ? security.toLowerCase().trim().replace(/ /g, '_') : ''
              }.png`} 
              className="coinicon" 
              alt="CrowdSense.ai" 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
              }}
            />
          
          
          {securitySymbol}
          
        </span>
      ) : (
        ''
      );
    return iconSymbol;
  };
  extraSecurityTag = (extraSecurity) => {
    const count = extraSecurity.length;
    var temp = (
      <span
        id={`extraTag${this.props.listkey}${this.props.feedId}`}
        ref={(el) => (this.extraSecTag = el)}
        onMouseEnter={(e) => {
          this.toggleExtraSectagtip(true)
          // let ToolTipStyleData = {
          //   Arrow: {
          //     marginLeft: '10px',
          //   },
          //   ToolTip: {
          //     align: 'left',
          //   },
          // };
          // this.props.toggleOverlay(true, this.SecTag, tiptext, ToolTipStyleData);
        }}
        onMouseLeave={(e) => {
          // this.props.toggleOverlay(false);
          this.toggleExtraSectagtip(false)
        }}
        onClick={(e) => {
          this.toggleExtraSectagtip(false);
          this.clickToSeeExtraCoins(e, extraSecurity, `${this.props.listkey}${this.props.feedId}`, this.state.toggleExtraCoins)
        }}
        className="tickhover margr2  padlr5tb2 bg-symbol"
      >
        {'+'}
        {count}
      </span>
    );
    return temp;
  };

  toggleExtraSectagtip = (status) => {
    if(!isMobile) {
      let ContainerRect = this.extraSecTag.getBoundingClientRect();
      let Pos = {
        left: ContainerRect.left - 15,
        top: ContainerRect.bottom + 8,
      };
      if(this.ExtraSecTagttHandle) {
        clearTimeout(this.ExtraSecTagttHandle);
      }
      let tipText = 'More Related Assets'
      const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.ExtraSecTagttHandle = setTimeout(() => {
          this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.togglePopup(status);
      }
    }
    
  }

  clickToSeeExtraCoins = (e, extraSecurityTags, feedId, toggleFlag = false) => {
    e.stopPropagation();
    var extraTagElement = document.getElementById(`extraTag${feedId}`);
    if (extraTagElement) {
      let ContainerRect = extraTagElement.getBoundingClientRect();
      let mousePosition = {
        left: ContainerRect.x - 100,
        top: ContainerRect.bottom + 15,
      };
      let balCRLList = [];
      extraSecurityTags.forEach((item, i) => {
        if (item.Impact !== 'evttime' && item.Impact !== 'feeds' && item.Impact !== 'usrReach') {
          let tmp = (
            <Row className={`tipItem marg-0 h-100`} key={i}>
              <CorelTag each={item} tagicon={this.tagIcon(item)} tagcontent={this.tagContent(item)} {...this.props} />
            </Row>
          );
          balCRLList.push(tmp);
        }
      });
      let balCRLCountList = <div className={``}>{balCRLList}</div>;
      let containerClass = `crl-tooltipMenu ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      // console.log(!this.state.toggleExtraCoins, balCRLCountList, mousePosition, containerClass)
      this.props.togglePopup(!this.state.toggleExtraCoins, balCRLCountList, mousePosition, containerClass);
      this.setState({
        toggleExtraCoins: !this.state.toggleExtraCoins,
      });
    }
  };

  extraSecurityCount = (corelsArray) => {
    var extrasecurityTags = [];
    for (let corelCount = 0; corelCount < corelsArray.length; corelCount++) {
      if (corelCount >= 1) {
        extrasecurityTags.push(corelsArray[corelCount]);
      }
    }
    //balCRLCountList = <div className={``}>{balCRLList}</div>;
    return extrasecurityTags;
  };

  tagContent = (item) => <span className="coreltext fs12  fssmsec">{item.Symbol}</span>;
  tagIcon = (Item) => (
    <span>
      {/* <object
        className="timelineIndicator imground"
        data={`${APPPATH.CoinIconUrl}/${
          Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
        }.png`}
        type="image/png"
      > */}
        <img 
          src={`${APPPATH.CoinIconUrl}/${
            Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
          }.png`} 
          className="timelineIndicator imground" 
          alt="CrowdSense.ai" 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
          }}
        />
      {/* </object> */}
    </span>
  );

  render() {
    let {
      story,
      preferencesSecuritiesLine,
      preferencesLocationRelatedCountries,
      NaceUpdated,
      NaceUpdatedOld,
      withBlackUpdatedOld,
      withBlackUpdated,
      withCuratedUpdated,
      withCuratedUpdatedOld,
    } = this.state;
    let EventReadStatus = story !== null ? story.EventReadStatus : true;
    let SecuritySymbol = '';
    let Security = '';
    // let SecuritySymbolTickerIcon = '';

    let feedDetails =
      story !== null ? (
        <StoryDetails
          {...this.props}
          collapse={this.state.collapseToggle}
          collapsesection={'feed'}
          collapseview={this.viewCollapse}
          handleCollapse={this.handleCollapse}
          feedDetailsRequest={this.state.feedDetailsRequest}
          story={this.state.story}
          EventReadStatus={EventReadStatus}
          timeOffset={this.state.timeOffset}
          Theme={this.props.Theme}
        />
      ) : (
        ''
      );

    if (story !== null && story.CorrelationResults) {
      // console.log(story.CorrelationResults)
      let securities = story.CorrelationResults.map((itm) => ({
        SecuritySymbol: itm.Symbol,
        Security: itm.Security,
      })).filter((itm) => itm);

      // let uniqueFeedIds = Array.from(new Set(feedIds))
      if (story.CorrelationResults.length === 1 && securities.length) {
        SecuritySymbol = securities[0].SecuritySymbol;
        Security = securities[0].Security;
      }
    }
    // if(story && story.FeedId === 49344334) {
    //   console.log(story)
    // }
    
    let detailsSection =
      story !== null ? (
        // story.Active ? (
        // preferencesSecuritiesLine || preferencesLocationRelatedCountries ? (
        <SecurityDetails
          {...this.props}
          collapse={this.state.collapseToggle}
          collapsesection={'security'}
          collapseview={this.viewCollapse}
          handleCollapse={this.handleCollapse}
          correlationResults={story.CorrelationResults}
          Locations={story.Location}
          EventReadStatus={EventReadStatus}
          preferencesSecuritiesLine={preferencesSecuritiesLine}
          preferencesLocationRelatedCountries={preferencesLocationRelatedCountries}
          GeoLocation={story.GeoLocation}
          GeoMarkers={story.EventMapMarkers}
          // Event_Collapsed_View={this.props.Event_Collapsed_View}
          RootChangeLogList={story.RootChangeLogList}
          StoryFeedTime={story.TZ_FeedTime}
          StoryLastFeedsUpdateTime={story.TZ_LastFeedsUpdateTime}
          Resources={this.props.lang === false ? story.Resources : story.Resources.split(',').length}
          Reports={this.props.lang === false ? story.Reports : story.NumberOfFeeds}
          EventType={story.EventType}
          UserReputationRate={this.props.lang === false ? story.UserReputationRate : story.EventUserReputationRate}
          storyOptions={this.storyOptions}
          storyShare={this.storyShare}
          storyID={story.FeedId}
          tmOffset={this.state.timeOffset}
          story={story}
          Theme={this.props.Theme}
          render={this.props.render}
        />
      ) : (
        // ) : (
        //   ''
        // )
        ''
      );
    let mapSection =
      story !== null && !this.state.collapseToggle ? (
        story.GeoLocation !== '' && preferencesLocationRelatedCountries ? (
          <StoryMaps
            GMAPKEY={this.props.googleMapKey}
            GeoLocations={story.GeoLocation}
            GeoLocationsRadius={story.Radius}
            GeoMarkers={story.EventMapMarkers}
            collapse={this.state.collapseToggle}
          />
        ) : (
          ''
        )
      ) : (
        ''
      );
    // console.log(this.props.initDataRequest)
    if (story !== null) {
      // let now = moment();
      // let FeedTime = moment(story.FeedTime.toString());
      // console.log(this.props.columnCollapse)
      let EventActive = story.Active;
      let EventCurated = story.EventCurated;
      let MagnitudeRate = story.MagnitudeRate;
      let brdrbg = '';

      // if(story.EventType.toLowerCase().trim().includes('followers post')) {
      //   // console.log(story.EventType)
      //   brdrbg = 'event-brdr-special';
      // } else {
      if (!EventActive && EventCurated) {
        brdrbg = 'event-brdr-gray';
      } else if (!EventActive && !EventCurated) {
        brdrbg = 'event-brdr-black';
      } else {
        // console.log(MagnitudeRate)
        switch (MagnitudeRate) {
          case 1:
            brdrbg = 'event-brdr-medium';
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-medium' : 'mag-bg-medium';
            break;
          case 2:
            brdrbg = 'event-brdr-high';
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
            break;
          case 3:
            brdrbg = 'event-brdr-higher';
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
            break;
          default:
            brdrbg = 'event-brdr-default';
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-default' : 'mag-bg-default';
            break;
        }
      }
      const EventTimeAgo = `${this.setTimeAgoFromNow(story)}`;
      const DetectedTimeAgo = `${this.EventUpdateDetected(story)}`;
      
      let Sentiment = this.ExtractSentiment_Source(story)
      let SentimentIndicator = ''
      if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && parseInt(story.MagnitudeRate) > 0) {
        if(Sentiment <= 3.5) {
          SentimentIndicator = 'neg_sentiment.svg'
        } else if(Sentiment >= 6.5) {
          SentimentIndicator = 'pos_sentiment.svg'
        }
      }

      // console.log(this.props.securitiesFilter)
      let watchlistfilteredcorrelations = [];
      let coins = '';
      story.CorrelationResults.forEach((corels, i) => {
        // if(this.props.story.FeedId === 49277420) {
        //   console.log(corels, this.props.securitiesFilter)
        // }
        if(corels.Security !== null) {
          if(this.props.securitiesFilter.indexOf(corels.Security) > -1) {
            watchlistfilteredcorrelations.push(corels);
          } else if(this.props.SecurityName === corels.Security) {
            watchlistfilteredcorrelations.push(corels);
          } else if(this.props.securitiesFilter.indexOf(' Semrush') > -1 && corels.Security === 'Semrush') {
            watchlistfilteredcorrelations.push(corels);
          } 
        }
        // console.log(watchlistfilteredcorrelations)
      })

      if (watchlistfilteredcorrelations.length === 1 || this.props.render === 'iframe') {
        coins = <span>{this.securityTag(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, Sentiment, SentimentIndicator)}</span>;
      }  else if (watchlistfilteredcorrelations.length > 1) {
        coins = (
          <span>
            {this.securityTag(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, this.props.StorySentiment, this.props.StorySentimentIndicator)}
            {/* {this.securityTag(watchlistfilteredcorrelations[1].Security, watchlistfilteredcorrelations[1].Symbol)} */}
            {this.extraSecurityTag(this.extraSecurityCount(watchlistfilteredcorrelations))}
          </span>
        );
      }

      let headlineText= this.props.lang === false ? story.HeadlineText : story.HeadlineTextAdmin
      let actualtext = headlineText.split('|')[0];
      let explanationtext = typeof headlineText.split('|')[1] !== 'undefined' && headlineText.split('|')[1] !== '' ? headlineText.split('|')[1] : false

      return (
        <div className={`storyCardHover ${this.props.render === 'iframe' ? 'iframe' : ''}`}>
          <Col
            lg={12}
            sm={12}
            xs={12}
            md={12}
            xl={12}
            id={story.FeedId}
            onClick={this.storyClicked}
            onMouseEnter={this.handleMouseEnter}
            className={`story-card bg-content ${this.props.Theme === 'light' ? 'lightmode' : ''} ${EventReadStatus ? '' : 'bg-story-unread'} ${brdrbg} ${
              this.state.collapseToggle ? 'padb9' : ''
            } `}
          >
            <Row className={`marg-0 `}>
              <Col lg={1} sm={1} xs={1} md={1} xl={1}
                style={{  }}
                className={`nopad  text-left jutsify-content-left   ${
                  this.props.storyState.TabMode
                    ? 'custom-col-1Tab'
                    : this.props.colLength > 2
                    ? 'custom-col-1min'
                    : 'custom-col-1'
                }`}
              >
                <StoryToggleBar
                  toggleDet={this.toggleDetails}
                  TDeckColWidth={this.props.TDeckColWidth}
                  userData={this.props.userData}
                  story={this.state.story}
                  storyClicked={this.storyClicked}
                  EventReadStatus={EventReadStatus}
                  togglePopup={this.props.togglePopup}
                  toggleOverlay={this.props.toggleOverlay}
                  tabmode={this.props.tabmode}
                  colLength={this.props.colLength}
                />
              </Col>
              <Col lg={1} sm={1} xs={1} md={1} xl={1}
                style={{ marginTop: 2 }}
                className={`nopad  text-left jutsify-content-left`}
              >
                {
                  SentimentIndicator === 'neg_sentiment.svg' || SentimentIndicator === 'pos_sentiment.svg' ? (
                    <img 
                      src={`${APPPATH.ImageUrl+'/'+SentimentIndicator} `} 
                      className="pointer" alt="CrowdSense.ai" 
                      style={{width: '20px'}} 
                      onClick={() => ('')}
                      ref={(el) => (this.SecTag = el)}
                      onMouseEnter={(e) => {
                        let tiptext = '';
                        if(SentimentIndicator === 'neg_sentiment.svg') {
                          tiptext = 'Negative AI sentiment for event'
                        } else if(SentimentIndicator === 'pos_sentiment.svg') {
                          tiptext = 'Positive AI sentiment for event'
                        }
                        this.toggleSectagtip(true, tiptext)
                      }}
                      onMouseLeave={(e) => {
                        this.toggleSectagtip(false)
                      }}
                    />
                  ) : ('')
                }
                
              </Col>
              <Col lg={10} sm={10} xs={10} md={10} xl={10}
                style={{ marginTop: 2 }}
                className={`nopad  text-left jutsify-content-right`}
              >
                <div className={`text-sigbarTime fs11 text-right text-white`}>
                  {
                    DetectedTimeAgo !== EventTimeAgo ? (
                      `Updated ${EventTimeAgo} ago`
                    ) : (
                      `Detected ${DetectedTimeAgo} ago`
                    )
                  }
                  {/* Detected {DetectedTimeAgo} ago | Updated {EventTimeAgo} ago */}
                </div>
              </Col>
            </Row>
            <Row className={`marg-0 `} style={{padding: '10px 0px 0px 0px'}}>
              <Col lg={6} sm={6} xs={6} md={6} xl={6} className={`align-self-center nopad`}>
                  {coins}
              </Col>
              <Col lg={6} sm={6} xs={6} md={6} xl={6} className={`align-self-center text-right nopad`}>
                {
                  this.props.render === 'iframe' && this.props.userData.loginlessuser === 'Iforex' ? (
                    <span 
                      className='btn btn-trade01'
                      style={{marginTop: '-15px'}}
                      onClick={(e) => {

                      let MagnitudeRate = this.state.story.MagnitudeRate;
                      let iframeclickable = false;
                      if(MagnitudeRate >= 1) {
                        if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
                          iframeclickable = true
                        }
                      }
                      if(iframeclickable) {
                        let Direction = '';
                        if(Sentiment <= 3.5) { 
                          Direction = 'Sell';
                        } else if(Sentiment >= 6.5) {
                          Direction = 'Buy'
                        }
                        e.stopPropagation();
                        window.showCoinColumnFromText(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, false, false,  Direction);
                      }
                      
                    }}>Trade</span>
                  ) : ('')
                }
                 
              </Col>
            </Row>
            <Row className={`marg-0 `} style={{padding: '0px 0px 0px 0px'}}>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`align-self-center cursor-contextmenu storyHeadline ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <div>
                  {actualtext}
                </div>
              </Col>
            </Row>
            
            <Row className={`marg-0`}>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center nopad">
                {detailsSection}
              </Col>
            </Row>
           
            {
              (!this.state.collapseToggle && explanationtext) && (
                <Row className={`marg-0`}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center text-left nopad fs13 font-weight-normal">
                    {explanationtext}
                  </Col>
                </Row>
              )
            }
            {/* <Row className={`marg-0 `} style={{ paddingTop: 10 }}>
              
              <Col
                lg={1}
                sm={1}
                xs={2}
                md={1}
                xl={1}
                style={{ marginTop: 2 }}
                className={`nopad  text-left jutsify-content-left   ${
                  this.props.storyState.TabMode
                    ? 'custom-col-1Tab'
                    : this.props.colLength > 2
                    ? 'custom-col-1min'
                    : 'custom-col-1'
                }`}
              >
                <StoryToggleBar
                  toggleDet={this.toggleDetails}
                  TDeckColWidth={this.props.TDeckColWidth}
                  userData={this.props.userData}
                  story={this.state.story}
                  storyClicked={this.storyClicked}
                  EventReadStatus={EventReadStatus}
                  togglePopup={this.props.togglePopup}
                  toggleOverlay={this.props.toggleOverlay}
                  tabmode={this.props.tabmode}
                  colLength={this.props.colLength}
                />
              </Col>
              <Col
                lg={11}
                sm={11}
                xs={10}
                md={11}
                xl={11}
                className={`align-self-center nopad ${
                  this.props.storyState.TabMode
                    ? 'custom-col-10Tab'
                    : this.props.colLength > 2
                    ? 'custom-col-10min'
                    : 'custom-col-10'
                }`}
              >
                <StoryHeadBar
                  {...this.props}
                  toggleDet={this.toggleDetails}
                  handleCollapse={this.handleCollapse}
                  story={this.state.story}
                  EventReadStatus={EventReadStatus}
                  storyClicked={this.storyClicked}
                  tabmode={this.props.tabmode}
                  // Event_Collapsed_View={this.props.Event_Collapsed_View}
                  collapse={this.state.collapseToggle}
                  SecuritySymbol={SecuritySymbol}
                  Security={Security}
                  securityTicker={story.CorrelationResults ? story.CorrelationResults[0] : {}}
                  tdecktype={this.props.tdecktype}
                />
                
                
              </Col>
              
            </Row>
            <div>
              {this.props.columnCollapse || (!this.state.collapseToggle && !this.props.columnCollapse) ? (
                <Row className={`marg-0 story-feeds-sec h-100 `}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className="story-feed-security nopad">
                    {detailsSection}
                  </Col>
                </Row>
              ) : (
                ''
              )}
             
            </div>
            {!this.state.collapseToggle && mapSection !== '' && (
              <Row className={`marg-0`}>
                <Col
                  lg={12}
                  sm={12}
                  xs={12}
                  md={12}
                  xl={12}
                  className="nopad posrelative"
                  style={{ width: '100%', height: '100%' }}
                >
                  {mapSection}
                </Col>
              </Row>
            )} */}
            {!this.state.collapseToggle && (
              <Row className={`marg-0`}>
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className="brdr-bot-gray padt2"></Col>
              </Row>
            )}
            {feedDetails}
          </Col>
        </div>
      );
    } else {
      // if (!this.props.storyCount) {
      const { StoryRequestState, tdecktype, savedSearchStoryRequestState, initDataRequest, coindetailcolumnRequest } = this.props;

      const searchnullContent =
        tdecktype === 'search' ? (
          this.props.checkInSearch ? (
            this.props.searchRequest ? (
              <div>
                <div className="Col-Pref-Update-Head">Updating search results to match preferences</div>
                <div>
                  <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
                </div>
              </div>
            ) : (
              <div className="storyloadindicator">{`No events matching the search criteria from ${moment
                .utc(this.props.searchData.startDate)
                .local()
                .format('DD/MM/YYYY HH:mm:ss A')} to ${moment
                .utc(this.props.searchData.endDate)
                .local()
                .format(
                  'DD/MM/YYYY HH:mm:ss A'
                )}. Try changing the dates and times using the calendar icon or try changing search terms.`}</div>
            )
          ) : (
            ''
          )
        ) : (
          ''
        );
      const tmDetailsnullContent =
        tdecktype === 'tmd' ? (
          this.props.tmDetailsRequest ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results to match preferences</div>
              <div>
                <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No recent events matching this criteria.`}</div>
          )
        ) : (
          ''
        );
      const coindetailcolumnnullContent =
        tdecktype === 'tmcd' ? (
          this.props.coindetailcolumnRequest ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results to match preferences</div>
              <div>
                <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No recent events matching this criteria.`}</div>
          )
        ) : (
          ''
        );
      const eventDetailsnullContent =
        tdecktype === 'event' ? (
          this.props.tmDetailsRequest ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results</div>
              <div>
                <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No such event found`}</div>
          )
        ) : (
          ''
        );
      const notifynullContent =
        tdecktype === 'notify' ? (
          StoryRequestState === true || initDataRequest === true ? (
            <div>
              <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
            </div>
          ) : StoryRequestState === false && !this.state.story ? (
            <div className="storyloadindicator">...</div> //No breaking events found
          ) : (
            ''
          )
        ) : (
          ''
        );
      const analyzednullContent =
        tdecktype === 'analyzed' ? (
          StoryRequestState === true || initDataRequest === true ? (
            <div>
              <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
            </div>
          ) : StoryRequestState === false && !this.state.story ? (
            <div className="storyloadindicator">...</div> //No analyzed events found
          ) : (
            ''
          )
        ) : (
          ''
        );
      let color;
      switch (this.props.UserPreferenceUpdatedCategory) {
        case 'Red_Colored_Events':
          color = 'red';
          break;
        case 'Yellow_Colored_Events':
          color = 'orange';
          break;
        case 'Gray_Colored_Events':
          color = 'yellow';
          break;
        case 'Non_Active_Curated_Events':
          color = 'gray';
          break;
        default:
          color = '';
          break;
      }

      const mainnullContent =
        tdecktype === 'main' ? (
          StoryRequestState === true || initDataRequest === true ? (
            NaceUpdated !== NaceUpdatedOld ? (
              <div>
                {
                  this.props.render !== 'iframe' ? (
                    <div className="Col-Pref-Update-Head">Updating results to match preferences</div>
                  ) : ('')
                }
                
                {/* <div className="Col-Pref-Update-Head">Updating results to include {color} events</div> */}
                {/* <div className="Col-Pref-Update-Desc">(earlier curated signals for events before a deeper analysis)</div> */}
                {
                  this.props.render !== 'iframe' ? (
                    <div>
                      <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
                    </div>
                  ) : ('')
                }
                
                
              </div>
            ) : (
              <div>
                {
                  this.props.render !== 'iframe' ? (
                    <div>
                      <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
                    </div>
                  ) : ('')
                }
                
              </div>
            )
          ) : StoryRequestState === false && !this.state.story ? (
            !this.props.checkInSearch ? (
              !this.props.req_error.rootFeeds ? (
                <div className="storyloadindicator"></div>//No matched events foun
              ) : (
                // <div className="storyloadindicator">
                //   No connection to service, please try to refresh. If the problem persists, please let us know in{' '}
                //   <a href="mailto: info@crowdsense.ai">
                //     <u>info@crowdsense.ai</u>
                //   </a>
                // </div>
                <div className="storyloadindicator"></div>//No matched events found
              )
            ) : (
              <div className="storyloadindicator">{`No events matching the search criteria from ${moment
                .utc(this.props.searchData.startDate)
                .local()
                .format('DD/MM/YYYY HH:mm:ss A')} to ${moment
                .utc(this.props.searchData.endDate)
                .local()
                .format(
                  'DD/MM/YYYY HH:mm:ss A'
                )}. Try changing the dates and times using the calendar icon or try changing search terms.`}</div>
            )
          ) : (
            ''
          )
        ) : (
          ''
        );
      const savedSearchNullContent =
        tdecktype === 'savedsearch' ? (
          savedSearchStoryRequestState === true ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results to match saved search preference.</div>
              <div>
                <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No events matching the search criteria.`}</div>
          )
        ) : (
          ''
        );
      return (
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.state.hideThisStory ? 'nodisp' : ''}`}>
          {tdecktype === 'notify'
            ? notifynullContent
            : tdecktype === 'search'
            ? searchnullContent
            : tdecktype === 'main'
            ? mainnullContent
            : tdecktype === 'savedsearch'
            ? savedSearchNullContent
            : tdecktype === 'analyzed'
            ? analyzednullContent
            : tdecktype === 'tmd'
            ? tmDetailsnullContent
            : tdecktype === 'event'
            ? eventDetailsnullContent
            : tdecktype === 'tmcd'
            ? coindetailcolumnnullContent
            : ''}
        </Col>
      );
      // }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let srcfilter;
  if(ownProps.tdecktype === 'tmcd') {
    // console.log(state.trendingMentions.tmDetailPreferencesDataStore)
    // console.log(`${ownProps.SecurityName}${ownProps.SourceFilter}`)
    if(typeof ownProps.SourceFilter === undefined || ownProps.SourceFilter === '') {
      srcfilter = '';
    } else {
      srcfilter = ownProps.SourceFilter;
    }
  }
  return {
    googleMapKey: state.settings.googleMapKey,
    story:
      ownProps.tdecktype === 'search' && ownProps.checkInSearch
        ? typeof state.search.searchStories[ownProps.id] !== 'undefined'
          ? state.search.searchStories[ownProps.id]
          : null
        : ownProps.tdecktype === 'savedsearch'
        ? ownProps.SavedId &&
          ownProps.id &&
          typeof state.search.searchSavedStories[ownProps.SavedId][ownProps.id] !== 'undefined'
          ? state.search.searchSavedStories[ownProps.SavedId][ownProps.id]
          : null
        : ownProps.tdecktype === 'notify'
        ? typeof state.feeds.BreakingStories[ownProps.id] !== 'undefined'
          ? state.feeds.BreakingStories[ownProps.id]
          : null
        : ownProps.tdecktype === 'analyzed'
        ? typeof state.feeds.AnalysedStories[ownProps.id] !== 'undefined'
          ? state.feeds.AnalysedStories[ownProps.id]
          : null
        : ownProps.tdecktype === 'tmd'
        ? typeof state.trendingMentions.tmDetails.TickerEventList[ownProps.id] !== 'undefined'
          ? state.trendingMentions.tmDetails.TickerEventList[ownProps.id]
          : null

        :ownProps.tdecktype === 'tmcd' 
        ? typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`] !== 'undefined' && typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerEventList[ownProps.id] !== 'undefined'
          ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerEventList[ownProps.id]
          : null
        : ownProps.tdecktype === 'event'
        ? ownProps.currentEvent
          ? ownProps.currentEvent
          : null
        : typeof state.feeds.Stories[ownProps.id] !== 'undefined'
        ? state.feeds.Stories[ownProps.id]
        : null,
    feedDetails: state.feeds.feedDetails,
    searchData: state.search.searchData,
    searchRequest: state.search.searchRequest,
    NaceUpdated: state.app_preferences.UserPreferenceUpdated,
    UserPreferenceUpdatedCategory: state.app_preferences.UserPreferenceUpdatedCategory,
    preferencesSecuritiesLine: state.app_preferences.preferences.event_filters.Securities_Line,
    preferencesLocationRelatedCountries: state.app_preferences.preferences.event_filters.Locations_Related_Countries,
    StoryRequestState: state.feeds.feedReqData.RequestState,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    render: state.settings.render,
    savedSearchStoryRequestState:
      typeof state.search.searchSaveFeedReqData[ownProps.SavedId] !== 'undefined'
        ? state.search.searchSaveFeedReqData[ownProps.SavedId].RequestState
        : false,
    withBlackUpdated:
      typeof state.search.withBlackUpdated[ownProps.SavedId] !== 'undefined'
        ? state.search.withBlackUpdated[ownProps.SavedId]
        : 0,
    withCuratedUpdated:
      typeof state.search.withCuratedUpdated[ownProps.SavedId] !== 'undefined'
        ? state.search.withCuratedUpdated[ownProps.SavedId]
        : 0,
    Event_Collapsed_View: state.app_preferences.preferences.user_interface.Event_Collapsed_View,
    initDataRequest: state.settings.initDataRequest,
    feedOpenClose:
      typeof state.feeds.feedOpenCloseList[ownProps.id] !== 'undefined'
        ? state.feeds.feedOpenCloseList[ownProps.id]
        : true,
    TimeZoneOffset: state.settings.TimeZoneOffset,
    Timezone: state.app_preferences.preferences.localisation_preferences.Timezone,
    // SecuritiesTickerIdList: state.filters.SecuritiesTickerIdList,
    tmDetailsRequest: state.trendingMentions.tmDetails.TickerDetailsFeedReqData.RequestState,
    coindetailcolumnRequest: 
      typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`] !== 'undefined' ? 
        typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerDetailsFeedReqData !== 'undefined' ? 
          state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerDetailsFeedReqData.RequestState : false
        : false,
    lang: state.settings.lang,
    feedDetailsRequests: state.feeds.feedDetailsRequests,
  };
};

const mapDispatchToProps = {
  ...feedActionCreators,
  activateGlobalTooltipRemover,
  resetTipHandles,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleStory);
