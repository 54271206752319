import React, { Component } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class OverlayWrap extends Component {
  constructor(props) {
    super();
    this.state = {
      showState: props.show,
      target: props.target,
      toolTipText: props.tooltiptext,
      styleData: props.styleData,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      showState: nextProps.show,
      target: nextProps.target,
      toolTipText: nextProps.tooltiptext,
      styleData: nextProps.styleData,
    });
  }

  entering = (e) => {
    // console.log(e.children[0]);
    // console.log(e.children[1].offsetWidth);
    e.children[0].style.marginLeft = `${this.alignArrow()}`;
    e.children[0].style.marginTop = 5;
    e.children[1].style.width = `150px`;
    e.children[1].style.marginLeft = `${this.alignToolTip(e.children[1].offsetWidth)}px`;
    e.children[1].style.backgroundColor = this.props.Theme === 'light' ? '#007bff' : '#50c0ff';//'#37aaf8';
    e.children[1].style.fontSize = '12px';
  };
  alignToolTip = (width) => {
    if (this.state.styleData.ToolTip && this.state.styleData.ToolTip.align) {
      switch (this.state.styleData.ToolTip.align) {
        case 'center':
          return width * 0.5 * -1;
        case 'right':
          return width * 0.75 * -1;
        case 'slightright':
          return width * 0.25 ;
        case 'pushedright':
          return width * 1;
        case 'pushedleft':
          return width * -1;
        case 'left':
          return 0;
        case 'left-center':
          return 25;
        case 'slightleft':
          return width * 0.25 * -1;
        case 'leftmedium':
          return width * -0.25 * -1;
        default:
          return this.state.styleData.ToolTip.align;
      }
    }
    return '0';
  };
  alignArrow = () => {
    if (this.state.styleData.Arrow && this.state.styleData.Arrow.marginLeft) {
      return this.state.styleData.Arrow.marginLeft;
    }
    return '0px';
  };

  render() {
    const { target, showState, toolTipText } = this.state;
    // console.log(target)
    if (!isMobile) {
      if (showState) {
        return (
          <Overlay container={target.current} show={showState} placement="bottom" onEntering={this.entering}>
            {({ placement, arrowProps, poppers, show, ...props }) => {
              // console.log(placement, arrowProps, props);

              return (
                <Tooltip id={`trigger-tool-tip`} placement={placement} style={{}}>
                  {toolTipText}
                </Tooltip>
              );
            }}
          </Overlay>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayWrap);
