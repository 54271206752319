import { APICONFIG } from '../modules/helper.module';
import Store from '../store';
import * as moment from 'moment';
import { NotifyFeedsHandler, ErrorHandleHelper } from '../modules/helper.module';
import * as logactions from '../actions/logAction.action';

export function resetStories() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RESET_STORIES',
      });
    } catch (err) {
      console.log('error in resetStories', err);
      logactions.logger({ Message: 'error in resetStories' + err });
      // logactions.addLogAction('error in resetStories :' +err)
    }
  };
}
export function resetStoriesResetHandle() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RESET_STORIES_HANDLE',
      });
    } catch (err) {
      console.log('error in resetStoriesResetHandle', err);
      logactions.logger({ Message: 'error in resetStoriesResetHandle' + err });
      // logactions.addLogAction('error in resetStoriesResetHandle :' +err)
    }
  };
}

export function getStories(howMuch, hasCuratedParam = false, withCurated = false, callForAnalysed = false) {
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl}/api/alerts/getalerts?all=${howMuch}&userName=${APICONFIG.apiLoggedInUser()}${
          hasCuratedParam ? '&withCurated=' + withCurated : ''
        }`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          let rootFeedsList = {};
          let rootFeedsIndexedRef = [];

          if (result.length) {
            console.log(result);
            let feedIds = result.map((rs) => rs.FeedId);
            let uniqueFeedIds = Array.from(new Set(feedIds));
            result.map((rs, cnt) => {
              if (uniqueFeedIds.indexOf(rs.FeedId) > -1) {
                rootFeedsList[`${rs.FeedId}`] = rs;
                rootFeedsIndexedRef.push({
                  FeedId: rs.FeedId,
                  SortByDate: rs.SortByDate,
                  notifyEvent: rs.NotifyEvent,
                  magnitudeRate: rs.MagnitudeRate,
                });
                uniqueFeedIds.splice(uniqueFeedIds.indexOf(rs.FeedId), 1);
              }
              return null;
            });
          }

          // result.forEach(res => {
          //   res.EventReadStatus = false;
          // })
          dispatch({
            type: 'GET_STORIES',
            payload: result,
            Stories: rootFeedsList,
            StoriesIndex: rootFeedsIndexedRef,
            callForAnalysed,
          });
        })
        .catch((err) => {
          console.log('error in getStories', err);
          // logactions.logger({Message: 'error in getStories'+ err})
          // throw new Error('error in  getStories' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Alerts',
            url: `${APICONFIG.apiBaseUrl}/api/alerts/getalerts?all=${howMuch}&userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high',
          };
          // throw err
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'Get Alerts'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function getStoryUpdates(howMuch, startdate, enddate, updateHandle = null) {
  // console.log("getStoryUpdates Start")
  return (dispatch) => {
    try {
      fetch(
        `${
          APICONFIG.apiBaseUrl
        }/api/alerts/updatealerts?userName=${APICONFIG.apiLoggedInUser()}&userPackage=${APICONFIG.apiLoggedInUserPackage()}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => {
          // console.log("getStoryUpdates After Update Call");
          // console.log(moment().format('HH:mm:ss'))
          return response.text();
        })
        .then((result_json) => {
          let result = JSON.parse(result_json);
          let rootFeedsUpdtList = {};
          let rootFeedsUpdtIndexedRef = [];
          // console.log(result)

          if (result.length) {
            result.map((rs) => {
              if (rs) {
                if (NotifyFeedsHandler.EventSecurityFilterPass(rs)) {
                  if (NotifyFeedsHandler.EventTypeFilterPass(rs)) {
                    if (NotifyFeedsHandler.FilterMagnitude(rs)) {
                      rootFeedsUpdtList[`${rs.FeedId}`] = NotifyFeedsHandler.EventTimeToLocal(rs);
                      rootFeedsUpdtIndexedRef.push({
                        FeedId: rs.FeedId,
                        SortByDate: rs.SortByDate,
                        notifyEvent: rs.NotifyEvent,
                        magnitudeRate: rs.MagnitudeRate,
                      });
                    }
                  }
                }
              }
              return null;
            });
          } else {
            // throw new Error(`Message : ${result.Message}`);
          }

          // console.log(result, rootFeedsList, rootFeedsIndexedRef)

          dispatch({
            type: 'UPDATE_STORIES',
            payload: result,
            UpdatedStories: rootFeedsUpdtList,
            UpdatedStoriesIndex: rootFeedsUpdtIndexedRef,
            updateHandle: updateHandle,
            preferences: Store.getState().app_preferences.preferences,
            webNotifications: Store.getState().app_preferences.webNotifications,
            systemTime: Store.getState().settings.systemTime,
          });
          let notif = Store.getState().feeds.FeedsNotifications;
          // if(notif.has) {
          dispatch({
            type: 'SET_NOTIFICATIONS',
            payload: notif,
          });
          // }
        })
        .catch((err) => {
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Update Alerts',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/alerts/updatealerts?all=${howMuch}&userName=${APICONFIG.apiLoggedInUser()}&startDate=${startdate}&endDate=${enddate}`,
            severity: 'low',
          };
          // console.log(err);
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'Update Alerts'),
            data: data,
          });
          dispatch({
            type: 'UPDATE_STORIES',
            payload: [],
            UpdatedStories: {},
            UpdatedStoriesIndex: [],
            updateHandle: updateHandle,
            preferences: Store.getState().app_preferences.preferences,
            webNotifications: Store.getState().app_preferences.webNotifications,
            systemTime: Store.getState().settings.systemTime,
          });
          console.log('error in getStoryUpdates', err);
          // logactions.logger({Message: 'error in getStoryUpdates'+ err})
          // throw new Error('error in  getgetStoryUpdatesStories' + err.Message);
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function preventStoryUpdate(status = false) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_PREVENT_STORY_UPDATE',
        payload: status,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in preventStoryUpdate'+ err})
      // logactions.addLogAction('error in  preventStoryUpdate' + err)
    }
  };
}

export function setFeedReqData(feedreqdata) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_FEEDREQDATA',
        payload: feedreqdata,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setFeedReqData'+ err})
      // logactions.addLogAction('error in  setFeedReqData' + err)
    }
  };
}

export function getStoryDetails(feedId, maxNumberOfResults = 10) {
  return (dispatch) => {
    try {
      return fetch(
        `${
          APICONFIG.apiBaseUrl
        }/api/alerts/getalertdetails?alertId=${feedId}&maxNumberOfResults=${maxNumberOfResults}&userName=${APICONFIG.apiLoggedInUser()}`,
        {
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          let res = [];
          if (result.length) {
            result.map((rs) => {
              res.push(NotifyFeedsHandler.EventDetailsTimeToLocal(rs));
              return null;
            });
          }
          dispatch({
            type: 'GET_STORY_DETAILS',
            payload: res,
            rootFeedId: feedId,
          });
          if(!result.length) {
            throw new Error(`Message : No feed details found`);
          }
        })
        .catch((err) => {
          console.log('error in getStoryDetails', err);
          // logactions.logger({Message: 'error in setFeedReqData'+ err})
          // throw new Error('error in  getStoryDetails' + err);
          // console.log(err)
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Story Details',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/alerts/getalertdetails?alertId=${feedId}&maxNumberOfResults=${maxNumberOfResults}&userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'Get Story Details'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function updateEventReadStatus(data, ColType, SavedId = false) {
  return (dispatch) => {
    // if(ColType !== 'search') {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/readstatus/updatereadstatus`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: APICONFIG.apiHeaders(),
      })
        .then((response) => {
          if (response.status === 200 && response.ok) {
            if (ColType === 'main' || ColType === 'notify' || ColType === 'analysed') {
              dispatch({
                type: 'READ_STAT_UPDATE_STORIES',
                payload: data,
              });
            } else if (ColType === 'savedsearch') {
              dispatch({
                type: 'READ_STAT_UPDATE_SAVEDSEARCH',
                payload: data,
                SavedId,
              });
            } else if (ColType === 'search') {
              dispatch({
                type: 'READ_STAT_UPDATE_SEARCH',
                payload: data,
              });
            }
          } else {
            // console.log(result)
            throw new Error(`${response.status} : ${response.statusText}`);
          }
        })
        .catch((err) => {
          console.log('error in updateEventReadStatus', err);
          // logactions.logger({Message: 'error in updateEventReadStatus'+ err})
          // throw new Error('error in  updateEventReadStatus' + err);
          // console.log(err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Event By ID',
            url: `${APICONFIG.apiBaseUrl}/api/readstatus/updatereadstatus`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'Get Event By Id'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

    // }
  };
}

async function callStoriesByPackage(Package, withCurated) {
  try {
    let freeTrial = Store.getState().settings.freeTrial;
    let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    let userPackage = APICONFIG.apiLoggedInUserPackage();
    if( userPackage.toLowerCase() === 'bronze' && freeTrial && daystogo > 0 ) {
      userPackage = 'Premium';
    }
    let response = await fetch(
      `${
        APICONFIG.apiBaseUrl
      }/api/alerts/getalertsbypackage?packageName=${Package}&withCurated=${withCurated}&userPackage=${userPackage}&extendedResults=true
      `,
      {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      }
    );
    let result = await response.json();
    return result;
  } catch (err) {
    console.log(err);
    throw err;
  }
}
async function callStoriesByLanguage(lang) {
  try {
    // let freeTrial = Store.getState().settings.freeTrial;
    // let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    // let userPackage = APICONFIG.apiLoggedInUserPackage();
    // if( userPackage.toLowerCase() === 'bronze' && freeTrial && daystogo > 0 ) {
    //   userPackage = 'Premium';
    // }
    let response = await fetch(
      `${ APICONFIG.apiBaseUrl}/api/alerts/getalertsbylanguage?language=${lang}`,
      {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      }
    );
    let result = await response.json();
    // console.log(result)
    return result;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function callStoriesFromSearch() {
  try {
    let eventFilter = Store.getState().filters.eventFilter;
    let eventTypes = Store.getState().filters.eventTypes;

    let EventCategories = eventTypes
      .map((evnt) => (evnt.EventTypeParent === 0 ? evnt.Name : false))
      .filter((itm) => itm);

    let userSelectedCategories = EventCategories.map((EvntCat) =>
      eventFilter.indexOf(EvntCat) > -1 ? EvntCat : false
    ).filter((itm) => itm);

    let securitiesFilter = Store.getState().filters.securitiesFilter;
    securitiesFilter = securitiesFilter.length > 50 ? securitiesFilter.slice(0, 50) : securitiesFilter;
    // Store.getState().app_preferences.preferences.search_preferences;

    let searchWithRed = Store.getState().app_preferences.preferences.event_importance_filters.Red_Colored_Events
      .Show_Red;
    let searchWithOrange = Store.getState().app_preferences.preferences.event_importance_filters.Yellow_Colored_Events
      .Show_Yellow;
    let searchWithYellow = Store.getState().app_preferences.preferences.event_importance_filters.Gray_Colored_Events
      .Show_Gray;
    let searchWithNACE = Store.getState().app_preferences.preferences.event_importance_filters.Non_Active_Curated_Events
      .Show_NACE;
    let searchPreferences = {
      Search_with_gray: searchWithNACE,
      Search_with_black: false,
      Search_with_yellow: searchWithYellow,
      Search_with_orange: searchWithOrange,
      Search_with_red: searchWithRed,
    };

    let startDate = moment().utcOffset(0).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
    let endDate =
      APICONFIG.apiLoggedInUserPackage().toLowerCase() === 'bronze'
        ? moment().utcOffset(0).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
    let freeTrial = Store.getState().settings.freeTrial;
    let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    let userPackage = APICONFIG.apiLoggedInUserPackage();
    if( userPackage.toLowerCase() === 'bronze' && freeTrial && daystogo > 0 ) {
      userPackage = 'Premium';
    }
    let response = await fetch(
      `${
        APICONFIG.apiBaseUrl
      }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${userSelectedCategories.join(
        ';'
      )}&securityTypes=${''}&securities=${securitiesFilter.join(
        ';'
      )}&&startDate=${startDate}&endDate=${endDate}&freeText=${''}${'&update=false'}${
        '&withCurated=' + searchPreferences.Search_with_gray
      }${'&withBlack=' + searchPreferences.Search_with_black}${'&withYellow=' + searchPreferences.Search_with_yellow}${
        '&withOrange=' + searchPreferences.Search_with_orange
      }${'&withRed=' + searchPreferences.Search_with_red}${'&userPackage=' + userPackage}`,
      {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      }
    );
    let result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
}
export function getStoriesByPackage(Package, withCurated) {
  let FetchedResults = {};
  let FetchedIndex = [];
  let NACE = Store.getState().app_preferences.preferences.event_importance_filters.Non_Active_Curated_Events.Show_NACE;
  let lang = Store.getState().settings.lang
  return (dispatch) => {
    try {
      if(lang === false) {
        callStoriesByPackage(Package, withCurated)
        .then((result) => {
          // console.log(result)
          if (result.length) {
            let correctedresult = NotifyFeedsHandler.fixeventmissingprops(result);
            // console.log(correctedresult)
            let downgradedFilterList = NotifyFeedsHandler.FormatandDowngradeEvents(correctedresult);
            // console.log(downgradedFilterList)
            // console.log('call results', result)
            let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(downgradedFilterList);
            // console.log('filtered results', rootFeedsList)
            FetchedResults = rootFeedsList;
            FetchedIndex = rootFeedsIndexedRef;
            if (withCurated) {
              callStoriesByPackage(Package, !withCurated).then((resultA) => {
                // console.log(resultA)
                let correctedresultA = NotifyFeedsHandler.fixeventmissingprops(resultA);
                console.log(correctedresult)
                let downgradedFilterListA = NotifyFeedsHandler.FormatandDowngradeEvents(correctedresultA);
                let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(downgradedFilterListA);
                // console.log(rootFeedsIndexedRef);//36042692 36042692 36041037
                let StoriesData = NotifyFeedsHandler.UniqueMergeEventsByPackage(
                  FetchedResults,
                  FetchedIndex,
                  rootFeedsList,
                  rootFeedsIndexedRef
                );

                let newStoriesList = StoriesData.newList;
                let newStoriesIndex = StoriesData.newIndex;

                dispatch({
                  type: 'GET_STORIES_BY_PACKAGE',
                  payload: downgradedFilterList.concat(downgradedFilterListA),
                  Stories: newStoriesList,
                  StoriesIndex: newStoriesIndex,
                  NACE,
                  withCurated,
                });
                dispatch({
                  type: 'SET_INIT_REQUEST_STATUS',
                  payload: false,
                });
                // if (newStoriesIndex.length <= 10) {
                //   callStoriesFromSearch().then((srchResult) => {
                //     let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(srchResult);

                //     dispatch({
                //       type: 'GET_STORIES_BY_PACKAGE',
                //       payload: srchResult,
                //       Stories: rootFeedsList,
                //       StoriesIndex: rootFeedsIndexedRef,
                //       NACE,
                //       withCurated,
                //     });
                //     dispatch({
                //       type: 'SET_INIT_REQUEST_STATUS',
                //       payload: false,
                //     });
                //   });
                // } else {
                //   dispatch({
                //     type: 'GET_STORIES_BY_PACKAGE',
                //     payload: result.concat(resultA),
                //     Stories: newStoriesList,
                //     StoriesIndex: newStoriesIndex,
                //     NACE,
                //     withCurated,
                //   });
                //   dispatch({
                //     type: 'SET_INIT_REQUEST_STATUS',
                //     payload: false,
                //   });
                // }
              });
            } else {
              // if (FetchedIndex.length <= 10) {
              //   callStoriesFromSearch().then((srchResult) => {
              //     let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(srchResult);
              //     let StoriesData = NotifyFeedsHandler.UniqueMergeEventsByPackage(
              //       FetchedResults,
              //       FetchedIndex,
              //       rootFeedsList,
              //       rootFeedsIndexedRef
              //     );
              //     let newStoriesList = StoriesData.newList;
              //     let newStoriesIndex = StoriesData.newIndex;
              //     dispatch({
              //       type: 'GET_STORIES_BY_PACKAGE',
              //       payload: srchResult,
              //       Stories: newStoriesList,
              //       StoriesIndex: newStoriesIndex,
              //       NACE,
              //       withCurated,
              //     });
              //     dispatch({
              //       type: 'SET_INIT_REQUEST_STATUS',
              //       payload: false,
              //     });
              //   });
              // } else {
                dispatch({
                  type: 'GET_STORIES_BY_PACKAGE',
                  payload: result,
                  Stories: FetchedResults,
                  StoriesIndex: FetchedIndex,
                  NACE,
                  withCurated,
                });
                dispatch({
                  type: 'SET_INIT_REQUEST_STATUS',
                  payload: false,
                });
              // }
            }
            window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
            window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
            window.Mixpanel.actions
              .track('Crypto Column Events loaded successfully', window.Mixpanel.MixpanelProps)
              .then((data) => {
                window.Mixpanel.actions.people.set({
                  UserName: APICONFIG.apiLoggedInUser(),
                });
                window.Mixpanel.actions.resetProp('Error');
              });
          } else {
            throw new Error(`Message : ${result.Message}`);
          }
        })

        .catch((err) => {
          // console.log(err);
          // console.log(ErrorHandleHelper.generateErrorString(err, 'Get Stories By Package'))
          console.log('error in getStoriesByPackage', err);
          // logactions.logger({Message: 'error in getStoriesByPackage'+ err})
          // throw new Error('error in  getStoriesByPackage' + err);
          window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
          window.Mixpanel.MixpanelProps.Error = err;
          window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
          window.Mixpanel.actions
            .track('Crypto Column Events loading failed', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: APICONFIG.apiLoggedInUser(),
              });
              window.Mixpanel.actions.resetProp('Error');
            });
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Stories by Package',
            url: `${APICONFIG.apiBaseUrl}/api/Alerts?packageName=${Package}&withCurated=${withCurated}`,
            severity: 'high',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'Get Stories By Package'),
            data: data,
          });
        });
      } else {
        callStoriesByLanguage(lang)
        .then((result) => {
          // console.log(result)
          if (result.length) {
            let correctedresult = NotifyFeedsHandler.fixeventmissingprops(result);
            // console.log(correctedresult)
            // return
            // Retweets:2;Engaement:2147;Replies:8;Quote:1;Likes:20;Source:Twitter;SentimentTotal:8;Sentiment Label:Positive;Sentiment Score:8;Sentiment Asset Label:Nvidia;Algolocation:Nvidia-up
            let downgradedFilterList = NotifyFeedsHandler.FormatandDowngradeEvents(correctedresult);
            let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(downgradedFilterList);
            dispatch({
              type: 'GET_STORIES_BY_PACKAGE',
              payload: downgradedFilterList,
              Stories: rootFeedsList,
              StoriesIndex: rootFeedsIndexedRef,
              NACE,
              withCurated,
            });
            dispatch({
              type: 'SET_INIT_REQUEST_STATUS',
              payload: false,
            });
          }
        })
      }
      
    } catch (err) {
      console.log(err)
    }
  };
}

export function storyopenlogger(FeedId, status) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'OPEN_CLOSE_STORY_TRACK',
        FeedId,
        payload: status,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in storyopenlogger'+ err})
    }
  };
}
