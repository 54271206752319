import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './custom.css';
import 'font-awesome/css/font-awesome.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { hasToken, hasToken_LLU, loginUser } from './actions/authenticate.action';
import { installedAppData, updaterendertype, updateforcetheme, updatelanguagesettings, setiframelogo } from './actions/settings.action';
import { setusertheme } from './actions/apppreferences.action';
import { Mixpanel, Hotjar } from './modules/analytics.module';
import withWrapHandle from './components/wraphandles/generalwraphandle';
import ErrorGlobalBoundary from './components/error/error-global';
import GridLoader from './components/shared/loaders/apploadergrid/apploaderGrid';
import AppRouter from './AppRouter';
import { LoginlessUsers}  from './static_data/loginlessuserdata';
import { timers } from 'jquery';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.apploading,
      relatedApp: ''
    };
    this.loaderTimeout = ''
    new Hotjar();
    window.Mixpanel = Mixpanel;
    window.Mixpanel.actions.init(props.mixpanelToken);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let logintype = '';
    let emailid = '';
    let code = ''; 
    let magiclogin = false;
    let org = '';
    
    
    if(window.location.host.includes('qaevents.crowdsense.ai')) {
    } else if(window.location.host.includes('events.crowdsense.ai')) {
      // console.log(urlParams)
      // console.log(queryString)
      window.location.href = `https://events.algense.com/${queryString}`;
    }
    if(urlParams.has('lang')) {
      let language = urlParams.get('lang');
      props.updatelanguagesettings(language);
    }
    // console.log(urlParams.has('render'))
    if(urlParams.has('render')) {
      let rendertype = urlParams.get('render');
      let theme = '';
      let iframelogo = false;
      // console.log(rendertype)
      if(rendertype === 'app' || rendertype === 'iframe') {
        if(rendertype === 'iframe' && urlParams.has('theme')) {
          theme = urlParams.get('theme');
          if(theme === 'light' || theme === 'dark') {
            props.setusertheme(theme);
            props.updateforcetheme(theme);
            // window.localStorage.setItem(`${APPPATH.AppUrl}${this.props.userData.username}Theme`, this.state.theme)
          }
        }
        if(rendertype === 'iframe' && urlParams.has('withlogo')) {
          iframelogo = urlParams.get('withlogo');
          // if(theme === 'light' || theme === 'dark') {
            props.setiframelogo(iframelogo);
            // window.localStorage.setItem(`${APPPATH.AppUrl}${this.props.userData.username}Theme`, this.state.theme)
          // }
        }
        props.updaterendertype(rendertype);
      }
    }
    if(urlParams.has('org')) {
      org = urlParams.get('org');
      console.log(LoginlessUsers[org])
      if(LoginlessUsers[org] && Object.keys(LoginlessUsers[org]).length) {
        if(urlParams.has('reset') && urlParams.get('reset')) {
          window.localStorage.removeItem('userData');
          window.localStorage.removeItem('tnc_accepted');
        } else {
          // window.localStorage.removeItem('userData');
          // window.localStorage.removeItem('tnc_accepted');
          let info = window.localStorage.getItem('userData');
          let userData;
          let terms = true;
          if(urlParams.has('terms')) {
            terms = urlParams.get('terms');
          }
          if(info) {
            userData = JSON.parse(info);
            if(terms === false || terms === 'false') {
              userData.TNC = true;
              info = JSON.stringify(userData)
            }
            // console.log(userData.loginlessuser)
            console.log(LoginlessUsers[org])
            if(typeof userData.loginlessuser !== 'undefined' && userData.loginlessuser !== false && userData.loginlessuser.toLowerCase() === org.toLowerCase()) {
              props.hasToken_LLU(info, LoginlessUsers[org]);
            } else {
              props.loginUser(LoginlessUsers[org], org, terms);
            }
          } else {
            props.loginUser(LoginlessUsers[org], org, terms);
          }
        }
        // console.log(LoginlessUsers[org])
        
        
      }
    } else if(urlParams.has('logintype')) {
      logintype = urlParams.get('logintype');
      if(logintype === 'magiclink') {
        emailid = urlParams.get('emailId');
        code = urlParams.get('code');
        // console.log(logintype, emailid, code)
        let data = {
          username: emailid,
          password: `##Link#AC${code}`
        }
        props.loginUser(data);
      }
    } else {
      
      let info = window.localStorage.getItem('userData');
      let userData;
      if(info) {
        userData = JSON.parse(info);
        if(typeof userData.loginlessuser !== 'undefined' && userData.loginlessuser) {
          window.localStorage.removeItem('userData');
          info = false;
        }
      }
      
      props.hasToken(info);
    }
    
    
  }

  componentWillMount() {
    // window.localStorage.removeItem('userData');
    // window.localStorage.removeItem('isProFlag');
    
    // navigator.getInstalledRelatedApps().then(data => {
    //   console.log(data)
    //   if(data.length) {
    //     this.props.installedAppData(data)
    //   }
    // })
  }

  componentDidMount() {
    if(this.state.loading) {
      // this.loaderTimeout = setTimeout(() => {
      //   window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      //   window.Mixpanel.actions.identify(this.props.userData.username);
      //   window.Mixpanel.actions.track(`Blank Screen Reloading`, window.Mixpanel.MixpanelProps).then((data) => {
      //     window.Mixpanel.actions.people.set({
      //       UserName: this.props.userData.username,
      //     });
          
      //   });
      //   window.location.reload();
      // }, 30000)
    }
  }

  componentWillReceiveProps(nextProps) {
    clearTimeout(this.loaderTimeout);
    this.setState({
      ...this.state,
      loading: nextProps.apploading,
    },() => {
      if(this.state.loading) {
        // this.loaderTimeout = setTimeout(() => {
        //   window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        //   window.Mixpanel.actions.identify(this.props.userData.username);
        //   window.Mixpanel.actions.track(`Blank Screen Reloading`, window.Mixpanel.MixpanelProps).then((data) => {
        //     window.Mixpanel.actions.people.set({
        //       UserName: this.props.userData.username,
        //     });
            
        //   });
        //   window.location.reload();
        // }, 10000)
      }
    });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // if(this.props.userData !== nextProps.userData) {
  //   //   return true;
  //   // }
  //   // return false;
  // }

  render() {
    const { loading } = this.state;
    return (
      <Container fluid={true}>
        {/* <Row className="justify-content-center text-center">
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={``} >
            { JSON.stringify(this.state.relatedApp) }
          </Col>
        </Row> */}
        <Row className="justify-content-center text-center">
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={``} style={{ overflow: 'hidden' }}>
            {/* <FetchErrorRequest {...this.props} > */}
            {!loading ? <AppRouter /> : <GridLoader Theme={this.props.Theme} />}
            {/* <AppRouter /> */}
            {/* </FetchErrorRequest> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToApp = (state) => {
  return {
    apploading: state.settings.apploading,
    mixpanelToken: state.settings.mixpanel.token,
    userData: state.settings.userData,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  hasToken, hasToken_LLU, installedAppData, loginUser, updaterendertype, updateforcetheme, setusertheme, updatelanguagesettings, setiframelogo
};

export default withWrapHandle(ErrorGlobalBoundary)(connect(mapStateToApp, mapDispatchToProps)(App));
