import { APICONFIG, APPPATH, ErrorHandleHelper } from '../modules/helper.module';
import * as logactions from '../actions/logAction.action';
import * as moment from 'moment';

async function signupExternalUser(access_token) {
  return fetch(`${APICONFIG.apiBaseUrl}/api/Account/RegisterExternal`, {
    method: 'POST',
    headers: {
      'content-type': 'application/JSON',
      Authorization: 'Bearer ' + access_token,
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then(async (response) => {
      // console.log('responce:',response)
      let result = await response.json();
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function googleLoginVerify(data) {
  return (dispatch) => {
    return fetch(`${APICONFIG.apiBaseUrl}/api/Account/UserInfo`, {
      method: 'GET',
      headers: {
        'content-type': 'application/JSON',
        Authorization: 'Bearer ' + data.access_token,
      },
    })
      .then(async (response) => {
        // console.log('responce:',response)
        if (response.status === 200 && response.ok) {
          response.json().then(async (result) => {
            // console.log('LINE 18',result)
            if (result.HasRegistered) {
              data.userName = result.Email;
              let loggedIn = {
                token: data.access_token,
                isLoggedIn: true,
                token_type: 'Bearer',
                username: data.userName,
                loginTime: data['.issued'],
                expiryTime: data['.expires'],
                expiresIn: data.expires_in,
                TNC: false,
                InitialPasswordReset: true,
                usertype: '',
                userRole: '',
                userPackage: '',
              };

              let Theme = window.localStorage.getItem(`${APPPATH.AppUrl}${data.userName}Theme`)
              dispatch({
                type: 'SETUSERTHEME',
                payload: Theme ? Theme : 'dark',
              });
              //check usertype
              // const usertypeResp = `${apiBaseUrl}/api/userpreferences/getusertype?userName=${loggedIn.username}`;
              const usertypeResponse = await fetch(
                `${APICONFIG.apiBaseUrl}/api/userpreferences/getusertype?userName=${loggedIn.username}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                    Accept: 'application/json',
                  },
                }
              );
              if (usertypeResponse.status === 200 && usertypeResponse.ok) {
                const usertype = await usertypeResponse.json();
                loggedIn.usertype = usertype;
              } else {
                throw new Error(`${usertypeResponse.status} : ${usertypeResponse.statusText} - User type check failed`);
              }

              //checking terms and condition
              const tncResponse = await fetch(
                `${APICONFIG.apiBaseUrl}/api/termsandconditions/checkuser?userName=${loggedIn.username}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                    Accept: 'application/json',
                  },
                }
              );
              if (tncResponse.status === 200 && tncResponse.ok) {
                const tnc = await tncResponse.json();
                loggedIn.TNC = tnc || false;
              } else {
                throw new Error(
                  `${tncResponse.status} : ${tncResponse.statusText} - Terms and conditions check failed`
                );
              }
              //getting userpackage
              const packageResponse = await fetch(
                `${APICONFIG.apiBaseUrl}/api/userpreferences/getuserpackage?userName=${loggedIn.username}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                    Accept: 'application/json',
                  },
                }
              );
              if (packageResponse.status === 200 && packageResponse.ok) {
                const package2 = await packageResponse.json();
                // loggedIn.TNC = false;
                loggedIn.userPackage = package2;
              }
              window.localStorage.setItem('userData', JSON.stringify(loggedIn));
              //hasToken(JSON.stringify(loggedIn))
              window.location.href = APPPATH.AppUrl;
            } else {
              // throw new Error(`UserInfo Error : Error encountered on requesting userinfo.`)
              // window.location.href="https://crowdsense.ai/googlesignup";
              // console.log('noUser')
              dispatch({
                type: 'REQUEST_ERROR',
                payload: `Error: UsernotFound : Please try to login with username and password or Signup.`,
                data: {
                  action: 'UserNotFound',
                },
              });
            }
          });
        } else {
          await response.json().then((result) => {
            // console.log('LINE 17',result)
            throw new Error(`UserInfo Error : Error encountered on requesting userinfo.`);
          });
        }
      })
      .catch((err) => {
        console.log('error in googleloginverify', err);
        // logactions.logger({Message: 'error in googleloginverify'+ err})
        dispatch({
          type: 'REQUEST_ERROR',
          payload: ErrorHandleHelper.generateErrorString(err, 'GoogleLoginVerify'),
        });
      });
  };
}

export function hasToken_LLU(info, data, apiBaseUrl = APICONFIG.apiBaseUrl) {
  return (dispatch) => {
    try {
      if (info) {
        let userData = JSON.parse(info);
        let loggedIn = userData;
        let dataUrl = '';
        let DataAction = '';
        const searchParams = (params) => Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
        data.url = `${apiBaseUrl}/token`;
        // console.log(searchParams({ username: data.username, password: data.password, grant_type: 'password' }))
        fetch(`${apiBaseUrl}/token`, {
        // fetch(`http://localhost:59774/token`, {
          method: 'POST',
          body: searchParams({ username: data.username, password: data.password, grant_type: 'password' }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then(async (response) => {
          
          
          loggedIn.autologin = true;
          const result = await response.json();
          if (result.access_token && result.token_type) {
            let proStatus = window.localStorage.getItem('isProFlag');
            if(typeof proStatus === 'undefined' || proStatus === null || proStatus === '') {
              window.localStorage.setItem('isProFlag', true);
              proStatus = true;
            }
            
            loggedIn.token = result.access_token
            loggedIn.isLoggedIn = true
            loggedIn.token_type = result.token_type
            loggedIn.username = result.userName
            loggedIn.loginTime = result['.issued']
            loggedIn.expiryTime = result['.expires']
            loggedIn.expiresIn = result.expires_in
            
            let Theme = window.localStorage.getItem(`${APPPATH.AppUrl}${data.username}Theme`);
            dispatch({
              type: 'SETUSERTHEME',
              payload: Theme ? Theme : 'dark',
            });

            let tmListMaxCoinCount = 10;
            let tmtotalmaxcoincount = 1000;
            if(loggedIn.userPackage.toLowerCase() === 'bronze') {
              tmListMaxCoinCount = 10;
              tmtotalmaxcoincount = 1000;
            }
            if(loggedIn.userPackage.toLowerCase() === 'bronze') {
              let freetrial = false;
              let freetrialdetails = {
                trialEndDate: '',
                daystogo: 0
              } 
              let url = `${apiBaseUrl}/api/userpreferences/gettrial?userName=${loggedIn.username}`;
              const FreeTrialResponse = await fetch(url, {
                method: 'GET',
                headers: {
                  Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                  Accept: 'application/json',
                },
              });
              if (FreeTrialResponse.status === 200 && FreeTrialResponse.ok) {
                const FreeTrialDetails = await FreeTrialResponse.json();
                
                // console.log(FreeTrialDetails)
                if(FreeTrialDetails !== '1/1/0001 12:00:00 AM') {
                  let now = moment();
                  let tmpFr = FreeTrialDetails;
                  let date = tmpFr.split(' ')[0];
                  let formattedDate = `${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
                  // console.log(tmpFr.split(' ')[0].replace(/\//g, "."))
                  let trialExpiryDate = moment(tmpFr.split(' ').length ? formattedDate : '', 'DD.MM.YYYY');
                  
                  let daystogo = trialExpiryDate.isValid() ? trialExpiryDate.diff(now, 'days') +1 : 0;
                  freetrial = true;
                  freetrialdetails = {
                    trialEndDate: tmpFr.split(' ').length ? formattedDate : '',
                    
                    daystogo
                  }
                } else {
                  // if(Emailverify) {
                    dataUrl = `${apiBaseUrl}/api/userpreferences/settrial?userName=${loggedIn.username}`;
                    const SetFreeTrialResponse = await fetch(dataUrl, {
                      method: 'GET',
                      headers: {
                        Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                        Accept: 'application/json',
                      },
                    });
                    if (SetFreeTrialResponse.status === 200 && SetFreeTrialResponse.ok) {
                      const SetFreeTrialDetails = await SetFreeTrialResponse.json();
                      if(SetFreeTrialDetails !== '1/1/0001 12:00:00 AM') {
                        let now = moment();
                        let tmpFr = SetFreeTrialDetails;
                        let date = tmpFr.split(' ')[0];
                        let formattedDate = `${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
                        // console.log(tmpFr.split(' ')[0].replace(/\//g, "."))
                        let trialExpiryDate = moment(tmpFr.split(' ').length ? formattedDate : '', 'DD.MM.YYYY');
                        
                        let daystogo = trialExpiryDate.isValid() ? trialExpiryDate.diff(now, 'days') +1 : 0;
                        freetrial = true;
                        freetrialdetails = {
                          trialEndDate: tmpFr.split(' ').length ? formattedDate : '',
                          
                          daystogo
                        }
                      }
                    }
                  // }
                }
                // console.log(freetrial, freetrialdetails);
                dispatch({
                  type: 'SETFREETRIAL',
                  payload: {
                    freeTrial: freetrial,
                    freeTrialDetails: freetrialdetails
                  },
                });
                // return;
              }
            }
            dispatch({
              type: 'CHANGE_TM_LIMIT',
              payload: { limit : tmListMaxCoinCount, maxLimit : tmtotalmaxcoincount },
            });
            dispatch({
              type: 'HAS_TOKEN',
              payload: loggedIn,
              EmailVerify: true,
            });
            dispatch({
              type: 'DISABLE_APPLOADING',
            });
            
          }
        })
        .catch((err) => {
          console.log('error in hastoken', err);
          console.log(err.toString().split(':')[1].trim())
          // logactions.logger({Message: 'error in hastoken'+ err.Message})
          let data = {
            username: loggedIn.username,
            action: err.toString().split(':')[1].trim() === '500' ? 'FallbackScreen' : DataAction,
            url: dataUrl,
            severity: 'high',
          };
          // throw new Error('error in hastoken : '+ err)
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'HasToken'),
            data: data,
          });
        });


        
      } else {
        dispatch({
          type: 'DISABLE_APPLOADING',
        });
      }
    } catch (err) {
      console.log(err)
      //  logactions.addLogAction(err)
    }
  };
}

export function hasToken(info, apiBaseUrl = APICONFIG.apiBaseUrl) {
  return (dispatch) => {
    try {
      if (info) {
        let userData = JSON.parse(info);
        let now = moment();
        let expiry = moment(userData.expiryTime.toString());
        let dataUrl = '';
        let DataAction = '';
        let Emailverify = '';
        let difference = expiry.diff(now);
        if (difference > 0) {
          let loggedIn = userData;
          loggedIn.autologin = true;
          // if (window.sessionStorage.getItem('CancelUserRoleCheck')) {
          //   window.sessionStorage.removeItem('CancelUserRoleCheck');
          //   dispatch({
          //     type: 'HAS_TOKEN',
          //     payload: loggedIn,
          //   });
          //   dispatch({
          //     type: 'DISABLE_APPLOADING',
          //   });
          // } else {
            // loggedIn.usertype = 'company'
            // let data = {
            //   username: loggedIn.username,
            //   action: 'FallbackScreen',
            //   url: dataUrl,
            //   severity: 'high',
            // };
            // // throw new Error('error in hastoken : '+ err)
            // dispatch({
            //   type: 'REQUEST_ERROR',
            //   payload: ErrorHandleHelper.generateErrorString('Failed to Fetch', 'HasToken'),
            //   data: data,
            // });
          let Theme = window.localStorage.getItem(`${APPPATH.AppUrl}${userData.username}Theme`)
          dispatch({
            type: 'SETUSERTHEME',
            payload: Theme ? Theme : 'dark',
          });

          dataUrl = `${apiBaseUrl}/api/userpreferences/getusertype?userName=${loggedIn.username}`;
          fetch(`${apiBaseUrl}/api/userpreferences/getusertype?userName=${loggedIn.username}`, {
            method: 'GET',
            headers: {
              Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
              Accept: 'application/json',
            },
          })
          .then(async (response) => {
            if (response.status === 200 && response.ok) {
              // let res = await response.json();
              // throw new Error(`${response.status} : ${res.Message} - User role check failed`);
              return await response.json();
            } else {
              let res = await response.json();
              throw new Error(`${response.status} : ${res.Message} - User type check failed`);
            }
          })
          .then(async (result) => {
            // console.log(result)
            loggedIn.usertype = result;
            // loggedIn.usertype = 'cuser';
            dataUrl = `${apiBaseUrl}/api/userpreferences/getuserrole?userName=${loggedIn.username}`;
            DataAction = 'Get User Role';
            return fetch(`${apiBaseUrl}/api/userpreferences/getuserrole?userName=${loggedIn.username}`, {
              method: 'GET',
              headers: {
                Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                Accept: 'application/json',
              },
            })
          })
          .then(async (response) => {
            if (response.status === 200 && response.ok) {
              // let res = await response.json();
              // throw new Error(`${response.status} : ${res.Message} - User role check failed`);
              return await response.json();
            } else {
              let res = await response.json();
              throw new Error(`${response.status} : ${res.Message} - User role check failed`);
            }
          })
          .then((result) => {
            loggedIn.userRole = result;
            dataUrl = `${apiBaseUrl}/api/Account/GetEmailVerification?userName=${loggedIn.username}`;
            DataAction = 'Get Email Verification Status';
            return fetch(`${apiBaseUrl}/api/Account/GetEmailVerification?userName=${loggedIn.username}`, {
              method: 'GET',
              headers: {
                Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                Accept: 'application/json',
              },
            });
          })
          .then(async (EMLresponse) => {
            if (EMLresponse.status === 200 && EMLresponse.ok) {
              return await EMLresponse.json();
            } else {
              let res = await EMLresponse.json();
              throw new Error(`${EMLresponse.status} : ${res.Message} - GetEmailVerification Failed`);
            }
          })
          .then((EMLresult) => {
            // console.log(EMLresult)
            // loggedIn.VerifiedEmail = EMLresult;
            Emailverify = EMLresult;
            dataUrl = `${apiBaseUrl}/api/userpreferences/getuserpackage?userName=${loggedIn.username}`;
            DataAction = 'Get User Package';
            return fetch(`${apiBaseUrl}/api/userpreferences/getuserpackage?userName=${loggedIn.username}`, {
              method: 'GET',
              headers: {
                Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                Accept: 'application/json',
              },
            });
          })
          .then(async (packageResult) => {
            if (packageResult.status === 200 && packageResult.ok) {
              const UsrPackage = await packageResult.json();
              // loggedIn.TNC = false;
              loggedIn.userPackage = UsrPackage;
              let tmListMaxCoinCount = 10;
              let tmtotalmaxcoincount = 1000;
              if(UsrPackage.toLowerCase() === 'bronze') {
                tmListMaxCoinCount = 10;
                tmtotalmaxcoincount = 1000;
              }
              // initiating Free Trial Check

              if(UsrPackage.toLowerCase() === 'bronze') {
                let freetrial = false;
                let freetrialdetails = {
                  trialEndDate: '',
                  daystogo: 0
                } 
                let url = `${apiBaseUrl}/api/userpreferences/gettrial?userName=${loggedIn.username}`;
                const FreeTrialResponse = await fetch(url, {
                  method: 'GET',
                  headers: {
                    Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                    Accept: 'application/json',
                  },
                });
                if (FreeTrialResponse.status === 200 && FreeTrialResponse.ok) {
                  const FreeTrialDetails = await FreeTrialResponse.json();
                  
                  // console.log(FreeTrialDetails)
                  if(FreeTrialDetails !== '1/1/0001 12:00:00 AM') {
                    let now = moment();
                    let tmpFr = FreeTrialDetails;
                    let date = tmpFr.split(' ')[0];
                    let formattedDate = `${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
                    // console.log(tmpFr.split(' ')[0].replace(/\//g, "."))
                    let trialExpiryDate = moment(tmpFr.split(' ').length ? formattedDate : '', 'DD.MM.YYYY');
                    
                    let daystogo = trialExpiryDate.isValid() ? trialExpiryDate.diff(now, 'days') +1 : 0;
                    freetrial = true;
                    freetrialdetails = {
                      trialEndDate: tmpFr.split(' ').length ? formattedDate : '',
                      
                      daystogo
                    }
                  } else {
                    if(Emailverify) {
                      dataUrl = `${apiBaseUrl}/api/userpreferences/settrial?userName=${loggedIn.username}`;
                      const SetFreeTrialResponse = await fetch(dataUrl, {
                        method: 'GET',
                        headers: {
                          Authorization: `${loggedIn.token_type} ${loggedIn.token}`,
                          Accept: 'application/json',
                        },
                      });
                      if (SetFreeTrialResponse.status === 200 && SetFreeTrialResponse.ok) {
                        const SetFreeTrialDetails = await SetFreeTrialResponse.json();
                        if(SetFreeTrialDetails !== '1/1/0001 12:00:00 AM') {
                          let now = moment();
                          let tmpFr = SetFreeTrialDetails;
                          let date = tmpFr.split(' ')[0];
                          let formattedDate = `${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
                          // console.log(tmpFr.split(' ')[0].replace(/\//g, "."))
                          let trialExpiryDate = moment(tmpFr.split(' ').length ? formattedDate : '', 'DD.MM.YYYY');
                          
                          let daystogo = trialExpiryDate.isValid() ? trialExpiryDate.diff(now, 'days') +1 : 0;
                          freetrial = true;
                          freetrialdetails = {
                            trialEndDate: tmpFr.split(' ').length ? formattedDate : '',
                            
                            daystogo
                          }
                        }
                      }
                    }
                  }
                  // console.log(freetrial, freetrialdetails);
                  dispatch({
                    type: 'SETFREETRIAL',
                    payload: {
                      freeTrial: freetrial,
                      freeTrialDetails: freetrialdetails
                    },
                  });
                  // return;
                }
              }
              // Ending Free Trial Check
              dispatch({
                type: 'CHANGE_TM_LIMIT',
                payload: { limit : tmListMaxCoinCount, maxLimit : tmtotalmaxcoincount },
              });
              dispatch({
                type: 'HAS_TOKEN',
                payload: loggedIn,
                EmailVerify: Emailverify,
              });
              dispatch({
                type: 'DISABLE_APPLOADING',
              });
            } else {
              let res = await packageResult.json();
              throw new Error(`${packageResult.status} : ${res.Message} - User Package call failed`);
            }
          })
          .catch((err) => {
            console.log('error in hastoken', err);
            console.log(err.toString().split(':')[1].trim())
            // logactions.logger({Message: 'error in hastoken'+ err.Message})
            let data = {
              username: loggedIn.username,
              action: err.toString().split(':')[1].trim() === '500' ? 'FallbackScreen' : DataAction,
              url: dataUrl,
              severity: 'high',
            };
            // throw new Error('error in hastoken : '+ err)
            dispatch({
              type: 'REQUEST_ERROR',
              payload: ErrorHandleHelper.generateErrorString(err, 'HasToken'),
              data: data,
            });
          });
          // console.log(loggedIn)
          // }
        }
      } else {
        dispatch({
          type: 'DISABLE_APPLOADING',
        });
      }
    } catch (err) {
      //  logactions.addLogAction(err)
    }
  };
}

export function loginUser(data, organisation = false, terms = true,  apiBaseUrl = APICONFIG.apiBaseUrl) {
  return (dispatch) => {
    try {
      const searchParams = (params) =>
        Object.keys(params)
          .map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
          })
          .join('&');
      data.url = `${apiBaseUrl}/token`;
      // console.log(searchParams({ username: data.username, password: data.password, grant_type: 'password' }))
      fetch(`${apiBaseUrl}/token`, {
        // fetch(`http://localhost:59774/token`, {
        method: 'POST',
        body: searchParams({ username: data.username, password: data.password, grant_type: 'password' }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(async (response) => {
          try {
            let loggedIn;
            let status;
            let Emailverify;
            let freetrial = false;
            let freetrialdetails = {
              trialEndDate: '',
              daystogo: 0
            }
            const result = await response.json();
            // if(response.status === 200 && response.ok) {

            if (result.access_token && result.token_type) {
              // console.log(result)
              let proStatus = window.localStorage.getItem('isProFlag');
              if(typeof proStatus === 'undefined' || proStatus === null || proStatus === '') {
                window.localStorage.setItem('isProFlag', true);
                proStatus = true;
              }
              loggedIn = {
                token: result.access_token,
                isLoggedIn: true,
                token_type: result.token_type,
                username: result.userName,
                loginTime: result['.issued'],
                expiryTime: result['.expires'],
                expiresIn: result.expires_in,
                TNC: false,
                InitialPasswordReset: false,
                userRole: '',
                usertype: '',
                userPackage: '',
                isPro: proStatus,
                passlessLogin: data.password.startsWith('##Link#AC') ? true : false,
                loginlessuser: organisation
              };
              // console.log(result.userName)
              status = true;

              let Theme = window.localStorage.getItem(`${APPPATH.AppUrl}${result.userName}Theme`)
              dispatch({
                type: 'SETUSERTHEME',
                payload: Theme ? Theme : 'dark',
              });

              window.Mixpanel.MixpanelProps.UserName = result.userName;
              window.Mixpanel.MixpanelProps.LoggingInAt = moment().format('DD/MM/YYYY HH:mm:s A');
              window.Mixpanel.actions.identify(result.userName);

              window.Mixpanel.actions
                .track(`Logging In ${result.userName}`, window.Mixpanel.MixpanelProps)
                .then((data) => {
                  window.Mixpanel.actions.people.set({
                    UserName: result.userName,
                  });
                });

              data.url = `${apiBaseUrl}/api/userpreferences/getusertype?userName=${loggedIn.username}`;
              const usertypeResponse = await fetch(
                `${apiBaseUrl}/api/userpreferences/getusertype?userName=${loggedIn.username}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${result.token_type} ${result.access_token}`,
                    Accept: 'application/json',
                  },
                }
              );
              if (usertypeResponse.status === 200 && usertypeResponse.ok) {
                const usertype = await usertypeResponse.json();
                loggedIn.usertype = usertype;
              } else {
                throw new Error(`${usertypeResponse.status} : ${usertypeResponse.statusText} - User type check failed`);
              }

              data.url = `${apiBaseUrl}/api/userpreferences/getuserrole?userName=${loggedIn.username}`;
              const roleResponse = await fetch(
                `${apiBaseUrl}/api/userpreferences/getuserrole?userName=${loggedIn.username}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${result.token_type} ${result.access_token}`,
                    Accept: 'application/json',
                  },
                }
              );
              if (roleResponse.status === 200 && roleResponse.ok) {
                const Role = await roleResponse.json();
                loggedIn.userRole = Role;
              } else {
                throw new Error(`${roleResponse.status} : ${roleResponse.statusText} - User role check failed`);
              }

              data.url = `${apiBaseUrl}/api/Account/GetEmailVerification?userName=${result.userName}`;

              const EMLVrfyResponse = await fetch(
                `${apiBaseUrl}/api/Account/GetEmailVerification?userName=${result.userName}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${result.token_type} ${result.access_token}`,
                    Accept: 'application/json',
                  },
                }
              );
              if (EMLVrfyResponse.status === 200 && EMLVrfyResponse.ok) {
                const EML = await EMLVrfyResponse.json();
                console.log(EML);
                // loggedIn.TNC = false;
                // loggedIn.InitialPasswordReset = false;
                // loggedIn.VerifiedEmail = EML;
                Emailverify = EML;
              } else {
                throw new Error(
                  `${EMLVrfyResponse.status} : ${EMLVrfyResponse.statusText} - Terms and conditions check failed`
                );
              }
              //if loginless user check for terms and conditions accepeted in local storage
              // console.log(organisation)
              if(organisation !== false) {
                
                // console.log(terms)
                // console.log(window.localStorage.getItem('tnc_accepted'))
                if(terms === false || terms === 'false') {
                  // console.log('ivide')
                  loggedIn.TNC = true;
                } else {
                  let tnc = window.localStorage.getItem('tnc_accepted');
                  loggedIn.TNC = tnc || false;
                }
                console.log(loggedIn)
              } else {
                data.url = `${apiBaseUrl}/api/termsandconditions/checkuser?userName=${result.userName}`;
                const tncResponse = await fetch(
                  `${apiBaseUrl}/api/termsandconditions/checkuser?userName=${result.userName}`,
                  {
                    method: 'GET',
                    headers: {
                      Authorization: `${result.token_type} ${result.access_token}`,
                      Accept: 'application/json',
                    },
                  }
                );
                if (tncResponse.status === 200 && tncResponse.ok) {
                  const tnc = await tncResponse.json();
                  // loggedIn.TNC = false;
                  // loggedIn.InitialPasswordReset = false;
                  loggedIn.TNC = tnc || false;
                  // loggedIn.InitialPasswordReset = tnc || false;
                } else {
                  throw new Error(
                    `${tncResponse.status} : ${tncResponse.statusText} - Terms and conditions check failed`
                  );
                }
              }
              
              data.url = `${apiBaseUrl}/api/userpreferences/getuserpackage?userName=${result.userName}`;
              const packageResponse = await fetch(data.url, {
                method: 'GET',
                headers: {
                  Authorization: `${result.token_type} ${result.access_token}`,
                  Accept: 'application/json',
                },
              });
              if (packageResponse.status === 200 && packageResponse.ok) {
                const package2 = await packageResponse.json();
                // loggedIn.TNC = false;
                loggedIn.userPackage = package2;
              } else {
                throw new Error(
                  `${packageResponse.status} : ${packageResponse.statusText} - user package fetch failed`
                );
              }
              let tmListMaxCoinCount = 10;
              let tmtotalmaxcoincount = 1000;
              if(loggedIn.userPackage.toLowerCase() === 'bronze') {
                tmListMaxCoinCount = 10;
                tmtotalmaxcoincount = 1000;
              }
              dispatch({
                type: 'CHANGE_TM_LIMIT',
                payload: { limit : tmListMaxCoinCount, maxLimit : tmtotalmaxcoincount },
              });
              if(loggedIn.userPackage.toLowerCase() === 'bronze') {
                
                data.url = `${apiBaseUrl}/api/userpreferences/gettrial?userName=${result.userName}`;
                const FreeTrialResponse = await fetch(data.url, {
                  method: 'GET',
                  headers: {
                    Authorization: `${result.token_type} ${result.access_token}`,
                    Accept: 'application/json',
                  },
                });
                if (FreeTrialResponse.status === 200 && FreeTrialResponse.ok) {
                  const FreeTrialDetails = await FreeTrialResponse.json();
                  // loggedIn.TNC = false;
                  console.log(FreeTrialDetails);
                  if(FreeTrialDetails !== '1/1/0001 12:00:00 AM') {
                    let now = moment();
                    let tmpFr = FreeTrialDetails;
                    let date = tmpFr.split(' ')[0];
                    let formattedDate = `${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
                    // console.log(tmpFr.split(' ')[0].replace(/\//g, "."))
                    let trialExpiryDate = moment(tmpFr.split(' ').length ? formattedDate : '', 'DD.MM.YYYY');
                    
                    let daystogo = trialExpiryDate.isValid() ? trialExpiryDate.diff(now, 'days') +1 : 0;
                    freetrial = true;
                    freetrialdetails = {
                      trialEndDate: tmpFr.split(' ').length ? formattedDate : '',
                      
                      daystogo
                    }
                  } else {
                    if(Emailverify) {
                      data.url = `${apiBaseUrl}/api/userpreferences/settrial?userName=${result.userName}`;
                      const SetFreeTrialResponse = await fetch(data.url, {
                        method: 'GET',
                        headers: {
                          Authorization: `${result.token_type} ${result.access_token}`,
                          Accept: 'application/json',
                        },
                      });
                      if (SetFreeTrialResponse.status === 200 && SetFreeTrialResponse.ok) {
                        const SetFreeTrialDetails = await SetFreeTrialResponse.json();
                        if(SetFreeTrialDetails !== '1/1/0001 12:00:00 AM') {
                          let now = moment();
                          let tmpFr = SetFreeTrialDetails;
                          let date = tmpFr.split(' ')[0];
                          let formattedDate = `${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
                          // console.log(tmpFr.split(' ')[0].replace(/\//g, "."))
                          let trialExpiryDate = moment(tmpFr.split(' ').length ? formattedDate : '', 'DD.MM.YYYY');
                          
                          let daystogo = trialExpiryDate.isValid() ? trialExpiryDate.diff(now, 'days') +1 : 0;
                          freetrial = true;
                          freetrialdetails = {
                            trialEndDate: tmpFr.split(' ').length ? formattedDate : '',
                            
                            daystogo
                          }
                        }
                      }
                    }
                  }
                  console.log(freetrial, freetrialdetails);
                  // return;
                } else {
                  throw new Error(
                    `${FreeTrialResponse.status} : ${FreeTrialResponse.statusText} - Free Trial Details fetch failed`
                  );
                }
              }
              window.localStorage.setItem('userData', JSON.stringify(loggedIn));
              dispatch({
                type: 'LOGIN_USER',
                payload: loggedIn,
                status: status,
                EmailVerify: Emailverify,
                freeTrial: freetrial,
                freeTrialDetails: freetrialdetails
              });
              dispatch({
                type: 'DISABLE_APPLOADING',
              });
            } else if (result.error && result.error_description) {
              throw new Error(`${result.error} : ${result.error_description}`);
            } else {
              throw new Error(`${response.status} : ${response.statusText} - Invalid Error`);
            }
            // } else {
            //   throw `${response.status} : ${response.statusText} - Login Request Failed`
            // }
          } catch (err) {
            // console.log('error in loginUser', err);
            data.action = err.toString().split(':')[1].trim() === '500' ? 'FallbackScreen' : 'Try to login';
            throw new Error('error in loginUser' + err);
          }
        })
        .catch((err) => {
          // console.log('error in loginUser', err);
          // console.log('data.action', data);
          // logactions.logger({Message: 'error in loginuser'+ err})
          // throw new Error('error in loginUser' + err);
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'loginUser'),
            data,
          });
          dispatch({
            type: 'DISABLE_APPLOADING',
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function acceptTermsNCond_LLU(mark) {
  return (dispatch) => {
    try {
      let userData = JSON.parse(window.localStorage.getItem('userData'));
      userData.TNC = true;
      window.localStorage.setItem('userData', JSON.stringify(userData));
      window.localStorage.setItem('tnc_accepted', true);
      dispatch({
        type: 'TNC_ACCEPTED',
        payload: userData,
      });
      window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
      window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
      window.Mixpanel.actions .track('Terms And Conditions Accepting Call Completed', window.Mixpanel.MixpanelProps)
      .then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: APICONFIG.apiLoggedInUser(),
        });
      });
      
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function acceptTermsNCond(mark) {
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl}/api/termsandconditions/markuser?userName=${APICONFIG.apiLoggedInUser()}&mark=${mark}`,
        {
          method: 'POST',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            let userData = JSON.parse(window.localStorage.getItem('userData'));
            userData.TNC = true;
            window.localStorage.setItem('userData', JSON.stringify(userData));
            window.localStorage.setItem('tnc_accepted', true);
            dispatch({
              type: 'TNC_ACCEPTED',
              payload: userData,
            });
            window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
            window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
            window.Mixpanel.actions
              .track('Terms And Conditions Accepting Call Completed', window.Mixpanel.MixpanelProps)
              .then((data) => {
                window.Mixpanel.actions.people.set({
                  UserName: APICONFIG.apiLoggedInUser(),
                });
              });
          } else {
            throw new Error(`${response.status} : ${response.statusText}`);
          }
        })
        .catch((err) => {
          console.log('error in Terms and conditions', err);
          // logactions.logger({Message: 'error in TermsandConditions'+ err})
          // throw new Error('error in  accept Termsandconditions' + err);
          window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
          window.Mixpanel.MixpanelProps.Error = err;
          window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
          window.Mixpanel.actions
            .track('Terms And Conditions Accepting Call Failed', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: APICONFIG.apiLoggedInUser(),
              });
              window.Mixpanel.actions.resetProp('Error');
            });
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Accept Terms and Conditions',
            severity: 'high',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/termsandconditions/markuser?userName=${APICONFIG.apiLoggedInUser()}&mark=${mark}`,
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'AcceptTermsAndConditions'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function setsendmagiclinkerror(status) {
  return (dispatch) => {
    dispatch({
      type: 'PL_USERNOTFOUND',
      payload: status,
    });
  }
}

export function sendmagicloginlink(data) {
  
  return (dispatch) => {
    try {
      
      fetch(`${APICONFIG.apiBaseUrl}/api/Account/SentEmailMagicLink?username=${data.username}`, {
        method: 'GET',
        // body: JSON.stringify(data),
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            let result = await response.json();
            console.log(result);
            // dispatch({
            //   type: 'SET_NOTIFICATION_PREFERENCES',
            //   payload: data.UserPreferencesString,
            // });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in sendmagicloginlink', err);
          // logactions.logger({Message: 'error in setNotificationPreferences'+ err})
          // throw new Error('error in setNotificationPreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Send Magic link',
            url: `${APICONFIG.apiBaseUrl}/api/Account/SentEmailMagicLink?username=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'PL_USERNOTFOUND',
            payload: ErrorHandleHelper.generateErrorString(err, 'User not found'),
          });
          
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}


