import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as authActionCreator from '../actions/authenticate.action';
import RequestError from '../components/error/error-req';
import * as moment from 'moment';
import ReactDOM from 'react-dom';
import { APICONFIG , APPPATH } from '../modules/helper.module';
import * as logactions from '../actions/logAction.action';
import { isMobile } from 'react-device-detect';

class Login extends Component {
  
  constructor(props) {
    super();
    this.state = {
        loginData: {
          username: '',
          password: '',
          lwpusername: ''
        },
        
        showPass: false,
        passwordlessError: false,
        errors:props.loginError
      
    }
    // console.log(window.location)
    if (window.location.hash) {
      // console.log("getacces token : location hash");
      // console.log("location hash val :",window.location.hash);
      const params = new URLSearchParams(props.location.search);
      // console.log("params",params);
      if (window.location.hash.split('access_token=').length > 1) {
        var accessToken = window.location.hash.split('access_token=')[1].split('&')[0];        
        let data = {
          ".expires":moment().add(window.location.hash.split('expires_in=')[1].split('&')[0],"seconds").utcOffset(0).format("llll"),
          ".issued": moment().utcOffset(0).format("llll"),    
          expires_in: window.location.hash.split('expires_in=')[1].split('&')[0],
          token_type: "Bearer",
          access_token: accessToken,
          userName: ""
        }
        // console.log("Here - getacces - token -: "+accessToken);
        if (accessToken) { 
          props.googleLoginVerify(data);
        }
      // } else if(window.location.hash.split('google_token=').length > 1) {
      } else if(window.location.hash.split('provider=').length > 1) {
        var provider = window.location.hash.split('provider=')[1].split('&')[0];
        console.log(provider)
        // window.location.href = `https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1`;
        window.location.href = `https://qaapi.crowdsense.ai/api/Account/ExternalLogin?provider=${provider}&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1`;
      }
    }
  }

  componentDidMount() {
    
  
  }
  
  
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    if(nextProps.userData.isLoggedIn) {
      // this.props.history.push('/');
    } else {
      if(nextProps.loginError.error) {
        window.Mixpanel.MixpanelProps.UserName = this.state.loginData.username;
        window.Mixpanel.MixpanelProps.Password = this.state.loginData.password;
        window.Mixpanel.MixpanelProps.ErrorDescription = nextProps.loginError.error;
        window.Mixpanel.actions.identify(this.state.loginData.username);
        window.Mixpanel.actions.track('Unsuccessfull login attempt', window.Mixpanel.MixpanelProps).then(data => {
          window.Mixpanel.actions.people.set({
            UserName: this.state.loginData.username
          });
          window.Mixpanel.actions.resetProp('Password');
        })
        this.setState({
          ...this.state,
          errors: nextProps.loginError
        })
      } else if(!nextProps.userData.TNC) {

      }
      
    }
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      loginData: {
        ...this.state.loginData,
        [`${e.target.id}`] : e.target.value
      },
      passwordlessError: (e.target.id === 'lwpusername' && e.target.value !== '') ? false : this.state.passwordlessError
    })
  }
  handleSubmit = (e) => {
    // logactions.addLogAction('Test Error');
    e.preventDefault();
    window.Mixpanel.MixpanelProps.LoginTime = ''
    window.Mixpanel.MixpanelProps.UserName = e.target.username.value
    window.Mixpanel.MixpanelProps.actionTime = moment().format()
    // console.log('Try to login')
    window.Mixpanel.actions.identify(e.target.username.value);
    window.Mixpanel.actions.track('Try to login', window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: window.Mixpanel.MixpanelProps.UserName
      });
    })
    let data = {
      username: e.target.username.value,
      password: e.target.password.value
    }
    this.setState({
      loginData: {
        ...this.state.loginData, ...data,
      },
      passwordlessError: false,
      errors: {}
    },() => {
      this.props.loginUser(this.state.loginData);
    })
  }


  handleuserClick = (e, text) => {
    if(e.target.username.value !== '') {
      // console.log(text)
      // window.Mixpanel.MixpanelProps.LoginTime = ''
      window.Mixpanel.MixpanelProps.UserName = e.target.username.value
      window.Mixpanel.MixpanelProps.actionTime = moment().format()
      // console.log('Try to login')
      window.Mixpanel.actions.identify(e.target.username.value);
      window.Mixpanel.actions.track(`Forgot ${text}`, window.Mixpanel.MixpanelProps)
      .then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: window.Mixpanel.MixpanelProps.UserName
        });
      })
    }
    
  }
  passwordlesslogin = () => {
    if(this.state.loginData.lwpusername !== '') {
      this.props.sendmagicloginlink({username: this.state.loginData.lwpusername});
      this.props.showmodal('passwordlesslogin', {username: this.state.loginData.lwpusername});
    } else {
      this.setState({
        ...this.state,
        passwordlessError: true
      })
    }
  }

  toggleShowPass = (e) => {
    console.log(e.target.value)
    this.setState({
      ...this.state,
      showPass: !this.state.showPass
    })
  }

  render() {
    const { loginData, passwordlessError, showPass } = this.state;
    let  { errors } = this.state;
    let errorlist;
    // console.log(encodeURIComponent(APPPATH.AppUrl))
    if(errors.error && !errors.passwordlesslogin) {
      errorlist = <RequestError error={errors} />
      
    }
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let FromMobile = params && Object.keys(params).length !== 0 && Object.getPrototypeOf(params) === Object.prototype && typeof params.mobileapp !== 'undefined' && params.mobileapp === 'true';
    const signuplink = (window.location.host.includes('events.algense.com') || window.location.host.includes('qaevents.crowdsense.ai')) ? 'https://www.algense.com/dashboardsignup' : 'https://crowdsense.ai/signup'
    return(
      <Row className="justify-content-center text-center">
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
        
          <form onSubmit={this.handleSubmit}>
            <Row className="pad15" >
              
              <Col lg={6} sm={6} xs={6} md={6} xl={6} >
                <div className="form-group">
                  <Row className="">
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className=''>
                      <div className="form-group login-sec">
                        <div onClick={()=>{
                            window.location.href = `https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1`
                          }} className="btn btn-google-login ">
                              <div className="google-icon-wrap">
                                <span className="google-icon ">
                                  <img src={`${APPPATH.ImageUrl}/googleicon.png`} />
                                </span>
                              </div>
                              <div className="google-text-wrap " ><p className="google-text">Google {isMobile ? '' :'Login'}</p></div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} sm={6} xs={6} md={6} xl={6} >
                <div className="form-group">
                  <Row className="">
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className=''>
                      <div className="form-group login-sec">
                        <div onClick={()=>{
                            window.location.href = `https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Facebook&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1`
                          }} className="btn btn-google-login ">
                              <div className="google-icon-wrap">
                                <span className="google-icon ">
                                  <img src={`${APPPATH.ImageUrl}/fbLogo.png`} />
                                </span>
                              </div>
                              <div className="google-text-wrap " ><p className="google-text">Facebook {isMobile ? '' :'Login'}</p></div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                <p className="divider-text"><span className="padlr5">or login with email and password</span></p>
              </Col>
              {errorlist}
              
              <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                <div className="form-group">
                  <label htmlFor="username" className="fleft">Email :</label>
                  <input type="text" className="form-control" name="username" id="username" onChange={this.handleChange} defaultValue={loginData.username}  />
                </div>
                <div className="form-group">
                  <label htmlFor="eventname" className="fleft">Password :</label>
                  <input type={`${showPass ? 'text' : 'password'}`} className="form-control" name="password" id="password" onChange={this.handleChange} defaultValue={loginData.password}   />
                </div>
                <Row className={`marg-0 nopad `}>
                  <Col lg={6} sm={6} xs={6} md={6} xl={6} className={`text-left nopad`} >
                    <div className="form-group forgotpass-sec" onClick={(e) => this.handleuserClick(e, 'password')}>
                      <a href="https://admin.crowdsense.ai/Account/ForgotPassword" target="_blank" rel="noopener noreferrer" onClick={() => { 
                        if(FromMobile) {
                          window.location.href = "https://admin.crowdsense.ai/Account/ForgotPassword";
                        } else {
                          window.open(`https://admin.crowdsense.ai/Account/ForgotPassword`, '_blank');
                        }
                      }} >Forgot Password? </a> 
                    </div>
                  </Col>
                  <Col lg={6} sm={6} xs={6} md={6} xl={6} className={`text-right nopad`} >
                  <div className="form-group form-check forgotpass-sec">
                    <input type="checkbox" className="form-check-input margt-28rem" onChange={this.toggleShowPass} value={showPass} />
                    <label className={`form-check-label margt1 ${showPass ? 'text-blue2' : 'text-offWhite4'}`}>Show Password</label>
                  </div>
                    {/* <div className="form-group forgotpass-sec" >
                      <a  rel="noopener noreferrer"  >Show password </a> 
                    </div> */}
                  </Col>
                </Row>
                
                <div className="form-group">
                  <div className="form-group login-sec margb10">
                    <button type="submit" className="btn btn-login "  >Login</button>  
                    
                  </div>
                </div>
                
                
              </Col>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                <p className="divider-text"><span className="padlr5">or login with email</span></p>
              </Col>
              {/* {
                passwordlessError && (
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                    <div className="request-error1">
                      Please type a valid email below
                    </div>
                  </Col>
                )
              } */}
              {
                errors.error && errors.passwordlesslogin ? (
                  <RequestError error={errors} />
                ) : passwordlessError ? (
                  <RequestError error={{appError: 'Please type a valid email below'}} />
                ) : ('')
                  
                
              }
              <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                <div className="form-group-mb7">
                  <label htmlFor="lwpusername" className="fleft">Email :</label>
                  <input type="text" className="form-control" name="lwpusername" id="lwpusername" onChange={this.handleChange} defaultValue={loginData.lwpusername}  />
                </div>
                <div className="form-group margb10">
                  <div className="form-group login-sec">
                    <button type="button" className="btn btn-loginWpass " onClick={this.passwordlesslogin}  >Login without Password</button>  
                    
                  </div>
                </div>
              </Col>
              {/* <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center" >
                
              </Col> */}
              {/* <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center" onClick={(e) => this.handleuserClick(e, 'username')}>
                <div className="form-group forgotpass-sec">
                  <a href="https://admin.crowdsense.ai/Account/ForgotUsername" target="_blank" rel="noopener noreferrer">Forgot Username? </a> 
                </div>
              </Col> */}
              {/* <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                <p className="divider-text"></p>
              </Col> */}
              <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                <p className="divider-text"><span className="padlr5">Don't have an account?</span></p>
              </Col>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center">
                <div className="form-group signup-sec">
                  <a href={`${signuplink}`} target="_blank" rel="noopener noreferrer" onClick={() => { 
                    if(FromMobile) {
                      window.location.href = signuplink;
                    } 
                    // else {
                    //   window.open(`https://crowdsense.ai/signup`, '_blank');
                    // }
                  }} className="btn btn-signup">Sign up</a>
                </div>
              </Col>
              {/* <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center text-right">
                <div className="form-group forgotpass-sec">
                  Forgot Password? <a href="https://admin.crowdsense.ai/Account/ForgotPassword" target="_blank" rel="noopener noreferrer">Click Here</a> 
                </div>
                <div className="form-group forgotpass-sec">
                  Forgot Username? <a href="https://admin.crowdsense.ai/Account/ForgotUsername" target="_blank" rel="noopener noreferrer">Click Here</a> 
                </div>
                <div className="form-group signup-sec">
                  Don't have an account? <a href="https://crowdsense.ai/signup" target="_blank" rel="noopener noreferrer">Click Here</a>
                </div>
              </Col> */}
            </Row>
          </form>
          
        </Col>
      </Row>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    loginError: {
      error: state.error.auth,
      appError: state.error.appError,
      passwordlesslogin: state.error.passwordlesslogin
    }
  }
}

export default connect(mapStateToProps, authActionCreator)(Login)