import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { updateActiveColumn, SetPostponeUpdate } from '../../actions/settings.action';

class Swipeable extends Component {
    constructor(props) {
        super();
        console.log(props)
        this.state = {
          FeedList: {}
        }
        this.feedlist = {}
        this.hasTouch = true;
        this.TouchStartX = '';
        this.TouchStartY = '';
        this.TouchInitX = '';
        this.TouchInitY = '';
        this.scrollPos = '';
        this.ActiveTab = '';
        this.BeforeActiveTab = '';
        this.AfterActiveTab = '';
        this.swipeLock = false;
    }

    
    componentDidUpdate(prevProps, prevState) {
      // console.log('swipeable updated')
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //   const ChangedProps = Object.keys(this.props).map((prop, ind) => {
    //       if (!(nextProps[prop] instanceof Function)) {
    //         if (nextProps[prop] !== this.props[prop]) {
    //           // console.log(`props ${prop} not equal`); //not shallow equal
              
    //           if(prop !== 'children') {
    //             if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.props[prop])) {
    //               // console.log(`and props ${prop} when stringified is not equal`) //not Deep Equal
    //               return prop;
    //             }
    //           }
              
              
    //         }
    //       }
    //       return null;
    //     })
    //     .filter((itm) => itm);
    //   const ChangedState = Object.keys(this.state).map((prop, ind) => {
    //       if (!(nextState[prop] instanceof Function)) {
    //         if (nextState[prop] !== this.state[prop]) {
              
    //           // console.log(`state ${prop} not equal`); //not shallow equal
    //           if(prop !== 'children') {
    //             if (JSON.stringify(nextState[prop]) !== JSON.stringify(this.state[prop])) {
    //               // console.log(`and state ${prop} when stringified is not equal`) //not Deep Equal
    //               return prop;
    //             }
    //           }
              
    //         }
    //       }
    //       return null;
    //     })
    //     .filter((itm) => itm);
    //   // console.log('stories component trying to update')
    //   // console.log(ChangedProps)
    //   // console.log(ChangedState)
    //   if (!ChangedProps.length && !ChangedState.length) {
    //     return false;
    //   }
    //   // console.log('stories component is updating')
    //   return true;
    // }

    setSwipeableOptions = (options) => {
      // console.log(options)
      this.ActiveTab = options.ActiveTab
      this.BeforeActiveTab = options.BeforeActiveTab
      this.AfterActiveTab = options.AfterActiveTab
      this.feedlist = options.feedlist
      // console.log(this.ActiveTab, this.BeforeActiveTab, this.AfterActiveTab)
      this.props.updateActiveColumn(this.ActiveTab, this.BeforeActiveTab, this.AfterActiveTab);
      // console.log(this.feedlist, options)
      // if(JSON.stringify(options.feedList) !== JSON.stringify(this.state.FeedList)) {
      //   this.setState({
      //     ...this.state,
      //     FeedList: options.feedlist
      //   })
      // }
      
    }

    lockSwipe = () => {
      let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
      if(scrollDiv) {
        scrollDiv.scrollLeft = this.scrollPos
        this.swipeLock = true;
      }
    }

    TouchInit = (e) => {
      // e.preventDefault();
      let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
      if(scrollDiv) {
        scrollDiv.style.overflowX = 'hidden'
        this.scrollPos = scrollDiv.scrollLeft;
        // console.log('scrollPosition-',this.scrollPos)
      }
      
      
      this.TouchStartX = e.touches[0].clientX;
      this.TouchStartY = e.touches[0].clientY;
      this.TouchInitX = this.TouchStartX;
      this.TouchInitY = this.TouchStartY;
      this.props.SetPostponeUpdate(true);
      // console.log('Touch')
      // console.log('Touch start X-', this.TouchStartX)
      // console.log('Touch start Y-', this.TouchStartY)
  
    }
    TouchMove = (e) => {
      // e.preventDefault();
     

      let SwipeCoveredDistanceChange = 0
      let totalSwipeDistanceCovered = 0;
      SwipeCoveredDistanceChange = this.TouchStartX - e.touches[0].clientX
      totalSwipeDistanceCovered = this.TouchInitX - e.touches[0].clientX
      let slopeindeg = this.getSwipeSlope(e.touches[0].clientX, e.touches[0].clientY);
      this.TouchStartX = e.touches[0].clientX
      this.TouchStartY = e.touches[0].clientY
      let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];

      let FeedListKeys = typeof this.feedlist !== 'undefined' && Object.keys(this.feedlist).length ? Object.keys(this.feedlist) : [];
      // let scrollDiv = e.currentTarget;
      let ScrollPosActive = ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(this.ActiveTab)) -10
      let ScrollPosBefore = typeof this.BeforeActiveTab !== 'undefined' ? ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(this.BeforeActiveTab)) -10 : ScrollPosActive
      
      let ScrollPosAfter = typeof this.AfterActiveTab !== 'undefined' ? ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(this.AfterActiveTab)) -10 : ScrollPosActive
     
      
      if(scrollDiv.scrollLeft >= ScrollPosAfter) {
        scrollDiv.scrollLeft = ScrollPosAfter
      } else if(e.currentTarget.scrollLeft <= ScrollPosBefore) {
        scrollDiv.scrollLeft = ScrollPosBefore
      } else {
        // console.log(slopeindeg)
        // if(slopeindeg >= -20 && slopeindeg <= 20 || slopeindeg >= -160 && slopeindeg >= 160) {
          if(!this.swipeLock) {
            scrollDiv.scrollLeft = this.scrollPos + totalSwipeDistanceCovered
          }
          
        // }
        // console.log('movingscrollPosition-',scrollDiv.scrollLeft)
      }
      
      // if(slopeindeg >= -80 && slopeindeg <= 80 || slopeindeg >= -100 && slopeindeg >= 100) {
        
      // }
      
      
    };
    TouchEnd = (e) => {
      let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
      if(scrollDiv) {
        scrollDiv.style.overflowX = 'hidden'
      }
      if(!this.swipeLock) {
        this.hasTouch = false;
        // console.log('touch end')
        
        let Wrapper = e.currentTarget.children;
        let cols = Wrapper[0].children;
        let ColWidth = 0;
        let FeedListKeys = typeof this.feedlist !== 'undefined' && Object.keys(this.feedlist).length ? Object.keys(this.feedlist) : [];
        for(let i=0; i<cols.length; i++) {
          if(ColWidth === 0) {
            if(cols[i].clientWidth > 0) {
              ColWidth = cols[i].clientWidth
            }
          }
          cols[i].style.transition = 'all 1s ease-in';
        }
        let swipeDir = this.getSwipeDirection();
        let swipeDirTmp = swipeDir;
        if(swipeDir < 0) { swipeDirTmp = swipeDir * -1}
        let swipeMovedPerc = (swipeDir/ColWidth) * 100;

        
        if(swipeMovedPerc >= 20 || swipeMovedPerc <= -20) {
          if(this.getSwipeDirection() < 0) {
            
            let beforeActiveTab = typeof this.BeforeActiveTab !== 'undefined' ? this.BeforeActiveTab : this.ActiveTab
            // console.log('beforeActiveTab', beforeActiveTab)
            let scrollLeft = ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(beforeActiveTab)) -10
            scrollDiv.scroll({
              left: scrollLeft <= 0 ? 0 : scrollLeft >= scrollDiv.scrollWidth ? scrollDiv.scrollWidth : scrollLeft,
              behavior: 'smooth'
            })
            this.storyTabSelect(beforeActiveTab)
          } else if(this.getSwipeDirection() > 0){
          
            let afterActiveTab = typeof this.AfterActiveTab !== 'undefined' ? this.AfterActiveTab : this.ActiveTab
            // console.log('afterActiveTab', afterActiveTab, this.AfterActiveTab, FeedListKeys)
            let scrollLeft = ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(afterActiveTab)) -10
            scrollDiv.scroll({
              left: scrollLeft <= 0 ? 0 : scrollLeft >= scrollDiv.scrollWidth ? scrollDiv.scrollWidth : scrollLeft,
              behavior: 'smooth'
            })
            this.storyTabSelect(afterActiveTab)
          }
        } else {
    
          let scrollLeft = ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(this.ActiveTab)) -10
          scrollDiv.scroll({
            left: scrollLeft <= 0 ? 0 : scrollLeft >= scrollDiv.scrollWidth ? scrollDiv.scrollWidth : scrollLeft,
            behavior: 'smooth'
          })
        }
      } else {
        scrollDiv.scrollLeft = this.scrollPos;
        this.swipeLock = false;
      }
      this.props.SetPostponeUpdate(false);
      
    }
    getSwipeDirection = () => {
      return this.TouchInitX - this.TouchStartX
    }
    getSwipeSlope = (x, y) => {
      let x1 = this.TouchInitX;
      let y1 = this.TouchInitY;
      let x2 = x;
      let y2 = y;
      let dy = y1-y2;
      let dx = x1-x2;
      let theta = Math.atan2(dy, dx);
      theta *= 180 / Math.PI;
      return theta;
    }
    getBeforeAfterActiveTab = (feedList, ActiveTab) => {
      let feedListRef = Object.keys(feedList);
      let ActiveTabIndex = feedListRef.indexOf(ActiveTab);
      let BeforeActiveTabIndex = ActiveTabIndex > 0 ? ActiveTabIndex - 1 : -1;
      let AfterActiveTabIndex = ActiveTabIndex > -1 ? ActiveTabIndex + 1 : ActiveTabIndex;
      // console.log(ActiveTabIndex, BeforeActiveTabIndex, AfterActiveTabIndex)
      return {
        FeedListRef: feedListRef,
        BeforeActiveTab: feedListRef[BeforeActiveTabIndex],
        AfterActiveTab: feedListRef[AfterActiveTabIndex],
      };
    };
    storyTabSelect = (TabType) => {
      // console.log(TabType)
      if (typeof this.feedlist !== 'undefined' && typeof this.feedlist[`${TabType}`] !== 'undefined') {
        let { FeedListRef, BeforeActiveTab, AfterActiveTab } = this.getBeforeAfterActiveTab(this.feedlist, TabType);
        // console.log(FeedListRef, BeforeActiveTab, TabType,  AfterActiveTab)
        this.ActiveTab = TabType;
        this.BeforeActiveTab = BeforeActiveTab;
        this.AfterActiveTab = AfterActiveTab;
        if(TabType === 'Trending Mentions Details') {
          this.newTabTM = true;
        }
        // console.log(this.ActiveTab, this.BeforeActiveTab, this.AfterActiveTab)
        sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
        this.props.updateActiveColumn(this.ActiveTab, this.BeforeActiveTab, this.AfterActiveTab);
      }
    };
    
    render() {
      const { children } = this.props;
      let renderedView;
      React.Children.forEach(children, (element) => {
        if (!React.isValidElement(element)) return;
        let injectedProps = {
          setSwipeableOptions: this.setSwipeableOptions,
          lockSwipe: this.lockSwipe
        }
        renderedView = React.cloneElement(element, { ...injectedProps });
      })
      return(
        <div
          className="cScrollX hidden cScroll-Mob"
          style={{
            height: `${this.props.homeviewport.height}px`,
            overflowX: 'hidden',
            overflowY: this.props.page === 'stories' ? 'hidden' : 'auto',
            position: 'relative',
            margin: '0px 0px 0px 0px',
          }}
          // onScroll={(e) => {this.swipeScroll(e)}}
          onTouchStart={this.TouchInit}
          onTouchMove={this.TouchMove}
          onTouchEnd={this.TouchEnd}
        >
          {renderedView}
        </div>
      )
    }

    

}


const mapStateToProps = (state, ownProps) => {
  return {
    // postponeupdate: state.settings.postponeupdate
  };
};

const mapDispatchToProps = {
  updateActiveColumn,
  SetPostponeUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(Swipeable);