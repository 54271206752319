import * as moment from 'moment';
import Store from '../../store';
import { IforexInstrumentorderandsymbol } from '../../static_data/iforexinstrumentids';

export const NotifyFeedsHandler = {
  BreakingEventStatus: (notifyStat, magRate) => {
    let BEStatus = false;
    if (notifyStat !== 'undefined' && notifyStat) {
      switch (magRate) {
        case 0:
          BEStatus = true;
          break;
        case 1:
          BEStatus = true;
          break;
        case 2:
          BEStatus = true;
          break;
        default:
          BEStatus = false;
          break;
      }
    }
    return BEStatus;
  },
  AllEventsFilter: (lastNBEventDate, notifyStat, SBDate) => {
    if (lastNBEventDate !== '') {
      let hours = moment(lastNBEventDate).diff(moment(SBDate), 'hours');
      if (hours > 0) {
        if (notifyStat !== 'undefined' && notifyStat) {
          return false;
        }
      }
    }
    return true;
  },
  getLatestEventTime: (LatestStory) => {
    if (LatestStory.HeadlineText !== '') {
      let start;
      let stop;
      for (let charindex = 0; charindex < LatestStory.HeadlineText.length; charindex++) {
        if (LatestStory.HeadlineText[charindex] === '[') {
          start = charindex + 1;
        } else if (LatestStory.HeadlineText[charindex] === ']') {
          stop = charindex - 1;
        }
      }
      let alertTypeText = LatestStory.HeadlineText.substr(start, stop).toLowerCase();
      let alertType = alertTypeText.replace(/^\w/, (c) => c.toUpperCase());
      let alertTime;
      if (LatestStory.RootChangeLogList.length) {
        alertTime = LatestStory.RootChangeLogList[LatestStory.RootChangeLogList.length - 1].RootChangeLogTime;
        switch (
          LatestStory.RootChangeLogList[LatestStory.RootChangeLogList.length - 1].UpdateTypeCategory.toLowerCase()
        ) {
          case 'activate':
            alertType = 'Event Alert';
            break;
          case 'detected':
            alertType = 'Event Detected';
            break;
          case 'pre trade activate':
            alertType = 'Pre trade event confirmed';
            break;
          case 'details level':
            alertType = 'Integrity Updated';
            break;
          case 'event type':
            alertType = 'Event Type Updated';
            break;
          case 'attributes':
            alertType = 'Topics Updated';
            break;
          case 'location':
            alertType = 'Location Updated';
            break;
          case 'magnitude':
            alertType = 'Magnitude Updated';
            break;
          default:
            alertType = 'Major Update';
            break;
        }
      } else {
        if (alertType === 'Updated') {
          alertType = 'Major Update';
        } else {
          alertType = 'First Feed';
        }
      }
      return alertType === 'First Feed'
        ? moment(LatestStory.FeedTime).format()
        : alertTime
        ? moment(alertTime).format('')
        : LatestStory.LastUpdateTime;
    }
    return false;
  },
  addToTop: (story, Stories, Index, max) => {
    Index.unshift({
      FeedId: story.FeedId,
      SortByDate: story.SortByDate,
      notifyEvent: story.notifyEvent,
      magnitudeRate: story.magnitudeRate,
    });
    Stories[story.FeedId] = story;

    Index.length = Index.length > max ? max : Index.length;
    let StoriesList = {};
    Index.map((key) => {
      StoriesList[`${key.FeedId}`] = Stories[`${key.FeedId}`];
      return null;
    });
    console.log(story, Stories, Index);
    return { Index, StoriesList };
  },
  UniqueMergeEventsByPackage: (oldList, oldIndex, addedList, addedIndex) => {
    oldList = { ...oldList, ...addedList };
    oldIndex = oldIndex.concat(addedIndex);
    let newList = {};
    let newIndex = [];
    let uniqueIndexes = oldIndex.map((oi) => oi.FeedId);
    // console.log(uniqueIndexes)
    let uniqueIndexIds = [...new Set(uniqueIndexes)];
    // console.log(uniqueIndexIds)
    if (oldIndex.length) {
      uniqueIndexIds.map((unqID) => {
        newList[unqID] = oldList[unqID];
        newIndex.push({
          FeedId: oldList[unqID].FeedId,
          SortByDate: oldList[unqID].SortByDate,
          notifyEvent: oldList[unqID].NotifyEvent,
          magnitudeRate: oldList[unqID].MagnitudeRate,
        });
        return null;
      });
    }
    // let newIndex = newIndextmp.sort((a,b) => {
    //   return moment(b.SortByDate) - moment(a.SortByDate)
    // });
    // console.log(newIndex)
    return { newList, newIndex };
  },
  EventTypeFilterPass: (story) => {
    let EvntTypeFilterPass = false;
    let EvntTypeFilters = Store.getState().filters.eventFilter;
    let EventTypes = Store.getState().filters.eventTypes;
    let UserType = Store.getState().settings.userData.usertype
    let formattedfilters = EvntTypeFilters.map(itm => itm.toLowerCase()).filter((itm)=>itm)
    let formattedEventTypes = EventTypes.map(itm => itm.Name.toLowerCase()).filter((itm)=>itm)
    // console.log(formattedfilters, story.EventType)
    // let EvntTypes = Store.getState().filters.eventTypes;
    // let usertype = Store.getState().settings.userData.usertype;

    // let parentFilteredEventTypes = usertype === 'None' ? EvntTypes : EvntTypes.filter(evntp => (evntp.children.length))
    // let parentFilteredEventFilter = parentFilteredEventTypes
    //   .filter((pfet) => (EvntTypeFilters.indexOf(pfet.Name) > -1 ? pfet.Name : false))
    //   .map((itm) => itm.Name.toLowerCase())
    //   .filter((itm) => itm);
    if(formattedEventTypes.indexOf(story.EventType.toLowerCase()) > -1) {
      // console.log('in EventTypes Data')
    }
    if (formattedfilters.indexOf(story.EventType.toLowerCase()) > -1) {
      // console.log('in Eventype Filter')
      EvntTypeFilterPass = true;
    }
    // if(story.EventType === 'None') {
    //   EvntTypeFilterPass = true;
    // }
    if (formattedfilters.indexOf('general updates') > -1 && story.EventType === 'None') {
      EvntTypeFilterPass = true;
    }
    if(UserType !== 'None' && (UserType !== 'WUser' && UserType !== 'WAdmin')) {
      if(story.EventType.toLowerCase() === 'exchange updates') {
        EvntTypeFilterPass = false;
      }
      if(story.EventType.toLowerCase() === 'technical analysis') {
        EvntTypeFilterPass = false;
      }
    }
    return EvntTypeFilterPass;
  },
  EventSecurityFilterPass: (story) => {
    let SecFilterPassStatus = false;

    // let filtStats = window.localStorage.getItem('AllSecuritiesFilterStatus')
    // let userData = Store.getState().settings.userData;
    // console.log('eye', filtStats)
    // if(filtStats) {
    //   let obj = JSON.parse(filtStats);
    //   if(obj[`${userData.username}`]) {
    let SecTypeFilters = Store.getState().filters.securityFilter;
    let SecFilters = Store.getState().filters.securitiesFilter;
    let EventFilters = '';
    // console.log(SecTypeFilters, SecFilters)
    if (SecTypeFilters.length && SecTypeFilters[0] !== '') {
      EventFilters = SecTypeFilters.concat(SecFilters);
    } else {
      EventFilters = SecFilters;
    }
    // console.log(EventFilters, story.CorrelationResults)
    const EventSecs =
      story.CorrelationResults !== null && story.CorrelationResults.length
        ? story.CorrelationResults.map((crlItem) => crlItem.Security).filter((itm) => itm)
        : [];

    if (EventSecs.length) {
      for (let eachSec of EventSecs) {
        if (EventFilters.indexOf(eachSec) > -1) {
          SecFilterPassStatus = true;
          break;
        }
      }
    }
    if(story && story.CorrelationResults && !story.CorrelationResults.length) {
      SecFilterPassStatus = true;
    }
    //   } else {
    //     SecFilterPassStatus = true;
    //   }
    // } else {
    //   SecFilterPassStatus = true;
    // }

    return SecFilterPassStatus;
  },
  TimeZoneOffsetSettings: () => {
    let UserTimeZone = Store.getState().settings.TimeZoneOffset;
    // let TZName = Store.getState().app_preferences.preferences.localisation_preferences.Timezone;
    // let selTmZ = '';
    // Locations.map((lc) => {
    //   if(lc.Name === TZName) {
    //     selTmZ = lc.Time
    //   }
    //   return null;
    // })

    // let tmSign = selTmZ.substring(selTmZ.indexOf('(')+1, selTmZ.indexOf(')')).replace('GMT', '').split(':')[0][0]
    // console.log(UserTimeZone)
    let offsetMins = parseInt(UserTimeZone.split(':')[0]) * 60 + parseInt(UserTimeZone.split(':')[1]);
    // console.log(offsetMins)
    return { offsetMins };
  },
  EventDetailsTimeToLocal: (feedDetail) => {
    // let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings()
    feedDetail.FeedTime = moment.utc(feedDetail.FeedTime).local().format();
    return feedDetail;
  },
  EventTimeToLocal: (story) => {
    let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings();

    story.FeedTime = moment.utc(story.FeedTime).utcOffset(offsetMins).format();
    story.LastFeedsUpdateTime = moment.utc(story.LastFeedsUpdateTime).utcOffset(offsetMins).format();

    story.TZ_FeedTime = moment.utc(story.FeedTime).utcOffset(offsetMins).format();
    story.TZ_LastFeedsUpdateTime = moment.utc(story.LastFeedsUpdateTime).utcOffset(offsetMins).format();

    return story;
  },
  GraphTimeToLocal: (data) => {
    let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings();
    // console.log(parseInt(offsetMins))
    // console.log(moment.utc(data['CoinSaveTime']).utcOffset(offsetMins).format('DD/MM/YYYY HH:mm:ss ZZ'))
    // console.log(moment.utc(data['CoinSaveTime']).utcOffset(offsetMins).format())
    // data['CoinSaveTime'] = moment.utc(data['CoinSaveTime']).utcOffset(offsetMins).format()
    let newdata = moment.utc(data).utcOffset(offsetMins).format('HH:mm');
    return newdata;
  },
  filterInLastDayData: (data) => {
    let NewArr = [];
    if (data.length) {
      data.forEach((dt) => {
        let date = moment(dt.CoinSaveTime);
        if (moment().diff(date, 'days') <= 0) {
          NewArr.push(dt);
        }
      });
    }
    return NewArr;
  },

  FormatMissingGraphData: (data) => {
    // console.log(data)
    let res = JSON.parse(JSON.stringify(data));
    let NewDataSet = [];
    if (res.length) {
      for (let i = 0; i < res.length; i++) {
        let FirstDate = moment(res[i].CoinSaveTime);
        let SecondDate = '';
        let Interval = '';
        let NewDatapoints = {};

        if (i + 1 < res.length) {
          SecondDate = moment(res[i + 1].CoinSaveTime);
        }
        if (SecondDate !== '') {
          // console.log(FirstDate, SecondDate)
          Interval = SecondDate.diff(FirstDate, 'minute');
          // console.log(Interval)
        }
        if (Interval !== '' && Interval > 21) {
          NewDatapoints = {
            CoinSaveTime: null,
            ComparedValue: null,
            SecurityName: res[i].SecurityName,
            SecurityPrice: null,
            SecurityTicker: res[i].SecurityTicker,
          };
        }
        NewDataSet.push(res[i]);
        // console.log(NewDatapoints)
        if (Object.keys(NewDatapoints).length > 0) {
          NewDataSet.push(NewDatapoints);
        }
        // // console.log(Interval, i, NewDatapoints)
        // let TmpSet = NewDataSet.length ? NewDataSet : res;
        // for(let x= 0; x<TmpSet.length; x++) {
        //   NewDataSet.push(res[x])
        //   if(x==i) {
        //     NewDataSet.push(NewDatapoints);
        //   }
        // }
      }
    }
    return NewDataSet;
  },
  FilterEventsBySourceInLocation: (result, sourceText, splitBy = ',') => {
    if (result.length) {
      let filteredresult = []
      let sourceFilters = sourceText.toLowerCase().split(splitBy)
      // console.log(sourceText, sourceFilters, splitBy)
      result.map((rs, cnt) => {
        let LocationString = rs.Location
        if(LocationString !== null) {
          let items = LocationString.split(';')
          let EventSources = [];
          if(items.length) {
            items.forEach((el, i) => {
              // console.log(el)
              let key = el.split(':')[0]
              let value = el.split(':')[1]
              if(key === 'Source') {
                EventSources = value.split(',')
              }
            })
          }
          if(EventSources.length) {
            for (let eachSrc of EventSources) {
              if (sourceFilters.indexOf(eachSrc.toLowerCase()) > -1) {
                filteredresult.push(rs);
                break;
              }
            }
          }
        }
      })
      return filteredresult;
    } else {
      return result;
    }
  },
  FormatEventsBySecuritiesFilter: (result) => {
    let rootFeedsList = {};
    let rootFeedsIndexedRef = [];
    if (result.length) {
      let feedIds = result.map((rs) => rs.FeedId);
      let uniqueFeedIds = Array.from(new Set(feedIds));

      result.map((rs, cnt) => {
        if(rs.EventType === 'None') { rs.EventType = 'General Updates' }
        if (uniqueFeedIds.indexOf(rs.FeedId) > -1) {
          if (NotifyFeedsHandler.EventSecurityFilterPass(rs)) {
            rootFeedsList[`${rs.FeedId}`] = NotifyFeedsHandler.EventTimeToLocal(rs);
            rootFeedsList[`${rs.FeedId}`].EventReadStatus = true;
            rootFeedsIndexedRef.push({
              FeedId: rs.FeedId,
              SortByDate: rs.SortByDate,
              notifyEvent: rs.NotifyEvent,
              magnitudeRate: rs.MagnitudeRate,
            });
          }
          uniqueFeedIds.splice(uniqueFeedIds.indexOf(rs.FeedId), 1);
        }
        return null;
      })
    }
    return { rootFeedsList, rootFeedsIndexedRef };
  },
  EventSecurityFilterPass: (story) => {
    let SecFilterPassStatus = false;

    // let filtStats = window.localStorage.getItem('AllSecuritiesFilterStatus')
    // let userData = Store.getState().settings.userData;
    // console.log('eye', filtStats)
    // if(filtStats) {
    //   let obj = JSON.parse(filtStats);
    //   if(obj[`${userData.username}`]) {
    let SecTypeFilters = Store.getState().filters.securityFilter;
    let SecFilters = Store.getState().filters.securitiesFilter;
    let EventFilters = '';
    // console.log(SecTypeFilters, SecFilters)
    if (SecTypeFilters.length && SecTypeFilters[0] !== '') {
      EventFilters = SecTypeFilters.concat(SecFilters);
    } else {
      EventFilters = SecFilters;
    }
    // console.log(EventFilters, story.CorrelationResults)
    const EventSecs =
      story.CorrelationResults !== null && story.CorrelationResults.length
        ? story.CorrelationResults.map((crlItem) => crlItem.Security).filter((itm) => itm)
        : [];

    if (EventSecs.length) {
      for (let eachSec of EventSecs) {
        if (EventFilters.indexOf(eachSec) > -1) {
          SecFilterPassStatus = true;
          break;
        }
      }
    }
    if(story && story.CorrelationResults && !story.CorrelationResults.length) {
      SecFilterPassStatus = true;
    }
    //   } else {
    //     SecFilterPassStatus = true;
    //   }
    // } else {
    //   SecFilterPassStatus = true;
    // }

    return SecFilterPassStatus;
  },
  
  FormatandDowngradeEvents: (result) => {
    let newfiltreslt = [];
    result.map((rs, cnt) => {
      if(NotifyFeedsHandler.downgradeFilter(rs)) {
        newfiltreslt.push(rs);        
      }
    })
    return newfiltreslt;
  },
  downgradeFilter: (rs) => {
    let pass = true;
    let hasalgolocation = false;
    let algostr = '';
    let MagnitudeRate = rs.MagnitudeRate;
    // console.log(MagnitudeRate)
    let LocationString = rs.Location
    if(MagnitudeRate >= 1) {
      // console.log(LocationString)
      if(LocationString !== null) {
        let items = LocationString.split(';');
        if(items.length) {
          items.forEach((el, i) => {
            let key = el.split(':')[0]
            let value = el.split(':')[1]
            if(key === 'Algolocation' && value !== '' && typeof value !== 'undefined') {
              hasalgolocation = true;
              algostr = value;
            }
          })
        }
      }
      // console.log(hasalgolocation)
      if(!hasalgolocation) {
        rs.OldMagnitudeRate = MagnitudeRate;
        rs.MagnitudeRate = 0;
      }
      // console.log(rs.MagnitudeRate)
    }
    return pass;
  },
  FormatBySearchedAssetfilter: (result, searchedassetString) => {
    let newfiltreslt = [];
    // console.log(searchedassetString)
    const searchedAssets = searchedassetString !== '' ? searchedassetString.split(';') : [];
    result.map((rs, cnt) => {
      // if(rs.FeedId === 49299591) {
      //   console.log(rs)
      // }
      
      if(NotifyFeedsHandler.SearchedAssetFilter(rs, searchedAssets)) {
        // if(rs.FeedId === 49299591) {
        //   console.log(rs)
        // }
        newfiltreslt.push(rs);
      }
    })
    return newfiltreslt;
  },
  SearchedAssetFilter: (rs, searchedAssets) => {
    let pass = true;
    let hasalgolocation = false;
    let algostr = '';
    let SecFilters = Store.getState().filters.securitiesFilter
    if(searchedAssets.length) {
      let correlations = rs.CorrelationResults !== null && rs.CorrelationResults.length ? JSON.parse(JSON.stringify(rs.CorrelationResults)) : []
      let Actualcorrelations = typeof rs.ActualCorrelationresults !== 'undefined' && rs.ActualCorrelationresults.length ? JSON.parse(JSON.stringify(rs.ActualCorrelationresults)) : []
      // if(rs.FeedId === 49302424) {
      //   console.log(searchedAssets)
      //   console.log(correlations)
      //   console.log(Actualcorrelations)
      // }
      let LocationString = rs.Location
      if(LocationString !== null) {
        let items = LocationString.split(';');
        if(items.length) {
          items.forEach((el, i) => {
            let key = el.split(':')[0]
            let value = el.split(':')[1]
            if(key === 'Algolocation' && value !== '' && typeof value !== 'undefined') {
              hasalgolocation = true;
              algostr = value;
            }
          })
        }
      }
      // if(rs.FeedId === 49302424) {
      //   console.log(hasalgolocation);
      //   console.log(algostr);
      // }
      if(hasalgolocation) {
      //   console.log(correlations)
      //   console.log(Actualcorrelations)
        let rearrangedcorels = [];
        // if(rs.FeedId === 49303914) {
        //   console.log(rearrangedcorels)
        // }
        searchedAssets.forEach(sa => {
          correlations.forEach(cr => {
            if(cr.Security === sa) {
              // if(rs.FeedId === 49303914) {
              //   console.log(rearrangedcorels.filter(itm => itm.Security === sa))
              // }
              if(!rearrangedcorels.filter(itm => itm.Security === sa).length) {
                rearrangedcorels.push(cr)
              }
            }
          })  
        })
        // if(rs.FeedId === 49303914) {
        //   console.log(rearrangedcorels)
        // }
        if(rearrangedcorels.length > 0) {
          correlations.forEach(cr => {
            let security = cr.Security;
            if(!rearrangedcorels.filter(itm => itm.Security === security).length) {
              rearrangedcorels.push(cr)
            }
            
          })
          // if(rs.FeedId === 49303914) {
          //   console.log(rearrangedcorels)
          // }
          rs.CorrelationResults = rearrangedcorels;
          let algoassetparts = algostr.split(',');
          let newalgoassets = [];
          if(algoassetparts.length) {
            rearrangedcorels.forEach(cr => {
              algoassetparts.forEach((algoassetstr, i) => {
                
                let asset = algoassetstr.split('-')[0];
                let assetsentiment = algoassetstr.split('-')[1];
                if(rs.FeedId === 49303914) {
                  console.log(asset, assetsentiment)
                }
                if(asset === cr.Security || asset === cr.Symbol) {
                  newalgoassets.push(`${asset}-${assetsentiment}`);
                }
                
              })
            })
            
          }
          let newalgostr = newalgoassets.join(',')
          // if(rs.FeedId === 49303914) {
          //   console.log(newalgostr)
          // }
          let algoasset = newalgoassets[0].split('-')[0];
          let direction = newalgoassets[0].split('-')[1];
          let locitems = LocationString.split(';');
          let newlocstritems = [];
          if(locitems.length) {
            locitems.forEach((el, i) => {
              let key = el.split(':')[0]
              let value = el.split(':')[1]
              if(key === 'SentimentTotal' ) {
                value = direction === 'up' ? 8 : direction === 'down' ? 2 : 5
              } else if(key === 'Sentiment Label') {
                value = direction === 'up' ? 'Positive' : direction === 'down' ? 'Negative' : 'Neutral'
              } else if(key === 'Sentiment Score') {
                value = direction === 'up' ? 8 : direction === 'down' ? 2 : 5
              } else if(key === 'Sentiment Asset Label') {
                value = algoasset;
              } else if(key === 'Algolocation') {
                value = newalgostr;
              }
              // console.log(`${rs.FeedId}`)
              // console.log(`${key}:${value}`)
              newlocstritems.push(`${key}:${value}`);
            })
          }
          // console.log(newlocstritems.join(';'))
          rs.Location = newlocstritems.join(';');
        } else {
          pass = false;
          // searchedAssets.forEach(sa => {
          //   Actualcorrelations.forEach(cr => {
          //     if(cr.Security === sa) {
          //       if(!rearrangedcorels.filter(itm => itm.Security === sa).length) {
          //         rearrangedcorels.push(cr)
          //       }
          //     }
          //   })
          // })
          // // if(rs.FeedId === 49303914) {
          // //   console.log(rearrangedcorels)
          // // }
          // if(rearrangedcorels.length > 0) {
          //   Actualcorrelations.forEach(cr => {
          //     let security = cr.Security;
          //     if(!rearrangedcorels.filter(itm => itm.Security === security).length) {
          //       if(SecFilters.indexOf(security) > -1) {
          //         rearrangedcorels.push(cr)
          //       }
          //     }
          //   })
          //   // if(rs.FeedId === 49303914) {
          //   //   console.log(rearrangedcorels)
          //   // }
          //   rs.CorrelationResults = rearrangedcorels;
          //   let locitems = LocationString.split(';');
          //   let newlocstritems = [];
          //   if(locitems.length) {
          //     locitems.forEach((el, i) => {
          //       let key = el.split(':')[0]
          //       let value = el.split(':')[1]
          //       if(key === 'SentimentTotal' ) {
          //         value = 5
          //       } else if(key === 'Sentiment Label') {
          //         value = 'Neutral'
          //       } else if(key === 'Sentiment Score') {
          //         value = 5
          //       } else if(key === 'Sentiment Asset Label') {
          //         value = '';
          //       } else if(key === 'Algolocation') {
          //         value = '';
          //       }
          //       // console.log(`${rs.FeedId}`)
          //       // console.log(`${key}:${value}`)
          //       newlocstritems.push(`${key}:${value}`);
          //     })
          //   }
          //   // console.log(newlocstritems.join(';'))
          //   rs.Location = newlocstritems.join(';');
            
          // } else {
          //   pass = false;
          // }
        }
        
      } else {
        // if(rs.FeedId === 49302424) {
        //   console.log(correlations)
        // }
        let rearrangedcorels = [];
        searchedAssets.forEach(sa => {
          correlations.forEach(cr => {
            if(cr.Security === sa) {
              if(!rearrangedcorels.filter(itm => itm.Security === sa).length) {
                rearrangedcorels.push(cr)
              }
            }
          })
        })
        // if(rs.FeedId === 49302424) {
        //     console.log(rearrangedcorels)
        // }
        if(rearrangedcorels.length > 0) {
          correlations.forEach(cr => {
            let security = cr.Security;
            if(!rearrangedcorels.filter(itm => itm.Security === security).length) {
              if(SecFilters.indexOf(security) > -1) {
                rearrangedcorels.push(cr)
              }
            }
          })
          // if(rs.FeedId === 49302424) {
          //   console.log(rearrangedcorels)
          // }
          rs.CorrelationResults = rearrangedcorels;
          let locitems = LocationString.split(';');
          let newlocstritems = [];
          if(locitems.length) {
            locitems.forEach((el, i) => {
              let key = el.split(':')[0]
              let value = el.split(':')[1]
              if(key === 'SentimentTotal' ) {
                value = 5
              } else if(key === 'Sentiment Label') {
                value = 'Neutral'
              } else if(key === 'Sentiment Score') {
                value = 5
              } else if(key === 'Sentiment Asset Label') {
                value = '';
              } else if(key === 'Algolocation') {
                value = '';
              }
              // console.log(`${rs.FeedId}`)
              // console.log(`${key}:${value}`)
              newlocstritems.push(`${key}:${value}`);
            })
          }
          // console.log(newlocstritems.join(';'))
          rs.Location = newlocstritems.join(';');
        } else {
          pass = false;
        }
      }
    }
    
    return pass
  },
  FormatByAlgolocationFilter: (result) => {
    let newfiltreslt = [];
    result.map((rs, cnt) => {
      // if(rs.FeedId === 49299591) {
      //   console.log(rs)
      // }
      
      if(NotifyFeedsHandler.AlgolocationFilter(rs)) {
        // if(rs.FeedId === 49299591) {
        //   console.log(rs)
        // }
        newfiltreslt.push(rs);
      }
    })
    return newfiltreslt;
  },
  fixeventmissingprops: (result) => {
    let newfiltreslt = [];
    result.map((rs, cnt) => {
      if(typeof rs.CorrelationResults === 'undefined') {
        NotifyFeedsHandler.makecorrelationresult(rs);
      }
      if(typeof rs.RootChangeLogList === 'undefined') {
        NotifyFeedsHandler.makerootchangeloglist(rs);
      } 
      if(typeof rs.Source === 'undefined') {
        rs.Source = '';
      }
      if(typeof rs.RootId !== 'undefined') {
        rs.FeedId = rs.RootId
      }
      rs.SortByDate = rs.LastUpdateTime
      if(rs.CorrelationResults.length > 0) {
        newfiltreslt.push(rs);
      } 
      
    })
    return newfiltreslt;
  },
  makerootchangeloglist: (rs) => {
    let list = []
    
    let obj = {
      RootChangeLogTime:rs.LastUpdateTime,
      UpdateType:"Event Notified",
      UpdateTypeCategory:"Activate"
    }
    list.push(obj)
    rs.RootChangeLogList = list
  },
  makecorrelationresult: (rs) => {
    let Secs = Store.getState().filters.securities
    // if(rs.FeedId === 113524142) {
    //   console.log(Secs)
    // }
    let hasalgolocation = false;
    // let userData = Store.getState().settings.userData;
    let corels = []
    let LocationString = rs.Location
    let algostr = '';
    if(LocationString !== null) {
      let items = LocationString.split(';');
      if(items.length) {
        items.forEach((el, i) => {
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if(key === 'Algolocation' && value !== '' && typeof value !== 'undefined') {
            hasalgolocation = true;
            algostr = value;
          }
        })
      }
    }
    // if(rs.FeedId === 113524142) {
    //   console.log(hasalgolocation)
    //   console.log(algostr)
    // }
    if(hasalgolocation) {
      let assetparts = algostr.split(',');
      // if(rs.FeedId === 113524142) {
      //   console.log(assetparts)
      // }
      if(assetparts.length) {
        assetparts.forEach((assetstr, i) => {
          
          let asset = assetstr.split('-')[0];
          // if(rs.FeedId === 113524142) {
          //   console.log(asset)
          //   console.log(Secs)
          // }
          let assetdata = Secs.filter(itm => itm.Name === asset || itm.Symbol === asset);
          // if(rs.FeedId === 113524142) {
          //   console.log(assetdata)
          // }
          if(assetdata.length) {
            let corelelem = {
              Description: null,
              Impact: 'Direct',
              Link: null,
              SecutiryType: null,
              Security: `${assetdata[0].Name}`,
              Symbol: `${assetdata[0].Symbol}`,
              CustomSymbol: null
            }
            corels.push(corelelem)
          }
          
        })
      }
      

    }
    rs.CorrelationResults = corels;
  },
  AlgolocationFilter: (rs) => {
    let SecFilters = Store.getState().filters.securitiesFilter
    let Secs = Store.getState().filters.securities
    let pass = true;
    let passarr = [];
    let hasalgolocation = false;
    let userData = Store.getState().settings.userData;
    
    // let correlations = rs.CorrelationResults !== null && rs.CorrelationResults.length ? rs.CorrelationResults : []
    // const CorrelationSecs = rs.CorrelationResults !== null && rs.CorrelationResults.length ? rs.CorrelationResults.map((crlItem) => crlItem.Security).filter((itm) => itm) : [];
    let LocationString = rs.Location
    let algostr = '';
    let tmpalgostr = '';
    let tmpalgostrarr = [];
    
    // if(rs.FeedId === 49289572) {
    //   console.log(LocationString)
    // }
    if(LocationString !== null) {
      let items = LocationString.split(';');
      if(items.length) {
        items.forEach((el, i) => {
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if(key === 'Algolocation' && value !== '' && typeof value !== 'undefined') {
            hasalgolocation = true;
            algostr = value;
          }
        })
      }
    }
    // if(rs.FeedId === 49290289) {
    //   console.log(algostr)
    //   console.log(hasalgolocation)
    //   console.log(rs)
    // }
    if(hasalgolocation) {
      let assetparts = algostr.split(',');
      if(assetparts.length) {
        assetparts.forEach((assetstr, i) => {
          
          let asset = assetstr.split('-')[0];
          let assetdata = Secs.filter(itm => itm.Name === asset || itm.Symbol === asset);
          if(assetdata.length) {
            if(SecFilters.indexOf(assetdata[0].Name) === -1) {
             
            } else {
              passarr.push(i)
            }
          }
          
        })
      }
      // console.log(passarr)
      if(!passarr.length) {
        pass = false;
      } else {
        let corelarr = [];
        let userOrderedAssetList = false;
        if(userData.loginlessuser === 'Iforex' || userData.username === 'crowdsenseiforex@gmail.com') {
          userOrderedAssetList = IforexInstrumentorderandsymbol;
        }
        // console.log(userOrderedAssetList);
        if(userOrderedAssetList !== false && Object.keys(userOrderedAssetList).length) {
          let userassets = Object.keys(userOrderedAssetList);
          userassets.forEach(usrasset => {
            let userassetdata = Secs.filter(itm => itm.Name.toLowerCase() === usrasset.toLowerCase() || itm.Symbol.toLowerCase() === usrasset.toLowerCase());
            // console.log(usrasset, userassetdata)
            if(userassetdata.length) {
              passarr.forEach((i, x) => {
                let assetstr = assetparts[i];
                let asset = assetstr.split('-')[0];
                if(userassetdata[0].Name.toLowerCase() === asset.toLowerCase() || userassetdata[0].Symbol.toLowerCase() === asset.toLowerCase()) {
                  let corelelem = {
                    Description: null,
                    Impact: 'Direct',
                    Link: null,
                    SecutiryType: null,
                    Security: `${userassetdata[0].Name}`,
                    Symbol: `${userassetdata[0].Symbol}`,
                    CustomSymbol: `${userOrderedAssetList[usrasset]}`
                  }
                  tmpalgostrarr.push(`${userassetdata[0].Name}-${assetstr.split('-')[1]}`)
                  corelarr.push(corelelem)
                }
              })
            }
          })
        } else {
          passarr.forEach((i, x) => {
            let assetstr = assetparts[i];
            // console.log(assetstr)
            let asset = assetstr.split('-')[0];
            let assetdata = Secs.filter(itm => itm.Name === asset || itm.Symbol === asset);
            if(assetdata.length) {
              let corelelem = {
                Description: null,
                Impact: 'Direct',
                Link: null,
                SecutiryType: null,
                Security: `${assetdata[0].Name}`,
                Symbol: `${assetdata[0].Symbol}`,
                CustomSymbol: null
              }
              tmpalgostrarr.push(`${assetdata[0].Name}-${assetstr.split('-')[1]}`)
              corelarr.push(corelelem)
  
            }
            
          })
        }
        
        // console.log(corelarr, tmpalgostrarr)
        if(corelarr.length > 0) {
          
          let correlations = rs.CorrelationResults !== null && rs.CorrelationResults.length ? rs.CorrelationResults : []
          rs.CorrelationResults = corelarr;
          rs.ActualCorrelationresults = correlations;
        }

        // console.log(LocationString)
        let algoasset = tmpalgostrarr[0].split('-')[0];
        let direction = tmpalgostrarr[0].split('-')[1];
        // if(rs.FeedId === 49280756) {
        //   console.log(algoasset, direction, direction === 'up' ? 'Positive' : direction === 'down' ? 'Negative' : 'Neutral')
        // }
        let locitems = LocationString.split(';');
        let newlocstritems = [];
        if(locitems.length) {
          locitems.forEach((el, i) => {
            let key = el.split(':')[0]
            let value = el.split(':')[1]
            if(key === 'SentimentTotal' ) {
              value = direction === 'up' ? 8 : direction === 'down' ? 2 : 5
            } else if(key === 'Sentiment Label') {
              value = direction === 'up' ? 'Positive' : direction === 'down' ? 'Negative' : 'Neutral'
            } else if(key === 'Sentiment Score') {
              value = direction === 'up' ? 8 : direction === 'down' ? 2 : 5
            } else if(key === 'Sentiment Asset Label') {
              value = algoasset;
            }
            // console.log(`${rs.FeedId}`)
            // console.log(`${key}:${value}`)
            newlocstritems.push(`${key}:${value}`);
          })
        }
        // console.log(newlocstritems.join(';'))
        rs.Location = newlocstritems.join(';');
        // if(rs.FeedId === 49289572) {
        //   console.log(rs.Location, pass)
        // }
      }
    } else {
      let locitems = LocationString.split(';');
        let newlocstritems = [];
        if(locitems.length) {
          locitems.forEach((el, i) => {
            let key = el.split(':')[0]
            let value = el.split(':')[1]
            if(key === 'SentimentTotal' ) {
              value = 5
            } else if(key === 'Sentiment Label') {
              value = 'Neutral'
            } else if(key === 'Sentiment Score') {
              value = 5
            } 
            // else if(key === 'Sentiment Asset Label') {
            //   value = algoasset;
            // }
            // console.log(`${rs.FeedId}`)
            // console.log(`${key}:${value}`)
            newlocstritems.push(`${key}:${value}`);
          })
        }
        // console.log(newlocstritems.join(';'))
        rs.Location = newlocstritems.join(';');


        let userOrderedAssetList = false;
        if(userData.loginlessuser === 'Iforex' || userData.username === 'crowdsenseiforex@gmail.com') {
          userOrderedAssetList = IforexInstrumentorderandsymbol;
        }
        // if(rs.FeedId === 49290289) {
        //   console.log(userOrderedAssetList)
          
        // }
        let correlations = rs.CorrelationResults !== null && rs.CorrelationResults.length ? rs.CorrelationResults : []
        let modifiedcorels = [];
        if(correlations.length) {
          correlations.forEach(cor => {
            cor.CustomSymbol = Object.keys(userOrderedAssetList).length && typeof userOrderedAssetList[cor.Security] !== 'undefined' ? userOrderedAssetList[cor.Security] : null
            modifiedcorels.push(cor)
          })
          if(modifiedcorels.length > 0) {
            rs.CorrelationResults = modifiedcorels;
          }
        }

    }
    
    return pass;
  },
  FormatEventsByPackage: (result) => {
    let rootFeedsList = {};
    let rootFeedsIndexedRef = [];
    if (result.length) {
      let feedIds = result.map((rs) => rs.FeedId);
      let uniqueFeedIds = Array.from(new Set(feedIds));
      // console.log(result , uniqueFeedIds)
      result.map((rs, cnt) => {
        // console.log(rs.EventType)
        if(rs.EventType === 'None') { rs.EventType = 'General Updates' }
        if (uniqueFeedIds.indexOf(rs.FeedId) > -1) {
          // console.log(rs.FeedId === parseInt('36035883') ? NotifyFeedsHandler.EventSecurityFilterPass(rs) : '')
          // console.log('securitypass:', NotifyFeedsHandler.EventSecurityFilterPass(rs));
          // console.log('EventPass:',  NotifyFeedsHandler.EventTypeFilterPass(rs))
          
            
          if (NotifyFeedsHandler.FilterMagnitude(rs)) {
            if (NotifyFeedsHandler.EventSecurityFilterPass(rs)) {
              if (NotifyFeedsHandler.EventTypeFilterPass(rs)) {
                if(NotifyFeedsHandler.NoAlgoLocationInYellowAndAboveEventsFilter(rs)) {
                  if(NotifyFeedsHandler.AlgolocationFilter(rs)) {
                    // console.log(moment.utc(rs.FeedTime).format('DD/MM/YY'))
                    rootFeedsList[`${rs.FeedId}`] = NotifyFeedsHandler.EventTimeToLocal(rs);
                    rootFeedsList[`${rs.FeedId}`].EventReadStatus = true;
                    rootFeedsIndexedRef.push({
                      FeedId: rs.FeedId,
                      SortByDate: rs.SortByDate,
                      notifyEvent: rs.NotifyEvent,
                      magnitudeRate: rs.MagnitudeRate,
                    });
                  }
                }
              }
            }
          }
          uniqueFeedIds.splice(uniqueFeedIds.indexOf(rs.FeedId), 1);
        }
        return null;
      });
    }
    // console.log(rootFeedsList, rootFeedsIndexedRef)
    return { rootFeedsList, rootFeedsIndexedRef };
  },
  NoAlgoLocationInYellowAndAboveEventsFilter: (rs) => {
    let pass = true;
    let EventActive = rs.Active;
    let EventCurated = rs.EventCurated;
    let MagnitudeRate = rs.MagnitudeRate;
    if(MagnitudeRate >= 1) {
      let LocationString = rs.Location
      let algostr = '';
      let hasalgolocation = false;
      if(LocationString !== null) {
        let items = LocationString.split(';');
        if(items.length) {
          items.forEach((el, i) => {
            let key = el.split(':')[0]
            let value = el.split(':')[1]
            if(key === 'Algolocation' && value !== '' && typeof value !== 'undefined') {
              hasalgolocation = true;
              algostr = value;
            }
          })
        }
      }
      if(!hasalgolocation) {
        pass = false;
      }
    }
    return pass;
  },
  FilterMagnitudeSearch: (result, filtObj) => {
    let newfiltreslt = [];
    if (result.length) {
      result.forEach((rs, cnt) => {
        
        let EventActive = rs.Active;
        let EventCurated = rs.EventCurated;
        let MagnitudeRate = rs.MagnitudeRate;
        if (!EventActive && EventCurated && (filtObj.withGray === 'true' || filtObj.withGray === true)) {
          // if(rs.FeedId === 49313295) {
          //   console.log(MagnitudeRate)
          // }
          if(MagnitudeRate === 0 && typeof rs.OldMagnitudeRate !== 'undefined') {
            if(filtObj.withWhite === 'true' || filtObj.withWhite === true) {
              newfiltreslt.push(rs)
            }
          } else {
            newfiltreslt.push(rs)
          }
          
        } else if (!EventActive && !EventCurated && (filtObj.withBlack === 'true' || filtObj.withBlack === true)) {
          // if(rs.FeedId === 49313295) {
          //   console.log(MagnitudeRate)
          // }
          if(MagnitudeRate === 0 && typeof rs.OldMagnitudeRate !== 'undefined') {
            if(filtObj.withWhite === 'true' || filtObj.withWhite === true) {
              newfiltreslt.push(rs)
            }
          } else {
            newfiltreslt.push(rs)
          }
        } else {
          if(MagnitudeRate >= 0) {
            
            switch (MagnitudeRate) {
              case 1:
                if(filtObj.withYellow === 'true' || filtObj.withYellow === true) {
                  newfiltreslt.push(rs)
                }
                break;
              case 2:
                if(filtObj.withOrange === 'true' || filtObj.withOrange === true) {
                  newfiltreslt.push(rs)
                }
                break;
              case 3:
                if(filtObj.withRed === 'true' || filtObj.withRed === true) {
                  newfiltreslt.push(rs)
                }
                break;
              default:
                // if(rs.FeedId === 49313295) {
                //   console.log(MagnitudeRate, filtObj.withWhite)
                // }
                if(filtObj.withWhite === 'true' || filtObj.withWhite === true) {
                  newfiltreslt.push(rs)
                }
                break;
            }
          }
          
        }
      })
        
    }
    return newfiltreslt;
  },
  FilterMagnitude: (story) => {
    let filtMagStat = true;
    let magnitudeFilters = Store.getState().app_preferences.preferences.event_importance_filters;
    // console.log(magnitudeFilters)
    // console.log('show gray: ', magnitudeFilters.Gray_Colored_Events.Show_Gray)
    let EventActive = story.Active;
    let EventCurated = story.EventCurated;
    let MagnitudeRate = story.MagnitudeRate;
    let EventMagnitudeCategory = '';
    if (!EventActive && EventCurated) {
      EventMagnitudeCategory = 'gray';
    } else if (!EventActive && !EventCurated) {
      EventMagnitudeCategory = 'black';
    } else {
      switch (MagnitudeRate) {
        case 1:
          EventMagnitudeCategory = 'medium';
          break;
        case 2:
          EventMagnitudeCategory = 'high';
          break;
        case 3:
          EventMagnitudeCategory = 'higher';
          break;
        default:
          EventMagnitudeCategory = 'low';
          break;
      }
    }
    if (EventMagnitudeCategory === 'high') {
      if (!magnitudeFilters.Yellow_Colored_Events.Show_Yellow) {
        filtMagStat = false;
      }
    } else if (EventMagnitudeCategory === 'higher') {
      if (!magnitudeFilters.Red_Colored_Events.Show_Red) {
        filtMagStat = false;
      }
    } else if ( EventMagnitudeCategory === 'medium') {
      if (!magnitudeFilters.Gray_Colored_Events.Show_Gray) {
        filtMagStat = false;
      }
    } else if(EventMagnitudeCategory === 'low') {
      if (!magnitudeFilters.White_Colored_Events.Show_White) {
        filtMagStat = false;
      }
    }
    // console.log('magnitude: ', EventMagnitudeCategory)
    // console.log('filter status: ', filtMagStat)
    return filtMagStat;
  },
};

export class EventsUpdateHandler {
  constructor(options = {}) {
    this.InitialFeedRequest = options.InitialFeedRequest || '';
    this.LastFeedRequest = options.LastFeedRequest || '';
    this.LastFeedRequestEnd = options.LastFeedRequestEnd || '';
    this.InitialFeedResponse = options.InitialFeedResponse || '';
    this.LastFeedResponse = options.LastFeedResponse || '';
    this.RequestState = options.RequestState || false;
    this.utcOffset = 0;
  }

  getReqData = () => {
    return {
      RequestState: this.RequestState,
      InitialFeedResponse: this.InitialFeedResponse,
      LastFeedResponse: this.LastFeedResponse,
      LastFeedRequest: this.LastFeedRequest,
      LastFeedRequestEnd: this.LastFeedRequestEnd,
    };
  };

  setReqData = (options) => {
    this.InitialFeedRequest = options.InitialFeedRequest || '';
    this.LastFeedRequest = options.LastFeedRequest || '';
    this.LastFeedRequestEnd = options.LastFeedRequestEnd || '';
    this.InitialFeedResponse = options.InitialFeedResponse || '';
    this.LastFeedResponse = options.LastFeedResponse || '';
    this.RequestState = options.RequestState || false;
  };

  StoryReqData = (current, next) => {
    // console.log(type, saveid);
    // console.log("We entered StoryReqData")
    let oldStoriesIndex = [];
    let newStoriesIndex = [];
    oldStoriesIndex = current.StoriesIndex.map((index) => {
      return index.FeedId;
    });
    newStoriesIndex = next.StoriesIndex.map((index) => {
      return index.FeedId;
    });

    if (!oldStoriesIndex.length && newStoriesIndex.length) {
      this.RequestState = false;
      this.InitialFeedResponse = moment().utcOffset(this.utcOffset).format();
      this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
      this.LastFeedRequest = moment().utcOffset(this.utcOffset).format();
      this.LastFeedRequestEnd = moment().utcOffset(this.utcOffset).format();
      return {
        reqData: {
          RequestState: this.RequestState,
          InitialFeedRequest: this.InitialFeedRequest,
          InitialFeedResponse: this.InitialFeedResponse,
          LastFeedResponse: this.LastFeedResponse,
          LastFeedRequest: this.LastFeedRequest,
          LastFeedRequestEnd: this.LastFeedRequestEnd,
        },
        useCase: 1,
      };
    } else {
      if (oldStoriesIndex.length && newStoriesIndex.length) {
        this.RequestState = false;
        this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
        if (!next.req_error.rootFeeds) {
          this.LastFeedRequest = this.LastFeedRequestEnd;
        }
        return {
          reqData: {
            RequestState: this.RequestState,
            InitialFeedRequest: this.InitialFeedRequest,
            InitialFeedResponse: this.InitialFeedResponse,
            LastFeedResponse: this.LastFeedResponse,
            LastFeedRequest: this.LastFeedRequest,
            LastFeedRequestEnd: this.LastFeedRequestEnd,
          },
          useCase: 2,
        };
      } else if (oldStoriesIndex.length && !newStoriesIndex.length) {
        this.RequestState = false;
        this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();

        return {
          reqData: {
            RequestState: this.RequestState,
            InitialFeedRequest: this.InitialFeedRequest,
            InitialFeedResponse: this.InitialFeedResponse,
            LastFeedResponse: this.LastFeedResponse,
            LastFeedRequest: this.LastFeedRequest,
            LastFeedRequestEnd: this.LastFeedRequestEnd,
          },
          useCase: 4,
        };
      } else if (!oldStoriesIndex.length && !newStoriesIndex.length) {
        // console.log(current.systemTime !== next.systemTime);
        // console.log(current.userData !== next.userData);
        // console.log(current.StoriesIndex !== next.StoriesIndex);
        // console.log(current.settings !== next.settings);
        if (current.StoriesIndex === next.StoriesIndex) {
          // console.log(current, next)
          // FeedReqData.StoryRequestState = true
          return {
            reqData: {
              RequestState: this.RequestState,
              InitialFeedRequest: this.InitialFeedRequest,
              InitialFeedResponse: this.InitialFeedResponse,
              LastFeedResponse: this.LastFeedResponse,
              LastFeedRequest: this.LastFeedRequest,
              LastFeedRequestEnd: this.LastFeedRequestEnd,
            },
            useCase: 3,
          };
        } else {
          this.RequestState = false;
          // console.log("some other props changed no need to update new reqdata unless saved search")
          this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
          return {
            reqData: {
              RequestState: this.RequestState,
              InitialFeedRequest: this.InitialFeedRequest,
              InitialFeedResponse: this.InitialFeedResponse,
              LastFeedResponse: this.LastFeedResponse,
              LastFeedRequest: this.LastFeedRequest,
              LastFeedRequestEnd: this.LastFeedRequestEnd,
            },
            useCase: 3,
          };
        }
      }
    }
  };

  UpdateReqData = (current, next, error) => {
    let oldStoriesIndex = [];
    let newStoriesIndex = [];
    oldStoriesIndex = current.length ? current.map((index) => {
      return index.FeedId;
    }) : ([]);
    newStoriesIndex = next.length ? next.map((index) => {
      return index.FeedId;
    }) : ([]);

    if (!oldStoriesIndex.length && newStoriesIndex.length) {
      this.RequestState = false;
      this.InitialFeedResponse = moment().utcOffset(this.utcOffset).format();
      this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
      this.LastFeedRequest = moment().utcOffset(this.utcOffset).format();
      this.LastFeedRequestEnd = moment().utcOffset(this.utcOffset).format();
      return {
        reqData: {
          RequestState: this.RequestState,
          InitialFeedRequest: this.InitialFeedRequest,
          InitialFeedResponse: this.InitialFeedResponse,
          LastFeedResponse: this.LastFeedResponse,
          LastFeedRequest: this.LastFeedRequest,
          LastFeedRequestEnd: this.LastFeedRequestEnd,
        },
        useCase: 1,
      };
    } else {
      if (oldStoriesIndex.length && newStoriesIndex.length) {
        this.RequestState = false;
        this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
        // console.log(error, this.LastFeedRequestEnd)
        if (!error) {
          // console.log(error, this.LastFeedRequestEnd)
          this.LastFeedRequest = this.LastFeedRequestEnd;
        }
        return {
          reqData: {
            RequestState: this.RequestState,
            InitialFeedRequest: this.InitialFeedRequest,
            InitialFeedResponse: this.InitialFeedResponse,
            LastFeedResponse: this.LastFeedResponse,
            LastFeedRequest: this.LastFeedRequest,
            LastFeedRequestEnd: this.LastFeedRequestEnd,
          },
          useCase: 2,
        };
      } else if (!oldStoriesIndex.length && !newStoriesIndex.length) {
        this.RequestState = false;
        this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
        if (!error) {
          // console.log('LastFeedRequestTime:', this.LastFeedRequestEnd )
          this.LastFeedRequest = this.LastFeedRequestEnd;
        }
        return {
          reqData: {
            RequestState: this.RequestState,
            InitialFeedRequest: this.InitialFeedRequest,
            InitialFeedResponse: this.InitialFeedResponse,
            LastFeedResponse: this.LastFeedResponse,
            LastFeedRequest: this.LastFeedRequest,
            LastFeedRequestEnd: this.LastFeedRequestEnd,
          },
          useCase: 3,
        };
      } else if (oldStoriesIndex.length && !newStoriesIndex.length) {
        this.RequestState = false;
        this.LastFeedResponse = moment().utcOffset(this.utcOffset).format();
        if (!error) {
          this.LastFeedRequest = this.LastFeedRequestEnd;
        }
        return {
          reqData: {
            RequestState: this.RequestState,
            InitialFeedRequest: this.InitialFeedRequest,
            InitialFeedResponse: this.InitialFeedResponse,
            LastFeedResponse: this.LastFeedResponse,
            LastFeedRequest: this.LastFeedRequest,
            LastFeedRequestEnd: this.LastFeedRequestEnd,
          },
          useCase: 4,
        };
      }
    }
  };

  getDesktopWebBeepNotificationPreferences = (preferences) => {
    let webBeep = [];
    let DesktopNotifications = Object.keys(preferences.event_importance_filters).map((scat) => {
      switch (scat) {
        case 'Red_Colored_Events':
          if (preferences.event_importance_filters[scat].Speech_Notifications_Red) {
            webBeep.push(scat);
          }
          // if(preferences.event_importance_filters[scat].Desktop_Notifications_Red) {
          //   if(preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Red) {
          //     return ('High_BreakingOnly');
          //   } else {
          //     return ('High');
          //   }
          // }
          if (
            preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Red &&
            preferences.event_importance_filters[scat].Desktop_Notifications_Red
          ) {
            return 'High';
          } else if (preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Red) {
            return 'High_BreakingOnly';
          }
          break;
        case 'Yellow_Colored_Events':
          if (preferences.event_importance_filters[scat].Speech_Notifications_Yellow) {
            webBeep.push(scat);
          }
          // if(preferences.event_importance_filters[scat].Desktop_Notifications_Yellow) {
          //   if(preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Yellow) {
          //     return ('Medium_BreakingOnly');
          //   } else {
          //     return ('Medium');
          //   }
          // }
          if (
            preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Yellow &&
            preferences.event_importance_filters[scat].Desktop_Notifications_Yellow
          ) {
            return 'Medium';
          } else if (preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Yellow) {
            return 'Medium_BreakingOnly';
          }
          break;
        case 'Gray_Colored_Events':
          if (preferences.event_importance_filters[scat].Speech_Notifications_Gray) {
            webBeep.push(scat);
          }
          // if(preferences.event_importance_filters[scat].Desktop_Notifications_Gray) {
          //   if(preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Gray) {
          //     return ('Low_BreakingOnly');
          //   } else {
          //     return ('Low');
          //   }
          // }
          if (
            preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Gray &&
            preferences.event_importance_filters[scat].Desktop_Notifications_Gray
          ) {
            return 'Low';
          } else if (preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Gray) {
            return 'Low_BreakingOnly';
          }
          break;
        case 'Non_Active_Curated_Events':
          // if(preferences.event_importance_filters[scat].Desktop_Notifications_Gray) {
          //   if(preferences.event_importance_filters[scat].Desktop_Notify_Breaking_Only_Gray) {
          //     return ('Low_BreakingOnly');
          //   } else {
          //     return ('Low');
          //   }
          // }
          // return ('Curated')
          break;
        default:
          break;
      }

      return null;
    });
    let newDN = DesktopNotifications.filter((itm) => itm);
    return {
      webBeep,
      DesktopNotifications: newDN,
    };
  };

  getCanNotify = (inc, webBeep, DesktopNotifications, systemTime) => {
    let canNotifyDesktop = true;
    let canbeep = [];
    switch (inc.MagnitudeRate) {
      case 0:
        if (webBeep.indexOf('Gray_Colored_Events') === -1) {
          canbeep.push(0);
        } else {
          canbeep.push(1);
        }

        if (DesktopNotifications.indexOf('Low') === -1) {
          if (DesktopNotifications.indexOf('Low_BreakingOnly') > -1) {
            if (!inc.NotifyEvent) {
              canNotifyDesktop = false;
            }
          } else {
            canNotifyDesktop = false;
          }
        }
        break;
      case 1:
        if (webBeep.indexOf('Yellow_Colored_Events') === -1) {
          canbeep.push(0);
        } else {
          canbeep.push(1);
        }

        if (DesktopNotifications.indexOf('Medium') === -1) {
          if (DesktopNotifications.indexOf('Medium_BreakingOnly') > -1) {
            if (!inc.NotifyEvent) {
              canNotifyDesktop = false;
            }
          } else {
            canNotifyDesktop = false;
          }
        }
        break;
      case 2:
        if (webBeep.indexOf('Red_Colored_Events') === -1) {
          canbeep.push(0);
        } else {
          canbeep.push(1);
        }

        if (DesktopNotifications.indexOf('High') === -1) {
          if (DesktopNotifications.indexOf('High_BreakingOnly') > -1) {
            if (!inc.NotifyEvent) {
              canNotifyDesktop = false;
            }
          } else {
            canNotifyDesktop = false;
          }
        }
        break;
      default:
        break;
    }
    if (!inc.Active) {
      canNotifyDesktop = false;
    }
    if (systemTime.TimeToNextState !== -1) {
      if (!systemTime.IsOpen && (systemTime.IsSpecialClose || systemTime.IsWeekend)) {
        canNotifyDesktop = false;
      }
    } else {
      if (systemTime.IsSpecialClose) {
        canNotifyDesktop = false;
      }
    }

    return {
      canbeep,
      canNotifyDesktop,
    };
  };

  ifCanNotify = (inc, eachFeed, notifBodyData) => {
    let LastAttributesUpdateTime = moment(inc.LastAttributesUpdateTime).subtract(1, 'minute');
    let LastEventTypeUpdateTime = moment(inc.LastEventTypeUpdateTime).subtract(1, 'minute');
    let LastLocationUpdateTime = moment(inc.LastLocationUpdateTime).subtract(1, 'minute');

    if (
      moment(eachFeed.LastAttributesUpdateTime) < LastAttributesUpdateTime ||
      moment(eachFeed.LastEventTypeUpdateTime) < LastEventTypeUpdateTime ||
      moment(eachFeed.LastLocationUpdateTime) < LastLocationUpdateTime
    ) {
      console.log(Date(Date.now()).toString() + ': Notification Approved, FeedId: ' + inc.FeedId.toString());
      console.log('LastAttributesUpdateTime eachFeed: ' + eachFeed.LastAttributesUpdateTime);
      console.log('LastAttributesUpdateTime inc: ' + LastAttributesUpdateTime.toString());
      console.log('LastEventTypeUpdateTime eachFeed: ' + eachFeed.LastEventTypeUpdateTime);
      console.log('LastEventTypeUpdateTime inc: ' + LastEventTypeUpdateTime.toString());
      console.log('LastLocationUpdateTime eachFeed: ' + eachFeed.LastLocationUpdateTime);
      console.log('LastLocationUpdateTime inc: ' + LastLocationUpdateTime.toString());
      // let RootChangeLogList = inc.RootChangeLogList;
      // let alertType = '';
      // if(RootChangeLogList.length){

      //   switch(RootChangeLogList[RootChangeLogList.length - 1].UpdateTypeCategory) {
      //     case 'Activate':
      //       alertType = 'Event Alert';
      //       break;
      //     case 'Detected':
      //       alertType = 'Event Detected';
      //       break;
      //     case 'Details Level':
      //       alertType = 'Integrity Updated';
      //       break;
      //     case 'Event Type':
      //       alertType = 'Event Type Updated';
      //       break;
      //     case 'Attributes':
      //       alertType = 'Topics Updated';
      //       break;
      //     case 'Location':
      //       alertType = 'Location Updated';
      //       break;
      //     case 'Magnitude':
      //       alertType = 'Magnitude Updated';
      //       break;
      //     default:
      //       alertType = 'Major Update';
      //       break;
      //   }
      // }
      // notifBodyData.push(`${alertType} in ${inc.Location} ${inc.MentionTypes ? '('+inc.MentionTypes+')' : ''}$$${inc.FeedId.toString()}`)
      notifBodyData.push(`${inc.HeadlineText}$$${inc.FeedId.toString()}`);
      // actualRootFeeds.push(inc)
    } else {
      console.log(Date(Date.now()).toString() + ': Notification Denined, FeedId: ' + inc.FeedId.toString());
    }
    return notifBodyData;
  };

  addToNotif = (notifBodyData, notif) => {
    if (notifBodyData.length) {
      let actualBD = [];
      let evtId = [];
      notifBodyData.forEach((notBD) => {
        evtId.push(notBD.split('$$')[1]);
        actualBD.push(notBD.split('$$')[0]);
      });
      notif.body += actualBD.join(',') + ' .';
      notif.has = true;
      notif.eventId = evtId.join(',');
    }
    return notif;
  };
}
