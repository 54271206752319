import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import HeadBar from '../shared/headbar/headbar';
import Login from '../../screens/login';
import Modals from '../shared/modal/modals';
import { connect } from 'react-redux';
import * as settingsActionCreators from '../../actions/settings.action';

class Authenticate extends Component {
  
  constructor() {
    super();
    this.state = {
      page: '',
      modalShow :false,
      modalContent: '',
      modalData : {}
    }
  }
  
  componentWillMount() {
    let sessionUserLogout = window.sessionStorage.getItem('userLogout');
    let sessionUserData = JSON.parse(window.sessionStorage.getItem('logoutData'));
    if(sessionUserLogout && sessionUserData) { 
      window.Mixpanel.actions.identify(sessionUserData.UserName);
      window.Mixpanel.actions.track('Successfull logout', sessionUserData).then(data => {
        window.Mixpanel.actions.resetProp();
        window.sessionStorage.removeItem('userLogout');
        window.sessionStorage.removeItem('logoutData');
      })
      
    }
    
    this.setState({
      page: this.props.page,
      modalShow: this.props.modalShow,
      modalContent: this.props.modalContent 
    })
  }

  componentDidMount() {
    if(this.props.maintenancemodeon) {
      this.showmodal('UnderMaintenance');
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      page: nextProps.page,
      modalShow: nextProps.modalShow,
      modalContent: nextProps.modalContent 
    })
  }

  showmodal = (content = '', data = {}) => {
    this.setState({
      modalShow: true,
      modalContent: content,
      modalData: data
    })
  }

  modalClose = () => {
    this.setState({ 
      ...this.state, 
      modalShow: false 
    })
  }
  
  render() {
    const { page, modalShow, modalContent, modalData } = this.state;
    
    let renderedView ;
    if(page === 'login') {
      renderedView = <Login {...this.props} showmodal={this.showmodal} />
    }
    return(
      <Row className="justify-content-center text-center h100vh bg-body">
        <Col lg={4} sm={6} xs={12} md={6} xl={4} className=" login-top-margin"  >
          <div className="auth-wrap">
          
            <HeadBar page={this.state.page} {...this.props} />
            { renderedView }
          
          </div>
        </Col>
        <Modals signature={`Auth`} show={modalShow} onHide={this.modalClose} view={modalContent} modalData={modalData} {...this.props} />
      </Row>
    )
  }

}

const mapStateToApp = (state) => {
  return {

    maintenancemodeon: state.settings.maintenancemodeon
  };
};

const mapDispatchToProps = {
  ...settingsActionCreators
};

export default connect(mapStateToApp, mapDispatchToProps)(Authenticate);