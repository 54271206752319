export const IforexInstrumentId = {
    'Gold': 13103,
    'USDJPY': 12055,
    'Bitcoin': 35631,
    'EURUSD': 3631,
    'Crude Oil WTI': 24623,
    'Natural Gas': 27951,
    'US 100': 25391,
    'US 30': 21807,
    'US 500': 25135,
    'GBPUSD': 4143,
    'GBPJPY': 4119,
    'EURJPY': 3607,
    'JP 225': 3607,
    'Ethereum': 12335,
    'Silver': 12847,
    'AUDUSD': 303,
    'Nifty 50': 3119,
    'DAX': 26894,
    'Solana': 62515,
    'AUDJPY': 279,
    'DogeCoin': 63025,
    'Shiba INU': 7731,
    'USDCAD': 12035,
    'USDCHF': 12036,
    'Bitcoin Cash': 56623,
    'Internet Computer': 24112,
    'NZDUSD': 7727,
    'USDMXN': 12056,
    'Tesla': 60463,
    'Nvidia': 55600,
    'Apple': 32559,
    'Microsoft': 33583,
}
export const IforexInstrumentorderandsymbol = {
    'Gold': 'Gold',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'WTI Oil',
    'Natural Gas': 'Natural Gas',
    'US 100': 'Nasdaq 100',
    'US 30': 'Dow Jones 30',
    'US 500': 'S&P 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Nikkei 225',
    'Ethereum': 'Ethereum',
    'Silver': 'Silver',
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'India Nifty 50',
    'DAX': 'DAX 40',
    'Solana': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
}