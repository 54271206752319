import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging";

const config = {
    apiKey: "AIzaSyAyrHlK_d_X1034cunI_f3Q-sUBIJWxV5M",
    authDomain: "chromenotificationcs.firebaseapp.com",
    projectId: "chromenotificationcs",
    storageBucket: "chromenotificationcs.appspot.com",
    messagingSenderId: "639007629309",
    appId: "1:639007629309:web:c027f0a4a0bcf8f4a44394",
    measurementId: "G-3YWCV1Q2LM"
}

const fbApp = initializeApp(config);


export default fbApp;