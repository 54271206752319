import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './appLoaderSpinner.css';
import { APPPATH } from '../../../../modules/helper.module';
import { connect } from 'react-redux';

class SpinnerLoader extends Component {
    constructor(props) {
      super();
      this.state = {
        //   show: true
      }
      this.close = '';
    }
    componentDidMount() {
        this.close = setTimeout(() => {
        //     window.location.href = APPPATH.AppUrl;
        this.props.modalClose();
        },31000)
    }

    componentWillUnmount() {
        clearTimeout(this.close);
    }
    

    render() {
        return (
            <div className={`text-center`}>
                <div className={`lds-ring ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

}

// const SpinnerLoader = () => {
//     return (
//         <div className='text-center'>
//             <div className="lds-ring">
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//             </div>
//         </div>
//     );
//   };


const mapStateToProps = (state, ownProps) => {
    return {
      Theme: state.app_preferences.preferences.user_interface.Theme,
    };
};
  
const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerLoader);