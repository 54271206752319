import { Component } from 'react';
import  { connect } from 'react-redux';
import * as tmActionCreators from '../../actions/trendingmentions.action';
import { UpdateInitialColumnLoadCounter } from '../../actions/settings.action';
import { EventsUpdateHandler,TrendingMentionsHelper } from '../../modules/helper.module';
import * as moment from 'moment';

class TMDetailsColumnContextHandle extends Component {
    constructor(props) {
        super();
        this.state = {
            // TickerViewDetails: props.TickerViewDetails,
            TickerData: props.TickerData,
            TickerEventIndex: props.TickerEventIndex,
            TickerEventIndexUpdated: props.TickerEventIndexUpdated,
            TickerSearchData: {},
            tmDetailEventsReqData: {
                InitialFeedRequest: '',
                LastFeedRequest: '',
                LastFeedRequestEnd: '',
                InitialFeedResponse: '',
                LastFeedResponse: '',
                RequestState:false
            },
            TickerResearch: props.TickerResearch,
            thisInstance: this,
            updateUseCase: 0,
            utcOffset: 0,
            postponeupdate: props.postponeupdate
        }
        this.tmDetailsFeedUpdateHandle = {};
        this.tmDetailsFeedUpdateInterval = {};
        this.tmDetailsFeedRequestUpdateInterval = {};
        // console.log(props.SecurityName, props.SecurityTicker)
        props.loadtickerdata(props.SecurityName, props.SecurityTicker, props.sourceFilters, props.columnindex);
        // this.loadTickerEvents();
        // console.log('here', props)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps.TickerEventIndex)
        if(prevState.thisInstance.props.postponeupdate !== nextProps.postponeupdate) {
            return { postponeupdate: nextProps.postponeupdate };
        }
        if(JSON.stringify(prevState.thisInstance.props.TickerData) !== JSON.stringify(nextProps.TickerData)) {
            return {
                TickerData: nextProps.TickerData
            }
        } 
        else if(prevState.thisInstance.props.TickerEventIndexUpdated !== nextProps.TickerEventIndexUpdated) {
            let tmFeedReqData = prevState.tmDetailEventsReqData
            if(prevState.thisInstance.tmDetailsFeedUpdateHandle.UpdateReqData) {
                let error = nextProps.req_error.rootFeeds;
                tmFeedReqData = prevState.thisInstance.tmDetailsFeedUpdateHandle.UpdateReqData(prevState.TickerEventIndex, nextProps.TickerEventIndex, error);
            }
            return {
                TickerEventIndex: nextProps.TickerEventIndex,
                TickerEventIndexUpdated: nextProps.TickerEventIndexUpdated,
                tmDetailEventsReqData: tmFeedReqData.reqData ? tmFeedReqData.reqData : ({
                    InitialFeedRequest: moment().utcOffset(prevState.utcOffset).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
                    LastFeedRequest: moment().utcOffset(prevState.utcOffset).format('YYYY-MM-DD HH:mm:ss'),
                    LastFeedRequestEnd: moment().utcOffset(prevState.utcOffset).format('YYYY-MM-DD HH:mm:ss'),
                    InitialFeedResponse: moment().utcOffset(prevState.utcOffset).format('YYYY-MM-DD HH:mm:ss'),
                    LastFeedResponse: '',
                    RequestState:false
                }),
                updateUseCase: tmFeedReqData.useCase ? tmFeedReqData.useCase : 1
            }
        }
        
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('componentdid update')
        // console.log(prevState.TickerEventIndexUpdated,this.state.TickerEventIndexUpdated)
        // console.log(prevState.TickerEventIndex === this.state.TickerEventIndex)
        // console.log("Component did update")
        if(!Object.keys(prevState.TickerData).length && Object.keys(this.state.TickerData).length || (this.props.TickerResearch !== prevProps.TickerResearch && this.props.TickerResearch === true)) {
            // console.log('componentdid update-- load events initiated')
            clearTimeout(this.tmDetailsFeedUpdateInterval)
            this.loadTickerEvents()
        } else if(prevProps.TickerEventIndexUpdated !== this.props.TickerEventIndexUpdated && !this.state.postponeupdate) {
            // console.log('ticker event indexupdated')
            // clearInterval(this.tmDetailsFeedRequestUpdateInterval)
            // this.tmDetailsFeedRequestUpdateInterval = setInterval(() => {
                // console.log('componentdid update-- update events initiated', this.state.updateUseCase)
                this.updateTickerEventsRequest(this.state.updateUseCase)
            // }, 20000)
            
        } else {
            if(prevState.postponeupdate !== this.state.postponeupdate && !this.state.postponeupdate) {
                this.updateTickerEventsRequest(this.state.updateUseCase)
            } else if(prevState.postponeupdate !== this.state.postponeupdate && this.state.postponeupdate) {
                clearTimeout(this.tmDetailsFeedUpdateInterval)
            }
        }
        // console.log(this.props.TickerViewDetails, prevProps.TickerViewDetails)
        // console.log(this.state.TickerViewDetails, prevState.TickerViewDetails)

        // if((this.props.TickerViewDetails && this.props.TickerViewDetails !== prevProps.TickerViewDetails) || 
        // (this.props.TickerViewDetails && this.props.TickerData.SecurityTicker !== prevProps.TickerData.SecurityTicker) || 
        // (this.props.TickerResearch !== prevProps.TickerResearch && this.props.TickerResearch === true)) {
        //     // console.log('load ticker events')
            // clearTimeout(this.tmDetailsFeedUpdateInterval)
            // this.loadTickerEvents()
        // } else if(this.state.TickerEventIndex !== prevState.TickerEventIndex) {
        //     // console.log('ticker events updated')
        //     this.updateTickerEventsRequest(this.state.updateUseCase)
        // }

        // if(this.props.TickerData !== prevProps.TickerData && !prevProps.TickerData) {
        //     clearTimeout(this.tmDetailsFeedUpdateInterval)
        //     console.log('didupdate')
        //     this.loadTickerEvents()
        // } else if(this.state.TickerEventIndex !== prevState.TickerEventIndex) {
        //     this.updateTickerEventsRequest(this.state.updateUseCase)
        // }
    }

    updateTickerEventsRequest = (useCase) => {
        this.props.setTickerDetailsFeedReqDataCoinColumn(this.state.tmDetailEventsReqData, this.props.columnindex, this.props.SecurityName, this.props.sourceFilters);
        // let tkIndex = this.props.TickerEventIndex.map(index => { return index.FeedId })
        clearTimeout(this.tmDetailsFeedUpdateInterval)
        // console.log(useCase)
        switch(useCase) {
            case 1:
                // this.props.UpdateInitialColumnLoadCounter() 
                this.tmDetailsFeedUpdateInterval = setTimeout(() => {
                    
                    this.updateTickerEvents(this.tmDetailsFeedUpdateHandle.LastFeedRequest);
                    clearTimeout(this.tmDetailsFeedUpdateInterval)
                    
                },30000)

                break;
            case 4:
                clearTimeout(this.tmDetailsFeedUpdateInterval);
                this.loadTickerEvents(this.state.TickerSearchData);
                break;
            case 3:
                clearTimeout(this.tmDetailsFeedUpdateInterval);
                // this.loadTickerEvents(this.state.TickerSearchData);
                break;
            default:
                this.tmDetailsFeedUpdateInterval = setTimeout(() => {
                    this.updateTickerEvents(this.tmDetailsFeedUpdateHandle.LastFeedRequest);
                    clearTimeout(this.tmDetailsFeedUpdateInterval)
                },30000)
                break;
        }
    }

    loadTickerEvents = () => {
        
            // console.log('from coindetailcolumn - loading')
            let searchData = {
                sec : [`${this.state.TickerData.SecurityName}`],
                startDate : moment().utcOffset(this.state.utcOffset).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
                endDate : moment().utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss'),
                withCurated : this.props.Search_with_gray,
                withBlack : this.props.Search_with_black,
                withWhite : this.props.Search_with_white ,
                withYellow : this.props.Search_with_yellow,
                withOrange : this.props.Search_with_orange,
                withRed : this.props.Search_with_red,
                sourceFilters: this.props.sourceFilters
            }
            
            let TickerFeedReqData = {
                RequestState: true, 
                InitialFeedRequest: searchData.startDate, 
                LastFeedRequest: searchData.endDate,
                LastFeedRequestEnd: searchData.endDate,
                InitialFeedResponse: searchData.endDate,
                LastFeedResponse: '', 
            }
            this.tmDetailsFeedUpdateHandle = new EventsUpdateHandler(TickerFeedReqData);
            this.props.setTickerDetailsFeedReqDataCoinColumn(TickerFeedReqData, this.props.columnindex, this.props.SecurityName, this.props.sourceFilters);
            
            this.setState({
                ...this.state,
                TickerSearchData: searchData,
                tmDetailEventsReqData: TickerFeedReqData
            },() => {
                this.props.searchCoinDetailTickerEvents(searchData, this.props.columnindex, this.props.SecurityName);
            })
        
        

    }

    updateTickerEvents = (LastFeedRequest) => {
        let startDate = moment().utcOffset(this.state.utcOffset).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss');
        // let startDate = moment(LastFeedRequest).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');
        let nextStartDate = moment().utcOffset(this.state.utcOffset).format();
        let endDate = moment(nextStartDate).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');
        let { TickerSearchData } = this.state
        TickerSearchData.sec = [`${this.props.SecurityName}`]
        TickerSearchData.startDate = startDate
        TickerSearchData.endDate = endDate
        TickerSearchData.withCurated = this.props.Search_with_gray
        TickerSearchData.withBlack = this.props.Search_with_black
        TickerSearchData.withWhite = this.props.Search_with_white
        TickerSearchData.withYellow = this.props.Search_with_yellow
        TickerSearchData.withOrange = this.props.Search_with_orange
        TickerSearchData.withRed = this.props.Search_with_red
        TickerSearchData.sourceFilters= this.props.sourceFilters
        this.setState({
            ...this.state,
            tmDetailEventsReqData: {
                ...this.state.tmDetailEventsReqData,
                RequestState: true,
                LastFeedRequestEnd: nextStartDate,
                LastFeedRequest: LastFeedRequest
            }
        },() => {
            // console.log('from coindetailcolumn - updating')
            this.tmDetailsFeedUpdateHandle.RequestState = true;
            this.tmDetailsFeedUpdateHandle.LastFeedRequest = LastFeedRequest;
            this.tmDetailsFeedUpdateHandle.LastFeedRequestEnd = nextStartDate;
            this.props.setTickerDetailsFeedReqDataCoinColumn(this.state.tmDetailEventsReqData, this.props.columnindex, this.props.SecurityName, this.props.sourceFilters);
            this.props.searchCoinDetailTickerEvents(TickerSearchData, this.props.columnindex, this.props.SecurityName);
            this.props.loadtickerdata(this.props.SecurityName, this.props.SecurityTicker, this.props.sourceFilters, this.props.columnindex, true);
            // this.props.TMViewDetails(this.props.TickerData, true)
        })
        
    }

    componentWillUnmount() {
        clearTimeout(this.tmDetailsFeedUpdateInterval);
      }

    render() {
        // console.log(this.state.tmIndex);
        return ('')
    }

}


const mapStateToComponent = (state, ownProps) => {
    // console.log(`${ownProps.SecurityName}${ownProps.sourceFilters}`, state.trendingMentions.tmDetailPreferencesDataStore)
    return {
        postponeupdate: state.settings.postponeupdate,
        // TickerViewDetails: state.trendingMentions.tmDetails.TickerViewDetails,
        TickerData: typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`] !== 'undefined' ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`].TickerData : {},
        TickerResearch: typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`] !== 'undefined' ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`].TickerResearch : false,
        // securities: state.filters.securities,
        SecurityName: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].SecurityName,
        // SecurityTicker: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].SecurityTicker,
        // Search_with_gray: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].CDSearch_with_gray,
        // Search_with_black: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].CDSearch_with_black,
        // Search_with_yellow: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].CDSearch_with_yellow,
        // Search_with_orange: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].CDSearch_with_orange,
        // Search_with_red: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].CDSearch_with_red,
        TickerEventIndex: typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`] !== 'undefined' ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`].TickerEventIndex : [] ,
        TickerEventIndexUpdated: typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`] !== 'undefined' ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.sourceFilters}`].TickerEventIndexUpdated : 0 ,
        // TickerResearch: state.trendingMentions.tmDetailPreferences[ownProps.columnindex].TickerResearch,
        req_error: state.error.rootFeeds
    }
}
const mapDispatchToProps = {
    ...tmActionCreators,
    UpdateInitialColumnLoadCounter
}

export default connect(mapStateToComponent, mapDispatchToProps, null, {forwardRef : true})(TMDetailsColumnContextHandle)