import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../modules/helper.module';
// import PremiumButton from '../headbar/PremiumButton';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class MobileAppBanner extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      clientWidth:  document.documentElement.clientWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  closeBanner = () => {
    // this.setState({ ...this.state, show: false });
    //update the setting showPremiumBanner to false

    setTimeout(() => {
      this.props.closeMobileAppInstallBanner();
    }, 2);
  };

  GotoPlayStore = () => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track('PlayStore Button Clicked ', window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.open('https://play.google.com/store/apps/details?id=com.crowdsense.xamarin_app1&hl=en-US&ah=PGxIjINWj-Kl_TQnl3aw2xw66Q8', '_blank');
    
    });
    // window.location.replace('intent://app/SplashScreen#Intent;scheme=app_;package=com.crowdsense.xamarin_app1');
  }
  resize = () => {
    this.setState({
      ...this.state,
      clientWidth: document.documentElement.clientWidth
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    let hasAppInstalled = this.props.appInstalledData.length ? true : false;
    console.log(isMobile && (document.documentElement.clientWidth < 350))
    let smallScreenCondition = isMobile && (document.documentElement.clientWidth < 350)
    return (
      <Row className="marg-0 nopad flying-banner mob h-100">
        <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad" onClick={this.GotoPlayStore}>
          <Row className="marg-0 nopad h-100">
            <Col lg={3} sm={3} xs={3} md={3} xl={3} className="bannertext align-self-center">
              {
                // hasAppInstalled ? (
                //   <span  className={`mobileAppButtonImage`} onClick={this.GotoPlayStore}>
                //     Switch to app
                //   </span>
                // ) : (
                  
                // )
                <span>
                  <img
                      src={`${APPPATH.ImageUrl}/icon_google-play-store.png`}
                      className={`mobileAppButtonImage`}
                      alt="CrowdSense.ai"
                      onClick={this.GotoPlayStore}
                  />
                </span>
              }
                
            </Col>
            <Col lg={9} sm={9} xs={9} md={9} xl={9} className="bannertext align-self-center">
              {
                smallScreenCondition ? (
                  <div className="bannerspan1 fs14 fs13-sm">Switch to our mobile app for faster experience and personalized alerts</div>
                ) : (
                  <div className="bannerspan1 fs14 fs13-sm">Switch to our mobile app for faster experience and personalized alerts</div>
                  // <>
                  //   <div className="bannerspan1 fs14 fs13-sm">Switch to our mobile app</div>
                  //   <div className="bannerspan1 fs14 fs13-sm">for faster experience</div>
                  //   <div className="bannerspan1 fs14 fs13-sm">and personalized alerts</div>
                  // </>
                )
              }
              
              {/* google play button */}
            </Col> 
            
              
              {/* <span>
                <img
                    src={`${APPPATH.ImageUrl}/applestore.png`}
                    className={`mobileAppButtonImage`}
                    alt="CrowdSense.ai"
                />
              </span> */}
            </Row>
        </Col>
      
        <Col lg={1} sm={1} xs={1} md={1} xl={1} className={` bannerClose mob app mobinstAppbnr ${!this.state.show ? 'nodisp' : ''} pointer`} >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828" onClick={this.closeBanner}>
            <g transform="translate(1.414 1.414)">
              <path
                className="a"
                d="M1560.6,103.5l-13.63,13"
                transform="translate(-1546.97 -103.5)"
                fill="none"
                stroke={'#000'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2px"
              />
              <g transform="translate(-1546.97 -103.5)">
                <path
                  className="a"
                  d="M1546.97,103.5l13.63,13"
                  fill="none"
                  stroke={'#000'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2px"
                />
              </g>
            </g>
          </svg>
        </Col>
      </Row>
      
    );
  }
}


const mapStateToApp = (state) => {
  return {
    appInstalledData: state.settings.installedAppData,
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToApp, mapDispatchToProps)(MobileAppBanner);
