import appGlobalState from '../appconfig';
import * as moment from 'moment';

const searchReducer = (state = { ...appGlobalState.search }, action) => {
  if (action.type === 'SET_SAVED_SEARCH') {
    // console.log(action.pinnedSearch)
    return {
      ...state,
      searchSaveData: {
        ...state.searchSaveData,
        ...action.payload,
      },
      searchsavedata_initload: true
    };
  }

  if (action.type === 'UPDATE_PINNED_SEARCH_COUNT') {
    return {
      ...state,
      pinnedSearchCount: state.pinnedSearchCount + 1
    };
  }
  if (action.type === 'INIT_SEARCH_SAVE') {
    return {
      ...state,
      searchSaveInit: action.payload,
      searchSaveTitle: action.title,
    };
  }
  if (action.type === 'INIT_PINNED_SEARCH_SAVE') {
    return {
      ...state,
      pinnedSearchSaveInit: action.payload,
      pinnedSavedSearchListKey: action.listkey
    };
  }

  if (action.type === 'RESET_SEARCH_DATA') {
    return {
      ...state,
      searchData: action.payload,
    };
  }

  if (action.type === 'GET_SEARCH_FILTERED_STORIES') {
    // console.log(action.payload)
    return {
      ...state,
      searchFeeds: action.payload.eventResult,
      searchStories: action.payload.searchStories,
      searchStoriesIndex: JSON.parse(JSON.stringify(action.payload.searchStoriesIndex)),
      searchData: action.payload.searchData,
      searchRequest: false,
      searchCount: state.searchCount + 1,
      singleCoinSearch: false
    };
  }

  
  if (action.type === 'SEARCH_COUNT_INCREMENT_ON_COIN') {
    return {
      ...state,
      searchCount: state.searchCount + 1,
      singleCoinSearch: action.payload
    };
  }

  if (action.type === 'SEARCHED_COIN_DETAILS') {
    return {
      ...state,
      searchCoinDetails: action.payload,
    };
  }

  if(action.type === 'UPDATE_SEARCHEVENT_LIKES') {
    // console.log(action.payload)
    let stories = state.searchStories
    // console.log(stories)
    if(stories[action.payload.EventID]) {
      let Story = stories[action.payload.EventID]
      // console.log('before', Story.NumberOfLikes)
      Story.NumberOfLikes = action.payload.Like === 1 ? parseInt(Story.NumberOfLikes)+1 : parseInt(Story.NumberOfLikes)-1
      // console.log('after', Story.NumberOfLikes)
      return {
        ...state,
        searchStories: {
          ...state.searchStories,
          [action.payload.EventID]: JSON.parse(JSON.stringify(Story))
        }
      }
    }
  }
  if(action.type === 'UPDATE_SAVEDSEARCHEVENT_LIKES') {
    // console.log(action.payload)
    let savedSearches = state.searchSavedStories;
    if(Object.keys(savedSearches).length) {
      Object.keys(savedSearches).map(key => {
        if(savedSearches[key][action.payload.EventID]) {
          let Story = savedSearches[key][action.payload.EventID];
          Story.NumberOfLikes = action.payload.Like === 1 ? parseInt(Story.NumberOfLikes)+1 : parseInt(Story.NumberOfLikes)-1
          savedSearches[key][action.payload.EventID] = JSON.parse(JSON.stringify(Story))
        }
      })
      return {
        ...state,
        searchSavedStories: JSON.parse(JSON.stringify(savedSearches))
      }
    }
  }

  if (action.type === 'UPDATE_SAVED_SEARCH_FILTERED_STORIES') {
    let newStories = state.searchSavedStories[action.payload.savedsearchData.saveSearchId];
    let newStoriesIndex = state.searchSavedStoriesIndex[action.payload.savedsearchData.saveSearchId];
    const tweetListMaxCount = state.tweetListMaxCount;
    if (Object.keys(action.payload.searchStories).length) {
      let incomingFeeds = action.payload.searchStories;
      let RootFeedIdArr = Object.keys(newStories);
      let incomingFeedIdArr = Object.keys(incomingFeeds);
      let RootFeedHeadlines = RootFeedIdArr.map((it) => newStories[it].HeadlineText.trim());
      // let incomingFeedHeadlines = incomingFeedIdArr.map(it => (incomingFeeds[it].HeadlineText.trim()))
      RootFeedIdArr.forEach((eachFeedId) => {
        let eachFeed = newStories[eachFeedId];

        if (eachFeed) {
          if (incomingFeedIdArr.indexOf(eachFeedId) > -1) {
            let inc = incomingFeeds[eachFeedId];
            newStories[eachFeedId] = JSON.parse(JSON.stringify(inc));
            incomingFeedIdArr.splice(incomingFeedIdArr.indexOf(eachFeedId), 1);
          }
        }
      });

      let filtInc = incomingFeedIdArr.filter((inFdid) => {
        return RootFeedIdArr.indexOf(inFdid) === -1;
      });
      filtInc.forEach((item, i) => {
        let incNewFeed = incomingFeeds[item];
        if (RootFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1) {
          let existingHeadLineFeed = newStories[RootFeedIdArr[RootFeedHeadlines.indexOf(incNewFeed.HeadlineText)]];
          let existingHeadlineFeedIndex = '';
          newStoriesIndex.forEach((ns, indx) => {
            if (ns.FeedId === existingHeadLineFeed.FeedId) {
              existingHeadlineFeedIndex = indx;
            }
          });
          newStories = Object.assign({ [`${item}`]: incomingFeeds[item] }, newStories);
          newStoriesIndex[existingHeadlineFeedIndex] = {
            FeedId: item,
            SortByDate: incomingFeeds[item].SortByDate,
            notifyEvent: incomingFeeds[item].NotifyEvent,
            magnitudeRate: incomingFeeds[item].MagnitudeRate,
          };
          let changednewStories = {};
          newStoriesIndex.forEach((ns) => {
            changednewStories[`${ns.FeedId}`] = newStories[`${ns.FeedId}`];
          });
          newStories = {};
          newStories = changednewStories;
        } else {
          let nowTime = moment();
          let thenTime = moment(incomingFeeds[item].FeedTime);
          let days = moment(nowTime, 'DD-MM-YYYYThh:mm:ss').diff(thenTime, 'days');
          if (days < 2) {
            newStoriesIndex.unshift({
              FeedId: item,
              SortByDate: incomingFeeds[item].SortByDate,
              notifyEvent: incomingFeeds[item].NotifyEvent,
              magnitudeRate: incomingFeeds[item].MagnitudeRate,
            });
            newStories = Object.assign({ [`${item}`]: incomingFeeds[item] }, newStories);

            newStoriesIndex.length =
              newStoriesIndex.length > tweetListMaxCount ? tweetListMaxCount : newStoriesIndex.length;

            let limitedNewStories = {};
            if (newStoriesIndex.length) {
              newStoriesIndex.map((stindx) => {
                limitedNewStories[`${stindx.FeedId}`] = newStories[stindx.FeedId];
                return null;
              });
            }
            newStories = {};
            newStories = limitedNewStories;
          }
        }
      });
      let sortedNewStoriesIndex = newStoriesIndex.sort((a, b) => {
        return moment(b.SortByDate) - moment(a.SortByDate);
      });
      return {
        ...state,
        searchSavedStories: {
          ...state.searchSavedStories,
          [`${action.payload.savedsearchData.saveSearchId}`]: newStories,
        },
        searchSavedStoriesIndex: {
          ...state.searchSavedStoriesIndex,
          [`${action.payload.savedsearchData.saveSearchId}`]: JSON.parse(JSON.stringify(sortedNewStoriesIndex)),
        },
      };
    } else {
      return {
        ...state,
        searchSavedStories: {
          ...state.searchSavedStories,
          [`${action.payload.savedsearchData.saveSearchId}`]: newStories,
        },
        searchSavedStoriesIndex: {
          ...state.searchSavedStoriesIndex,
          [`${action.payload.savedsearchData.saveSearchId}`]: JSON.parse(JSON.stringify(newStoriesIndex)),
        },
      };
    }
  }

  if (action.type === 'GET_SAVED_SEARCH_FILTERED_STORIES') {
    const maxCount = state.tweetListMaxCount;
    action.payload.searchStoriesIndex.length =
      action.payload.searchStoriesIndex.length > maxCount ? maxCount : action.payload.searchStoriesIndex.length;

    let limitedStoriesList = {};
    action.payload.searchStoriesIndex.map((key) => {
      limitedStoriesList[`${key.FeedId}`] = action.payload.searchStories[`${key.FeedId}`];
      return null;
    });

    return {
      ...state,
      searchSavedFeeds: {
        ...state.searchSavedFeeds,
        [`${action.payload.savedsearchData.saveSearchId}`]: action.payload.eventResult,
      },
      searchSavedStories: {
        ...state.searchSavedStories,
        [`${action.payload.savedsearchData.saveSearchId}`]: limitedStoriesList,
      },
      searchSavedStoriesIndex: {
        ...state.searchSavedStoriesIndex,
        [`${action.payload.savedsearchData.saveSearchId}`]: action.payload.searchStoriesIndex,
      },
      searchSavedStoriesData: {
        ...state.searchSavedStoriesData,
        [`${action.payload.savedsearchData.saveSearchId}`]: action.payload.savedsearchData,
      },
      searchSaveDataUpdated: {
        ...state.searchSaveDataUpdated,
        [`${action.payload.savedsearchData.saveSearchId}`]: false,
      },
    };
  }

  if (action.type === 'SET_SAVESEARCH_FEEDREQDATA') {
    return {
      ...state,
      searchSaveFeedReqData: {
        ...state.searchSaveFeedReqData,
        [`${action.saveid}`]: action.payload,
      },
    };
  }

  if (action.type === 'DELETE_USER_SEARCH') {
    // console.log(action.payload);
    let searchSaveData = state.searchSaveData;
    let searchSavedFeeds = state.searchSavedFeeds;
    let searchSavedStoriesIndex = state.searchSavedStoriesIndex;
    let searchSavedStoriesData = state.searchSavedStoriesData;
    let searchSaveFeedReqData = state.searchSaveFeedReqData;
    // console.log(
    //   searchSaveData,
    //   searchSavedFeeds,
    //   searchSavedStoriesIndex,
    //   searchSavedStoriesData,
    //   searchSaveFeedReqData
    // );
    if (action.payload) {
      delete searchSaveData[`${action.payload}`];
      delete searchSavedFeeds[`${action.payload}`];
      delete searchSavedStoriesIndex[`${action.payload}`];
      delete searchSavedStoriesData[`${action.payload}`];
      delete searchSaveFeedReqData[`${action.payload}`];
    }
    // console.log(
    //   searchSaveData,
    //   searchSavedFeeds,
    //   searchSavedStoriesIndex,
    //   searchSavedStoriesData,
    //   searchSaveFeedReqData
    // );
    return {
      ...state,
      searchSaveData: JSON.parse(JSON.stringify(searchSaveData)),
      searchSavedFeeds: searchSavedFeeds,
      searchSavedStoriesIndex: JSON.parse(JSON.stringify(searchSavedStoriesIndex)),
      searchSavedStoriesData: searchSavedStoriesData,
      searchSaveFeedReqData: searchSaveFeedReqData,
    };
  }

  if (action.type === 'UPDATE_USER_SEARCH') {
    let withBlackUpdated = 0;
    let withCuratedUpdated = 0;
    // let searchSaveDataUpdated = state.searchSaveDataUpdated[`${action.searchid}`] >= 0 ?  state.searchSaveDataUpdated[`${action.searchid}`] : 0
    switch (action.updatedKey) {
      case 'withBlack':
        ++withBlackUpdated;
        break;
      case 'withCurated':
        ++withCuratedUpdated;
        break;
      default:
        break;
    }
    // console.log(state.searchSaveData, action.searchid, action.updatedKey)
    
    return {
      ...state,
      searchSaveData: {
        ...state.searchSaveData,
        [`${action.searchid}`]: action.payload,
      },
      searchSavedStories: {
        ...state.searchSavedStories,
        [`${action.searchid}`]: action.updatedKey === 'columnCollapsePreference' ? state.searchSavedStories[`${action.searchid}`] : {},
      },
      searchSavedStoriesIndex: {
        ...state.searchSavedStoriesIndex,
        [`${action.searchid}`]: action.updatedKey === 'columnCollapsePreference' ? state.searchSavedStoriesIndex[`${action.searchid}`] : [],
      },
      withBlackUpdated: {
        ...state.withBlackUpdated,
        [`${action.searchid}`]: withBlackUpdated,
      },
      withCuratedUpdated: {
        ...state.withCuratedUpdated,
        [`${action.searchid}`]: withCuratedUpdated,
      },
      searchSaveDataUpdated: {
        ...state.searchSaveDataUpdated,
        [`${action.searchid}`]: true,
      },
    };
  }

  if (action.type === 'READ_STAT_UPDATE_SAVEDSEARCH') {
    if (action.payload && action.SavedId) {
      let newsearchSavedStories = state.searchSavedStories;
      if (newsearchSavedStories[action.SavedId][action.payload.RootId]) {
        newsearchSavedStories[action.SavedId][action.payload.RootId].EventReadStatus = action.payload.ReadStatus;
      }

      return {
        ...state,
        searchSavedStories: newsearchSavedStories,
      };
    }
  }

  if (action.type === 'READ_STAT_UPDATE_SEARCH') {
    if (action.payload) {
      let searchStories = state.searchStories;
      if (searchStories[`${action.payload.RootId}`]) {
        searchStories[`${action.payload.RootId}`].EventReadStatus = action.payload.ReadStatus;
      }
      return {
        ...state,
        searchStories,
      };
    }
  }

  if (action.type === 'RE_SEARCH') {
    return {
      ...state,
      reSearch: action.payload,
    };
  }

  if (action.type === 'RESET_SEARCH_STORIES') {
    return {
      ...state,
      searchFeeds: [],
      searchStories: {},
      searchStoriesIndex: [],
      searchRequest: true,
    };
  }

  return state;
};

export default searchReducer;
