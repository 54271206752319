import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

class CorelTag extends Component {
  constructor() {
    super()
    this.state = {
      each: {},
      tagicon: '',
      tagcontent: '',
      tagtooltip: false
    }
    this.ttHandle = '';
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      each: this.props.each,
      tagicon: this.props.tagicon,
      tagcontent: this.props.tagcontent
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      each: nextProps.each,
      tagicon: nextProps.tagicon,
      tagcontent: nextProps.tagcontent
    })
  }

  componentWillUnmount() {
    clearTimeout(this.ttHandle)
  }

  toggleToolTip = (status) => {
    const { each } = this.state;
    if(this.ttHandle) {
      clearTimeout(this.ttHandle)
    }
    if(status) {
      this.ttHandle = setTimeout(() => {
        this.setState({
          ...this.state,
          tagtooltip: status
        }, () => {
          // if(status) {
            let content = each.Symbol? each.Symbol : each.Security? each.Security.substring(each.Security.indexOf('(')+1, each.Security.indexOf(')')) !== ''? each.Security.substring(each.Security.indexOf('(')+1, each.Security.indexOf(')')) : each.Security : each.SecutiryType
    
            window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
            window.Mixpanel.MixpanelProps.EventId = this.props.story.FeedId;
            window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
            window.Mixpanel.MixpanelProps.SecurityContent = content;
            window.Mixpanel.MixpanelProps.ToolTipType = 'Security Tag Tooltip';
            window.Mixpanel.actions.identify(this.props.userData.username);
            window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userData.username
              });
              window.Mixpanel.actions.resetProp('EventId');
              window.Mixpanel.actions.resetProp('Headline');
              window.Mixpanel.actions.resetProp('ToolTipType');
              window.Mixpanel.actions.resetProp('SecurityContent');
            })
          // }
          
        })
      }, 1500)
    } else {
      this.setState({
        ...this.state,
        tagtooltip: status
      })
    }
    
  } 

  render() {
    const { tagicon, tagcontent } = this.state
    
    return(
      <Col className="nopad" onMouseOver={() => this.toggleToolTip(true)} onMouseLeave={() => this.toggleToolTip(false)} onPointerOut={() => this.toggleToolTip(false)}>
        <div className="marg-0 padlr2">
        {
          tagicon !== '' ? (
            <div className={`dispinb `} >
              {
                tagicon
              }
            </div>
          ) : ('') 
        }
        {
          tagcontent !== '' && (
            <div 
              
              className={`  dispinb `} >
                
              {
                tagcontent
              }
              
            </div>
          )
        }
        </div>
      </Col>
    )
  }

}

export default CorelTag